import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter } from '@app/shared/models/filter';
import { ConfigService } from '@app/shared/services/config.service';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root',
})
export class QuoteRequestsIndexService {
  readonly basePath: string;
  constructor(private http: HttpClient, configService: ConfigService) {
    this.basePath = configService.getApiPath('quoterequest');
  }

  public getQuoteRequests(filters: Filter): Observable<GridDataResult> {
    return this.http.post<GridDataResult>(`${this.basePath}/list`, { ...filters });
  }

  toTitleCase(str) {
    return str.replace(/\w\S*/g, (txt) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1);
    });
  }
}
