import { Component, Inject, OnInit } from '@angular/core';
import {
  FormControl,
  Validators,
  FormGroup,
  FormBuilder
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WizardService } from '@app/wizard-bar/services/wizard.service';
import { from, Observable, Subscription } from 'rxjs';
import { UdFile } from '../UdFile';
import { FileService } from '../file.service';
import { DeleteFileService } from '../delete-file.service';

@Component({
  selector: 'app-editable-upload-dialog',
  templateUrl: './editable-upload-dialog.component.html',
  styleUrls: ['./editable-upload-dialog.component.scss'],
})

export class EditableUploadDialogComponent implements OnInit {
  public displayNameForm: FormGroup;
  public showErrorText: boolean;
  public documentType: string = 'General'; // General type is used for uploading Training Documents.
  public invalidatesQuoteRequest: boolean = false; // loa uploads don't invalidate

  constructor(
    public dialogRef: MatDialogRef<EditableUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private wizardService: WizardService,
    public fileService: FileService,
    public deleteFileService: DeleteFileService,
    private fb: FormBuilder
  ) {

    this.displayNameForm = this.fb.group({
      documentDisplayName: ([data.file?.documentDisplayName, [Validators.required, Validators.maxLength(100)]]),
    });

    if (data.file === undefined) {
      return;
    }

    this.files.push(data.file);
  }

  ngOnInit(): void {
    this.resolveWaitForUploadConfirmation = () => {
      const warningRequired = this.wizardService.resolveExpireQuoteWarningDialog(this.invalidatesQuoteRequest);
      if (warningRequired.required) {
        return warningRequired.dialogRef.afterClosed();
      } else {
        return new Observable<any>((obs) => {
          obs.next(true);
          obs.complete();
        });
      }
    };
  }

  public resolveWaitForUploadConfirmation: () => Observable<boolean>;

  public files: Array<UdFile> = new Array<UdFile>();

  public onCompleteUpload(uploadedFiles) {
    uploadedFiles.forEach((f) => {
      if (f === undefined) {
        return;
      }
      if (this.files.length > 0) {
        this.deleteFileService.deleteFile(this.files[0]).subscribe((result) => { });
      }
      this.files = new Array<UdFile>();
      this.files.push(f.data);
    });
    this.showErrorText = false;
  }

  public onDeleteUpload(deleteResults) {
    const index = this.files.findIndex((f) => deleteResults.fileId === f.fileId);
    this.files.splice(index, 1);
  }

  public ok() {
    if (this.displayNameForm.valid) {
      if (this.files?.length > 0) {
        this.fileService.updateDocument(this.files[0], this.displayNameForm.get('documentDisplayName').value).subscribe((result) => {
          this.dialogRef.close({
            success: this.files?.length > 0 ?? false,
            files: this.files,
          });
        });
      }
      else {
        this.showErrorText = true;
      }
    }
  }
}
