import { MarginTerm } from '@app/quoting/models/quote-request-summary';
import { MonthlyConsumptionData } from '@app/shared/components/more-details/more-details.component';
import { CommodityType } from '@app/shared/enums/commodity-type';
import { ContractLength } from '@app/shared/models/contract-length';
import { CustomerType } from '@app/shared/models/customer-type';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';
import { UtilityInformation } from '@app/shared/models/utility-information';
import { Action } from '@ngrx/store';
import { UsageSourceMap } from '../../models/usage-source-map';
import { SupplierUtilityModel } from '@app/shared/models/supplier-utility-model';
import { CustomerTypeSize } from '@app/shared/models/customer-type-size';
import { FormControl } from '@angular/forms';
import { UtilityRateCode } from '@app/shared/models/utility-rate-code';

export enum UtilityInformtationActionTypes {
  SetContractLengths = '[Utility Information] SetContractLengths',
  SetBrokerageName = '[Utility Information] SetBrokerageName',
  SetCustomerType = '[Utility Information] SetCustomerType',
  SetCustomerTypeSize = '[Utility Information] SetCustomerTypeSize',
  SetState = '[Utility Information] SetState',
  SetZipCode = '[Utility Information] SetZipCode',
  SetCommodityType = '[Utility Information] SetCommodityType',
  SetUtility = '[Utility Information] SetUtility',
  SetAddress = '[Utility Information] SetAddress',
  LoadedUtilityInformation = '[Utility Information] LoadedUtilityInformation',
  SetUtilityInformation = '[Utility Information] SetUtilityInformation',
  SetUtilityInformationList = '[Utility Information List] SetUtilityInformationList',
  ClearUtilityInformation = '[Utility Information] ClearUtilityInformation',
  ClearUtility = '[Utility Information] ClearUtility',
  SetProductType = '[Utility Information] SetProductType',
  SetQuoteRequestType = '[Utility Information] SetQuoteRequestType',
  SetProductTermsFromProductType = '[Utility Information] SetProductTermsFromProductType',
  ClearUIState = '[Utility Information] ClearUIState',
  SetPricesFilters = '[Utility Information] SetPricesFilters',
  SetSweetSpotFilterValue = '[Utility Information] SetSweetSpotFilterValue',
  SetSweetSpotCheckBoxState = '[Utility Information] SetSweetSpotCheckBoxState',
  SetContractLengths_Selected = '[Utility Information] SetContractLengths_Selected',
  SetSweetSpotWasRequested = '[Utility Information] SetSweetSpotWasRequested',
  SetPageName = '[Utility Information] SetPageName',
  SetUsageSources = '[Utility Information] SetUsageSources',
  SetGetQuoteContractLengthStartingButtons = '[Utility Information] SetGetQuoteContractLengthStartingButtons',
  SetUtilityUOM = '[Utility Information] SetUtilityUOM',
  LoadConsumptionData = '[Utility Information] LoadConsumptionData',
  LoadedConsumptionData = '[Utility Information] LoadedConsumptionData',
  ClearConsumptionData = '[Utility Information] ClearConsumptionData',
  SetSalesType = '[Utility Information] SetSalesType',
  SetMarginDollar = '[Utility Information] SetMarginDollar',
  SetMarginTerm = '[Utility Information] SetMarginTerm',
  UpdateMarginTerm = '[Utility Information] UpdateMarginTerm',
  SupplierUtilityInformation = '[Utility Information] SupplierUtilityInformation',
  SetUtilityDgsShortName = '[Utility Information] SetUtilityDgsShortName',
  SetUtilityPowwrPricingName = '[Utility Information] SetUtilityDgsShortName',
  SetUtilitySupplierPricingName = '[Utility Information] SetUtilityDgsShortName',
  SetUtilityRisk360Name = '[Utility Information] SetUtilityDgsShortName',
  SetBillingType = '[Utility Information] SetBillingType',
  SetIsDisplayReportsEnabled = '[Utility Information] SetIsDisplayReportsEnabled',
  SetShowShareQuoteButton = '[Utility Information] SetShowShareQuoteButton',
  SetCanNavigate = '[Utility Information] SetCanNavigate',
  SetMaximumLoadFactorPercentageIgnored = '[Utility Information] SetMaximumLoadFactorPercentageIgnored',
  SetAgentFee = '[Utility Information] SetAgentFee',
}

export class SetUsageSources implements Action {
  readonly type = UtilityInformtationActionTypes.SetUsageSources;
  constructor(public quoteRequestId: string, public usageSourceMaps: UsageSourceMap[]) {}
}

export class SetUtilityUOM implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtilityUOM;
  constructor(public quoteRequestId: string, public utilityUOM: string) {}
}

export class SetUtilityDgsShortName implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtilityDgsShortName;
  constructor(public quoteRequestId: string, public utilityDgsShortName: string) {}
}

export class SetUtilityPowwrPricingName implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtilityPowwrPricingName;
  constructor(public quoteRequestId: string, public utilityPowwrPricingName: string) {}
}

export class SetUtilitySupplierPricingName implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtilitySupplierPricingName;
  constructor(public quoteRequestId: string, public utilitySupplierPricingName: string) {}
}

export class SetUtilityRisk360Name implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtilityRisk360Name;
  constructor(public quoteRequestId: string, public utilityRisk360Name: string) {}
}

export class SetBillingType implements Action {
  readonly type = UtilityInformtationActionTypes.SetBillingType;
  constructor(public quoteRequestId: string, public billingType: string) {}
}

export class SetContractLengths implements Action {
  readonly type = UtilityInformtationActionTypes.SetContractLengths;
  constructor(public quoteRequestId: string, public contractLengths: number[]) {}
}

export class SetContractLengthsSelected implements Action {
  readonly type = UtilityInformtationActionTypes.SetContractLengths_Selected;
  constructor(public quoteRequestId: string, public contractLengths: ContractLength[]) {}
}

export class SetSweetSpotFilterValue implements Action {
  readonly type = UtilityInformtationActionTypes.SetSweetSpotFilterValue;
  constructor(public quoteRequestId: string, public showSweetSpotFilter: boolean) {}
}

export class SetSweetSpotCheckBoxState implements Action {
  readonly type = UtilityInformtationActionTypes.SetSweetSpotCheckBoxState;
  constructor(public quoteRequestId: string, public sweetSpotCheckBoxState: boolean) {}
}

export class SetSweetSpotWasRequested implements Action {
  readonly type = UtilityInformtationActionTypes.SetSweetSpotWasRequested;
  constructor(public quoteRequestId: string, public sweetSpotWasRequested: boolean) {}
}

export class SetCustomerType implements Action {
  readonly type = UtilityInformtationActionTypes.SetCustomerType;
  constructor(public quoteRequestId: string, public customerType: CustomerType) {}
}

export class SetCustomerTypeSize implements Action {
  readonly type = UtilityInformtationActionTypes.SetCustomerTypeSize;
  constructor(public quoteRequestId: string, public customerTypeSize: CustomerTypeSize) {}
}

export class SetBrokerageName implements Action {
  readonly type = UtilityInformtationActionTypes.SetBrokerageName;
  constructor(public quoteRequestId: string, public brokerageName: string) {}
}

export class SetZipCode implements Action {
  readonly type = UtilityInformtationActionTypes.SetZipCode;
  constructor(public quoteRequestId: string, public zipCode: string) {}
}

export class SetCommodityType implements Action {
  readonly type = UtilityInformtationActionTypes.SetCommodityType;
  constructor(public quoteRequestId: string, public commodityType: CommodityType) {}
}

export class SetPageName implements Action {
  readonly type = UtilityInformtationActionTypes.SetPageName;
  constructor(public quoteRequestId: string, public pageName: string) {}
}

export class SetGetQuoteContractLengthStartingButtons implements Action {
  readonly type = UtilityInformtationActionTypes.SetGetQuoteContractLengthStartingButtons;
  constructor(public quoteRequestId: string, public startingButtons: ContractLength[]) {}
}

export class SetUtility implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtility;
  constructor(public quoteRequestId: string, public utility: string) {}
}

export class SetAddress implements Action {
  readonly type = UtilityInformtationActionTypes.SetAddress;
  constructor(public quoteRequestId: string, public zipCode: string, public state: string) {}
}

export class LoadedUtilityInformation implements Action {
  readonly type = UtilityInformtationActionTypes.LoadedUtilityInformation;
  constructor(public utilityInformation: UtilityInformation[]) {}
}
export class SupplierUtilityInformation implements Action {
  readonly type = UtilityInformtationActionTypes.SupplierUtilityInformation;
  constructor(public supplierUtilityInformation: SupplierUtilityModel[]) {}
}

export class LoadedConsumptionData implements Action {
  readonly type = UtilityInformtationActionTypes.LoadedConsumptionData;
  constructor(public consumptionData: MonthlyConsumptionData[]) {}
}

export class SetUtilityInformation implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtilityInformation;
  constructor(
    public quoteRequestId: string,
    public state: string,
    public quoteType: QuoteRequestType,
    public zipCode: string,
    public commodity: CommodityType
  ) {}
}

export class SetUtilityInformationList implements Action {
  readonly type = UtilityInformtationActionTypes.SetUtilityInformationList;
  constructor(public utilityInformation: UtilityInformation[]) {}
}

export class ClearUtilityInformation implements Action {
  readonly type = UtilityInformtationActionTypes.ClearUtilityInformation;
  constructor(public quoteRequestId: string) {}
}

export class ClearConsumptionData implements Action {
  readonly type = UtilityInformtationActionTypes.ClearConsumptionData;
  constructor(public quoteRequestId: string) {}
}

export class ClearUIState implements Action {
  readonly type = UtilityInformtationActionTypes.ClearUIState;
  constructor(public quoteRequestId: string) {}
}

export class ClearUtility implements Action {
  readonly type = UtilityInformtationActionTypes.ClearUtility;
  constructor(public quoteRequestId: string) {}
}

export class SetProductType implements Action {
  readonly type = UtilityInformtationActionTypes.SetProductType;
  constructor(public quoteRequestId: string, public productType: string) {}
}

export class SetSalesType implements Action {
  readonly type = UtilityInformtationActionTypes.SetSalesType;
  constructor(public quoteRequestId: string, public salesType: string) {}
}

export class SetProductTermsFromProductType implements Action {
  readonly type = UtilityInformtationActionTypes.SetProductTermsFromProductType;
  constructor(public quoteRequestId: string, public productType: string, public productTerms: string[]) {}
}

export class SetPricesFilters implements Action {
  readonly type = UtilityInformtationActionTypes.SetPricesFilters;
  constructor(
    public quoteRequestId: string,
    public productTypes: string[],
    public contractLengths: number[],
    public showSweetSpot: boolean
  ) {}
}
export class SetMarginDollar implements Action {
  readonly type = UtilityInformtationActionTypes.SetMarginDollar;
  constructor(public quoteRequestId: string, public marginDollar: number) {}
}
export class SetMarginTerm implements Action {
  readonly type = UtilityInformtationActionTypes.SetMarginTerm;
  constructor(public quoteRequestId: string, public marginTerm: MarginTerm[]) {}
}

export class SetQuoteRequestType implements Action {
  readonly type = UtilityInformtationActionTypes.SetQuoteRequestType;
  constructor(public quoteRequestId: string, public quoteRequestType: QuoteRequestType) {}
}

export class LoadConsumptionData implements Action {
  readonly type = UtilityInformtationActionTypes.LoadConsumptionData;
  constructor(public quoteRequestId: string, public term: number, public uplift: number) {}
}

export class SetIsDisplayReportsEnabled implements Action {
  readonly type = UtilityInformtationActionTypes.SetIsDisplayReportsEnabled;
  constructor(public isDisplayReportsEnabled: boolean) {}
}
export class SetShowShareQuoteButton implements Action {
  readonly type = UtilityInformtationActionTypes.SetShowShareQuoteButton;
  constructor(public showShareQuoteButton: boolean) {}
}
export class SetAgentFee implements Action {
  readonly type = UtilityInformtationActionTypes.SetAgentFee;
  constructor(public quoteRequestId: string, public agentFee: number) {}
}

export type UtilityInformationActions =
  | SetUtilityInformation
  | ClearUtilityInformation
  | ClearUtility
  | LoadedUtilityInformation
  | SetCustomerType
  | SetCustomerTypeSize
  | SetBrokerageName
  | SetContractLengths
  | SetZipCode
  | SetCommodityType
  | SetUtility
  | SetAddress
  | SetProductType
  | SetQuoteRequestType
  | SetProductTermsFromProductType
  | ClearUIState
  | SetPricesFilters
  | SetSweetSpotFilterValue
  | SetSweetSpotCheckBoxState
  | SetContractLengthsSelected
  | SetSweetSpotWasRequested
  | SetPageName
  | SetGetQuoteContractLengthStartingButtons
  | SetUtilityUOM
  | LoadConsumptionData
  | LoadedConsumptionData
  | ClearConsumptionData
  | SetUsageSources
  | SetSalesType
  | SupplierUtilityInformation
  | SetMarginDollar
  | SetMarginTerm
  | SetIsDisplayReportsEnabled
  | SetBillingType
  | SetShowShareQuoteButton
  | SetAgentFee;
