import { CustomerType } from '@app/shared/models/customer-type';
import { QuoteRequestState } from '@app/shared/models/quote-request-state';
import { YoureDone } from '@app/shared/models/youre-done';

export interface Quote {
  marginDollar?: number;
  upliftDollar?: number;
  quoteDate?: Date;
  prices?: Offer[];
  error?: QuoteError;
  approvalNote?: ApprovalNote;
  quoteRequestState?: QuoteRequestState;
  hasIDontKnows?: boolean;
  youreDone?: YoureDone;
  inputAnnualUsage?: number;
  pricingCurveDate?: Date;
  customerType?: CustomerType;
  isTaxIncluded?: boolean;
  // Resolved in reducer
  quoteState?: QuoteState;
  quote?: Quote;
  status?: string;
}

export interface ApprovalNote {
  note?: string;
  resolvedBy?: string;
  resolvedDateTime?: Date;
}

export interface Offer {
  quotePriceId: number;
  term: number;
  noResult: boolean;
  startDate: Date;
  sellingPrice: number;
  supplierMarginYear: number;
  totalCommission: number;
  totalMargin: number;
  commissionYear: number;
  totalConsumption: number;
  margin: number;
  annualCost: number;
  totalCost: number;
  isSweetSpot: boolean;
  status: QuotePriceStatus;
  quoteDate: Date;
  notes: string;
  approvalStatus: ApprovalStatus;
  resolvedBy: string;
  resolvedDateTime: Date;
  planType: string;
  details: PriceDetail[];
  statusMessage: string;
  agentFee: number;
}

export interface PriceDetail {
  batchId: string;
  annualLoad: number;
  revenueTotal: number;
  volumeTotal: number;
  recComplianceCostTotal: number;
  recComplianceCostPerUnit: number;
  energyCostPerUnit: number;
  energyCostTotal: number;
  capacityCostPerUnit: number;
  capacityCostTotal: number;
  nitsCostPerUnit: number;
  nitsCostTotal: number;
  nonIsoEnergyCostPerUnit: number;
  nonIsoEnergyCostTotal: number;
  tduCostPerUnit: number;
  tduCostTotal: number;
  clientAddersPerUnit: number;
  clientAdderTotal: number;
  brokerCommissionPerUnit: number;
  brokerCommissionTotal: number;
  ancillaryIsoCostPerUnit: number;
  ancillaryCostTotal: number;
  upliftPerUnit: number;
  marginPerUnit: number;
  annualLoadFactor: number;
  plc: number;
  nitsTag: number;
  utilityAccountNumber: string;
}

export interface QuoteError {
  error: string;
  detail: string;
}

export interface QuoteSettings {
  upliftDollar: number;
  marginDollar: number;
  isTaxIncluded: boolean;
  quoteDueDate: string;
  agentFee: number;
}

/**
 * Defines the UI state of a quote.
 * Allows us to display/hide and style UI objects based on a state.
 */

export enum QuoteState {
  NO_RESULT = 'NO_RESULT',
  EXPIRED = 'EXPIRED',
  ERROR = 'ERROR',
  NOT_QUOTED = 'NOT_QUOTED',
  HAS_I_DONT_KNOWS = 'HAS_I_DONT_KNOWS',
  BROKER_REQUESTED = 'BROKER_REQUESTED',
  BROKER_REQUESTED_UNQUOTED = 'BROKER_REQUESTED_UNQUOTED',
  INVALIDATED = 'INVALIDATED',
  PARTIALLY_ENROLLED = 'PARTIALLY_ENROLLED',
  CONTRACT_LIVE = 'CONTRACT_LIVE',
  CONTRACT_EXPIRING = 'CONTRACT_EXPIRING',
  CONTRACT_DROP_PENDING = 'CONTRACT_DROP_PENDING',
  CONTRACT_DROPPED = 'CONTRACT_DROPPED',
  SUBMISSION_SUCCESSFUL = 'SUBMISSION_SUCCESSFUL',
  SUBMISSION_FAILED = 'SUBMISSION_FAILED',
  PARTIALLY_SUBMITTED = 'PARTIALLY_SUBMITTED',

  /**
   * Quote has been generated that does not require approval.
   */
  GENERATED = 'GENERATED',

  /**
   * In context of Supplier/Broker user quote has been requested.
   */
  REQUESTED = 'REQUESTED',

  /**
   * In context of Supplier/Broker user quote request as been rejected.
   */
  REQUEST_REJECTED = 'REQUEST_REJECTED',

  /**
   * In context of Supplier/Broker user quote request as been approved and offers have been sent.
   */
  REQUEST_APPROVED = 'REQUEST_APPROVED',

  /**
   * In context of Supplier Manager quote has been requested and requires approval.
   */
  APPROVAL_REQUIRED = 'APPROVAL_REQUIRED',

  /**
   * In context of Supplier Manager offers have been sent.
   */
  APPROVAL_ACCEPTED = 'APPROVAL_ACCEPTED',

  /**
   * In context of Supplier Manager quote request has been rejected.
   */
  APPROVAL_REJECTED = 'APPROVAL_REJECTED',

  // In context of any user where quote owned does not require approval
  /**
   * In context of a quote request that has had offers accepted.
   */
  OFFER_ACCEPTED = 'OFFER_ACCEPTED',

  /**
   * In context of a quote request that has had offers rejected.
   */
  OFFER_REJECTED = 'OFFER_REJECTED',

  /**
   * In context of a quote request that has had a document submitted to DocuSign
   */
  CONTRACT_PENDING = 'CONTRACT_PENDING',

  /**
   * In context of a quote request that has had a document submitted to DocuSign
   */
  PENDING_ENROLLMENT = 'PENDING_ENROLLMENT',

  /**
   * In context of a quote request DocuSign document that has been rejected by the customer
   */
  CONTRACT_REJECTED = 'CONTRACT_REJECTED',

  /**
   * In context of a quote request DocuSign document that has been rejected by the customer
   */
  ENROLLED = 'ENROLLED',
}

export enum QuotePriceStatus {
  Generated = 'Generated',
  OfferRejected = 'OfferRejected',
  OfferAccepted = 'OfferAccepted',
  Expired = 'Expired',
  UnderReview = 'UnderReview',
}

export enum ApprovalStatus {
  NotRequired = 'NotRequired',
  UnderReview = 'UnderReview',
  Approved = 'Approved',
  Rejected = 'Rejected',
  UnderManagerReview = 'UnderManagerReview',
}
