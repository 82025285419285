import { Component, OnInit } from '@angular/core';
import { Store, ActionsSubject, Action } from '@ngrx/store';
import { LoginSuccess, Login } from '../../state/actions/auth.actions';
import { AuthService } from '../../auth.service';
import { FetchUtilityHubConfig, ConfigurationTypes } from '@configuration/state/actions/configuration.actions';
import { AppState } from '@app/state/app.state';

@Component({
  selector: 'app-oidc-callback',
  templateUrl: './oidc-callback.component.html',
  styleUrls: ['./oidc-callback.component.scss'],
})
export class OidcCallbackComponent implements OnInit {
  themeApplied = false;
  constructor(private actionSubject$: ActionsSubject, private store: Store<AppState>, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.completeAuthentication().subscribe(
      (user) => {
        this.authService.utilityHubIdentity().subscribe((uhUser) => {
          this.store.dispatch(new FetchUtilityHubConfig());
          this.actionSubject$.subscribe((action: Action) => {
            if (action.type === ConfigurationTypes.ThemeApplied && !this.themeApplied) {
              this.themeApplied = true;
              this.store.dispatch(new LoginSuccess(user, uhUser));
            }
          });
        });
      },
      (_) => {
        this.store.dispatch(new Login());
      }
    );
  }
}
