import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { OidcCallbackSignoutComponent } from '@auth/components/oidc-callback-signout/oidc-callback-signout.component';
import { OidcCallbackComponent } from '@auth/components/oidc-callback/oidc-callback.component';
import { AuthGuard } from './auth/auth.guard';
import { ShareQuoteComponent } from './share-quote/share-quote.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'quotes',
    loadChildren: () =>
      import('./quote-index/quote-index.module').then(
        (m) => m.QuoteIndexModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'utility-accounts',
    loadChildren: () =>
      import('./utility-accounts-index/utility-accounts-index.module').then(
        (m) => m.UtilityAccountsIndexModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'matrix',
    loadChildren: () =>
      import('./matrix/matrix.module').then(
        (m) => m.MatrixModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'custom',
    loadChildren: () =>
      import('./custom/custom.module').then(
        (m) => m.CustomModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'quickquote',
    loadChildren: () =>
      import('./quick-quote/quickquote.module').then(
        (m) => m.QuickQuoteModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'training-documents',
    loadChildren: () =>
      import('./training-document/training-document.module').then(
        (m) => m.TrainingDocumentModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./settings/settings.module').then(
        (m) => m.SettingsModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'quote-request/:quoteRequestId/quote-share/:QuoteShareGuid',
    component: ShareQuoteComponent
  },
  {
    path: 'oidc-callback-signout',
    component: OidcCallbackSignoutComponent
  },
  {
    path: 'oidc-callback',
    component: OidcCallbackComponent
  },
  { path: '', loadChildren: () => import('./4.0.x/error-pages.module').then(m => m.ErrorPagesModule) },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
