import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { ConsoleTransport, Logger } from '@app/logging.service';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

const logger = new Logger({
  transports: [new ConsoleTransport()],
});

Logger.Log = logger;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
