<div class="dialog-heading">
  <h5>{{data.title}}</h5>
  <span style="flex: 1 1 auto"></span>
  <mat-icon class="closeDialog pointer" aria-hidden="false" [mat-dialog-close]="{ success: false }" aria-label="Close icon">close</mat-icon>
</div>

<div [formGroup]="displayNameForm">
  <mat-form-field class="text-full-width" appearance="standard" floatLabel="always">
    <mat-label>Document Name</mat-label>
    <input type="text" matInput placeholder="Document Name" formControlName="documentDisplayName">
    <mat-error class="error">Please enter a Document Name</mat-error>
  </mat-form-field>
</div>

<p style="margin-top:20px;">{{data.description}}</p>
<section>
  <app-upload [waitForConfirmationFunc]="resolveWaitForUploadConfirmation"
              (after-closed)="onCompleteUpload($event)"
              [document-type]="documentType"
              [upload-description]="data.uploadBoxDescription"
              [disabled]="false"
              [multiple]="false"></app-upload>

  <ul class="file-list">
    <li *ngFor="let file of files">
      <div class="delete-file">
        <a class="download-link" appDownloadLink [file]="file">{{ file.fileName }}</a>
        <app-delete-file [file]="file"
                         (after-delete)="onDeleteUpload($event)"
                         [disabled]="false"
                         title="DELETE DOCUMENT">
        </app-delete-file>
      </div>
    </li>
  </ul>

  <p *ngIf="this.showErrorText" class="error">{{data.noFileUploadedErrorMessage}}</p>
</section>

<!-- This are the actions of the dialog, containing the primary and the cancel button-->
<mat-dialog-actions class="actions">
  <button mat-flat-button color="accent" (click)="ok()">OK</button>
  <button mat-stroked-button color="accent" [mat-dialog-close]="{ confirmed: false }">Cancel</button>
</mat-dialog-actions>
