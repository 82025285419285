import {  Component, OnInit} from '@angular/core';
import { QuoteRequestIndexState } from '../state/quote-index.state';
import { Store } from '@ngrx/store';
import { ClearQuote } from '@quoting/state/actions/quoting-module.actions';
import { UserRole, UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { Router } from '@angular/router';
import { CommodityType } from '@app/shared/enums/commodity-type';
import { AppState } from '@app/state/app.state';
import { ClearUIState, SetCommodityType } from '@utility-information/state/actions/utility-information.actions';
import { isDynamicSelector, isCustomJourneyEnabledSelector, isMatrixJourneyEnabledSelector, isQuickQuoteJoruneyEnabledSelector, isCustomJourneyGasEnabledSelector, isCustomJourneyElectricEnabledSelector } from '@utility-information/state/selectors/utility-information.selectors';
import { loggedInUtilityHubIdentitySelector } from '@app/state/app.selectors';
import { escape } from 'lodash';

@Component({
  selector: 'app-quotes',
  templateUrl: './quote-index.component.html',
  styleUrls: ['./quote-index.component.scss'],
})
export class QuoteIndexComponent implements OnInit {

  public customJourneyEnabled: boolean;
  public matrixJourneyEnabled: boolean;
  public powwrEnabled: boolean;
  public quickQuoteJourneyEnabled: boolean;
  public customGasJourneyEnabled: boolean;
  public customElectricJourneyEnabled: boolean;
  public isSupplierMgr: boolean;
  public isCreditAdmin: boolean;
  public userIdentity: UtilityHubIdentity;
  quoteRequestId: string;

  constructor(private store: Store<AppState>,private quouteRequestIndexState: QuoteRequestIndexState,  private router: Router) {
    this.store.dispatch(new ClearUIState(this.quoteRequestId));
  }

  ngOnInit(): void {
    this.store.select(loggedInUtilityHubIdentitySelector).subscribe((user) => {
      this.getUhConfiguration();
    });
    this.store.dispatch(new ClearQuote());
  }

  getUhConfiguration() {
    this.store.select(isDynamicSelector).subscribe(d=> this.powwrEnabled = d);
    this.store.select(isCustomJourneyEnabledSelector).subscribe(c => this.customJourneyEnabled = c);
    this.store.select(isMatrixJourneyEnabledSelector).subscribe(m => this.matrixJourneyEnabled = m);
    this.store.select(isQuickQuoteJoruneyEnabledSelector).subscribe(q => this.quickQuoteJourneyEnabled = q);
    this.store.select(isCustomJourneyGasEnabledSelector).subscribe(cg => this.customGasJourneyEnabled = cg);
    this.store.select(isCustomJourneyElectricEnabledSelector).subscribe(ce => this.customElectricJourneyEnabled = ce);
    this.store.select(loggedInUtilityHubIdentitySelector).subscribe((user) => {
      this.userIdentity = user;

      this.isCreditAdmin = false;
      this.isSupplierMgr = false;

      if(this.userIdentity.isSupplierManagerOrSalesManager){
        this.isSupplierMgr = true;
      }
      else if (UserRole[this.userIdentity.currentOrganisation.role] === UserRole.CreditAdmin) {
        this.isCreditAdmin = true;
      }
    });

  }

  search($event) {
    const filters = this.quouteRequestIndexState.getActiveFilters();
    this.quouteRequestIndexState.setActiveFilter({ ...filters, searchText: $event.trim() });
  }

  public getCustomGasQuoteBtn_Clicked() {
    this.store.dispatch(new SetCommodityType(this.quoteRequestId, CommodityType.Gas));
    this.router.navigate(['custom', 'new', 'customer-details',{queryParams : {commodityType: CommodityType.Gas}}]);
  }

  public getCustomElectricQuoteBtn_Clicked() {
    this.store.dispatch(new SetCommodityType(this.quoteRequestId, CommodityType.Electricity));
    this.router.navigate(['custom', 'new', 'customer-details',{queryParams : {commodityType: CommodityType.Electricity}}]);
  }
}
