<div class="upload" *ngIf="!disabled">
  <div appDnd (filesChangeEmitter)="openUploadDialog($event)" class="dropArea" fxLayout="column" fxLayoutAlign="space-evenly center">
    <label class="upload-label">
      <input id="uploadFile" #file (change)="openUploadDialog(null)" type="file" style="display: none" [multiple]="multiple" />
      <span><object class="icon" data="../../../../../assets/icons/cloud_upload.svg"></object></span>
      {{ uploadDescription }}
      <span>or click to browse and upload files</span>
    </label>
  </div>
  <p class="error-text" *ngIf="statusMessage?.length > 0">{{ statusMessage }}</p>
</div>
