import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-fire-lightning-background',
  templateUrl: './fire-lightning-background.component.html',
  styleUrls: ['./fire-lightning-background.component.scss'],
})
export class FireLightningBackgroundComponent implements OnInit {
  @Input() cssClass: string;
  constructor() {}

  ngOnInit() {}
}
