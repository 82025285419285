import { ApplicationInsights } from '@microsoft/applicationinsights-web';

export class AppInsights {
  public static telemetry: ApplicationInsights;

  constructor() {}

  public static init(instrumentKey: string, version: string) {
    AppInsights.telemetry = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentKey,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        appId: version,
      },
    });

    AppInsights.telemetry.loadAppInsights();
  }
}
