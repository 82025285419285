import { Action } from '@ngrx/store';
import { User } from 'oidc-client';
import { UtilityHubIdentity } from '../../models/utility-hub-identity';

export enum AuthActionTypes {
  Login = '[Auth] Login',
  LoginSilent = '[Auth] Login Silent',
  UtilityHubIdentitySet = '[UH Auth] Identity Set',
  LoginSuccess = '[Auth] Login Success',
  LoginSilentSuccess = '[Auth] Login Silent Success',
  LoginFailure = '[Auth] Login Failure',
  Logout = '[Auth] Logout',
  AccessCheck = '[Auth] Access Check',
  AccessCheckFailed = '[Auth] Access Check Failed',
}

export class Login implements Action {
  readonly type = AuthActionTypes.Login;
}

export class LoginSilent implements Action {
  readonly type = AuthActionTypes.LoginSilent;
}

export class UtilityHubIdentitySet implements Action {
  readonly type = AuthActionTypes.UtilityHubIdentitySet;
  constructor(public user: User, public utiltyHubIdentity: UtilityHubIdentity) {}
}

export class LoginSuccess implements Action {
  readonly type = AuthActionTypes.LoginSuccess;
  constructor(public user: User, public utiltyHubIdentity: UtilityHubIdentity) {}
}
export class LoginSilentSuccess implements Action {
  readonly type = AuthActionTypes.LoginSilentSuccess;
  constructor(public user: User, public utiltyHubIdentity: UtilityHubIdentity) {}
}

export class LoginFailure implements Action {
  readonly type = AuthActionTypes.LoginFailure;
}

export class Logout implements Action {
  readonly type = AuthActionTypes.Logout;
}

export class AccessCheckFailed implements Action {
  readonly type = AuthActionTypes.AccessCheckFailed;
}

export class AccessCheck implements Action {
  readonly type = AuthActionTypes.AccessCheck;
}

export type AuthActions =
  | Login
  | LoginSilent
  | LoginSilentSuccess
  | UtilityHubIdentitySet
  | LoginSuccess
  | LoginFailure
  | Logout
  | AccessCheck
  | AccessCheckFailed;
