import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { Observable } from 'rxjs';
import { MatSort } from '@angular/material/sort';

export class QuoteQuery {
  constructor(values: any) {
    for (const key in values) {
      if (values[key] !== null) {
        if (typeof values[key] !== 'object') {
          this[key] = values[key];
        }
      }
    }
  }
  public pageSize: number;
  public pageIndex: number;
  public createdBy: string;
  public brokerage: string;
  public status: string;
  public commodity: string;
  public sortBy: string;
  public sortDirection: string;

  public static getQuoteRequestsFromQueryParam(route: ActivatedRoute, paginator: MatPaginator, sort: MatSort): Observable<QuoteQuery> {
    return new Observable<QuoteQuery>((obs) =>
      route.queryParams.subscribe((params) => {
        const query = new QuoteQuery(params);
        query.pageIndex = paginator?.pageIndex ?? 0;
        query.pageSize = paginator?.pageSize ?? 20;
        query.brokerage = params.brokerage ?? '';
        query.createdBy = params.createdBy ?? '';
        query.status = params.status ?? '';
        query.commodity = params.commodity ?? '';
        query.sortBy = sort?.active ?? 'id';
        query.sortDirection = sort?.direction ?? 'desc';
        obs.next(query);
        obs.complete();
      })
    );
  }

  public toUriString(): string {
    const fragments = [
      `pageSize=${this.pageSize || ''}`,
      `pageIndex=${this.pageIndex === 0 ? this.pageIndex : this.pageIndex || ''}`,
      `${this.brokerage ? `brokerage=${this.brokerage}` : ''}`,
      `${this.createdBy ? `createdBy=${this.createdBy}` : ''}`,
      `${this.status ? `status=${this.status}` : ''}`,
    ]
      .filter(Boolean)
      .join('&');
    return encodeURI(fragments);
  }
}
