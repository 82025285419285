export class DateConstants {
  static LOCALE_FORMAT_SHORT = (locale?: string) => {
    switch (locale) {
      case 'en-US':
        return 'MM/dd/yyyy';
      case 'en-GB':
        return 'dd/MM/yyyy';
    }

    return 'MM/dd/yyyy';
  };
}
