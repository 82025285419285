import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { UdFile } from '../UdFile';
import { FileService } from '../file.service';
import { ConfigService } from '../../shared/services/config.service';

@Directive({
  selector: '[appDownloadLink]',
})
export class DownloadLinkDirective implements OnInit {
  @Input() file: UdFile;

  constructor(private el: ElementRef, private fileService: FileService, private config: ConfigService) {
    this.el.nativeElement.style.cursor = 'pointer';
  }

  ngOnInit(): void {
    if (this.file) {
      this.el.nativeElement.href = this.config.getApiPath(`file/download/${this.file.fileId}`);
    }
  }
}
