import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { authModuleReducer } from '@auth/state/reducers/auth.reducers';
import { localStorageSync } from 'ngrx-store-localstorage';
import { merge } from 'lodash';
import LogRocket from 'logrocket';
import createNgrxMiddleware from 'logrocket-ngrx';
import { AuthActionTypes } from '@auth/state/actions/auth.actions';
import { AppState } from './app.state';
import { configurationReducer } from '@configuration/state/reducers/configuration.reducers';
import { quotingModuleReducer } from '@quoting/state/reducers/quoting-module.reducers';
import { utilityInformationReducer } from '@utility-information/state/reducers/utility-information.reducers';

const INIT_ACTION = '@ngrx/store/init';
const UPDATE_ACTION = '@ngrx/store/update-reducers';

export const reducers: ActionReducerMap<AppState> = {
  authModuleState: authModuleReducer,
  configurationModuleState: configurationReducer,
  quotingModuleState: quotingModuleReducer,
  utilityInformationState: utilityInformationReducer,
};

const logrocketMiddleware = createNgrxMiddleware(LogRocket);

const mergeReducer = (state: any, rehydratedState: any, action: Action): AppState => {
  if ((action.type === INIT_ACTION || action.type === UPDATE_ACTION) && rehydratedState) {
    state = merge(state, rehydratedState);
  }
  if (action.type === AuthActionTypes.Logout) {
    state = undefined;
  }
  return state;
};

const localStorageSyncReducer: (reducer: ActionReducer<AppState>) => ActionReducer<AppState> = (reducer) => {
  return localStorageSync({
    keys: ['configurationModuleState'],
    rehydrate: true,
    mergeReducer,
  })(reducer);
};

export const metaReducers: MetaReducer<AppState>[] = [localStorageSyncReducer, logrocketMiddleware];

