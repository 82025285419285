import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../shared/services/config.service';
import { UdFile, UdFileExt } from './UdFile';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  constructor(private http: HttpClient, private appConfig: ConfigService) {}

  public download(tenderId: number, blobName: string, documentType: string) {
    const path = this.appConfig.getApiPath('file/download');
    const body = {
      TenderId: tenderId,
      blobName: blobName,
      DocumentType: documentType,
    };

    return this.http.post<Blob>(path, body, { responseType: 'blob' as 'json' });
  }

  public updateDocumentType(file: UdFileExt) {
    const path = this.appConfig.getApiPath('file/update');
    file.tags['Type'] = file.documentType;
    return this.http.post(path, file);
  }

  public updateDocument(file: UdFile, documentDisplayName: string) {
    const path = this.appConfig.getApiPath('file/update');
    return this.http.post(path, { fileId: file.fileId, documentDisplayName: documentDisplayName });
  }
}
