import { TableauOrganisationWorkbooksModel } from '@app/dashboard/models/tableau-organisation-workbooks.model';
import { Action } from '@ngrx/store';
import { UtilityHubConfiguration } from "../../models/utilityhub-configuration";

export enum ConfigurationTypes {
  FetchUtilityHubConfig = '[Utility Hub Configuration] Fetch',
  ApplyConfig = '[Utility Hub Configuration] Apply Config',
  FetchBrokerageConfig = '[Utility Hub Configuration] FetchBrokerageConfig',
  ThemeApplied = '[Utility Hub Configuration] ThemeApplied',
  FetchTableauWorkbooks = '[Utility Hub Configuration] FetchTableauReports',
  FetchTableauWorkbooksSuccess = '[Utility Hub Configuration] Successfully fetched tableau reports',
  FetchTableauWorkbooksFailure = '[Utility Hub Configuration] Failed to fetch Tableau Workbooks',
}

export class FetchUtilityHubConfig implements Action {
  readonly type = ConfigurationTypes.FetchUtilityHubConfig;
}

export class ApplyConfig implements Action {
  readonly type = ConfigurationTypes.ApplyConfig;
  constructor(public config: UtilityHubConfiguration) {}
}

export class ThemeApplied implements Action {
  readonly type = ConfigurationTypes.ThemeApplied;
}

export class FetchTableauWorkbooks implements Action {
  constructor(public organisationId: string) {}
  readonly type = ConfigurationTypes.FetchTableauWorkbooks;
}

export class FetchTableauWorkbooksSuccess implements Action {
  readonly type = ConfigurationTypes.FetchTableauWorkbooksSuccess;
  constructor(public organisationWorkbooks: TableauOrganisationWorkbooksModel) {}
}

export class FetchTableauWorkbooksFailure implements Action {
  readonly type = ConfigurationTypes.FetchTableauWorkbooksFailure;
  constructor(public error: string) {}
}

export type ConfigurationActions = FetchUtilityHubConfig | ApplyConfig | ThemeApplied | FetchTableauWorkbooks | FetchTableauWorkbooksSuccess;
