import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { Observable, ObservableInput, of, BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AppConfig } from '../models/app-config';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  public config: AppConfig;
  public configLoaded: BehaviorSubject<AppConfig> = new BehaviorSubject<AppConfig>(null);

  constructor(private http: HttpClient) {}

  static loadConfiguration(http: HttpClient, config: ConfigService): () => Promise<boolean> {
    return (): Promise<boolean> => {
      return new Promise<boolean>((resolve: (a: boolean) => void): void => {
        http
          .get('/assets/appsettings.json')
          .pipe(
            map((x: AppConfig) => {
              config.config = x;
              resolve(true);
            }),
            catchError(
              (x: { status: number }, caught: Observable<void>): ObservableInput<{}> => {
                if (x.status !== 404) {
                  resolve(false);
                }
                config.config = {} as AppConfig;
                resolve(true);
                return of({});
              }
            )
          )
          .subscribe((x) => {
            config.configLoaded.next(config.config);
          });
      });
    };
  }

  public getApiPath(location: string): string {
    let url = `${this.config.endpoints.apiUrl}/api/${location}`;
    url = url.replace('//api', '/api');
    return url;
  }
}
