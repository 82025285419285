import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../auth.service';
import { Store } from '@ngrx/store';
import { LoginSilentSuccess } from '../../state/actions/auth.actions';
import { AppState } from '@app/state/app.state';

@Component({
  selector: 'app-oidc-silent-renew',
  templateUrl: './oidc-silent-renew.component.html',
  styleUrls: ['./oidc-silent-renew.component.scss'],
})
export class OidcSilentRenewComponent implements OnInit {
  constructor(private store: Store<AppState>, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.completeSilentAuthentication().subscribe((user) => {
      this.authService.utilityHubIdentity().subscribe((uhUser) => {
        this.store.dispatch(new LoginSilentSuccess(user, uhUser));
      });
    });
  }
}
