import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../shared/services/config.service';
import { Injectable } from '@angular/core';
import { DeleteBlobResult } from './models/DeleteBlobResult';
import { UdFile } from './UdFile';

@Injectable({
  providedIn: 'root',
})
export class DeleteFileService {
  constructor(private http: HttpClient, private appConfig: ConfigService) {}

  public deleteFile(file: UdFile, quoteRequestId?: number) {
    const path = this.appConfig.getApiPath('file/delete');
    return this.http.post<DeleteBlobResult>(path, { fileId: file.fileId, quoteRequestId: isNaN(quoteRequestId) ? null : quoteRequestId });
  }
}
