import { User, UserManager, WebStorageStateStore } from 'oidc-client';
import { Injectable } from '@angular/core';
import { ConfigService } from '../shared/services/config.service';
import { Observable } from 'rxjs';
import { UtilityHubIdentity } from './models/utility-hub-identity';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppInsights } from '@app/ai.service';
import * as Sentry from '@sentry/angular';
import * as LogRocket from 'logrocket';
import { CookieService } from 'ngx-cookie-service';
import { UtilityHubIdentitySet } from './state/actions/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
    private cookieService: CookieService,
    private store: Store<AppState>
  ) {}

  private manager: UserManager = null;

  public get userManager(): Observable<UserManager> {
    return new Observable<UserManager>((obs) => {
      if (this.manager) {
        return obs.next(this.manager);
      }
      this.configService.configLoaded.subscribe((config) => {
        if (config) {
          const portStr = window.location.port.length > 0 ? `:${window.location.port}` : '';
          this.manager = new UserManager({
            authority: config.auth.authority,
            client_id: 'energypricingtool-spa',
            redirect_uri: `${window.location.protocol}//${window.location.hostname}${portStr}/oidc-callback`,
            post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${portStr}/oidc-callback-signout`,
            response_type: 'code',
            scope: 'openid email profile roles energypricingtool-api udgs-utilityhub-api',
            filterProtocolClaims: true,
            loadUserInfo: true,
            automaticSilentRenew: true,
            staleStateAge: 600, // 10min
            includeIdTokenInSilentRenew: true,
            extraQueryParams: { 'powwr-branded': '1' },
            silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${portStr}/oidc-silent-renew`,
            userStore: new WebStorageStateStore({ store: localStorage }),
            stateStore: new WebStorageStateStore({ store: localStorage }),
          });
          return obs.next(this.manager);
        }
      });
    });
  }

  public utilityHubIdentity(): Observable<UtilityHubIdentity> {
    return new Observable<UtilityHubIdentity>((obs) => {
      this.loadIdentity().subscribe({
        next: (identity) => {
          this.setUserInstance(identity);
          obs.next(identity);
        },
        complete: () => {
          obs.complete();
        },
      });
    });
  }

  public hydrateUtilityHubIdentity(): void {
    this.loadIdentity().subscribe((identity) => {
      this.setUserInstance(identity);
      this.getUser().subscribe((user) => {
        this.store.dispatch(new UtilityHubIdentitySet(user, identity));
      });
    });
  }

  public loadIdentity(): Observable<UtilityHubIdentity> {
    const path = this.configService.getApiPath('utilityhub/identity');
    return this.http.get<UtilityHubIdentity>(path).pipe(map((data) => new UtilityHubIdentity(data)));
  }

  public getUser(): Observable<User> {
    return new Observable<User>((obs) => this.userManager.subscribe((mgr) => mgr.getUser().then((u) => obs.next(u))));
  }

  public login(): Observable<any> {
    return new Observable<any>((obs) => {
      return this.userManager.subscribe((mgr) => {
        return mgr.clearStaleState().then(() => {
          return obs.next(mgr.signinRedirect());
        });
      });
    });
  }

  public renewToken(): Observable<User> {
    return new Observable<User>((obs) => {
      this.userManager.subscribe((mgr) => mgr.signinSilent().then((u) => obs.next(u)));
    });
  }

  public logout(): Observable<any> {
    Sentry.configureScope((scope) => scope.setUser(null));
    return new Observable<any>((obs) => {
      return this.userManager.subscribe((mgr) => obs.next(mgr.signoutRedirect()));
    });
  }

  public completeAuthentication(): Observable<User> {
    return new Observable<User>((obs) => {
      this.userManager.subscribe((mgr) =>
        mgr
          .signinRedirectCallback()
          .then((u) => {
            this.setUserInstanceFromOidc(u);
            return obs.next(u);
          })
          .catch((err) => {
            return obs.error(err);
          })
      );
    });
  }

  public completeSilentAuthentication(): Observable<User> {
    return new Observable<User>((obs) => {
      this.userManager.subscribe((mgr) =>
        mgr.signinSilentCallback().then((u) => {
          this.setUserInstanceFromOidc(u);
          return obs.next(u);
        })
      );
    });
  }

  private setUserInstanceFromOidc(user: User): void {
    this.setUserOidcInstance(user.profile.email, user.profile.sub);
  }

  private setUserInstance(identity: UtilityHubIdentity): void {
    if (!this.cookieService.check(`active-organisation-${identity.id}`)) {
      this.cookieService.set(`active-organisation-${identity.id}`, identity.currentOrganisation.vanityName, { expires: 1000000 });
    }

    Sentry.setUser({ email: identity.email, id: identity.id });

    let tags = {
      powwrId: identity.currentOrganisation.externalId,
      role: identity.currentOrganisation.role,
    } as any;

    Sentry.setTags(tags);

    LogRocket.identify(identity.id, { email: identity.email });

    if (AppInsights?.telemetry) {
      AppInsights.telemetry.setAuthenticatedUserContext(identity.email, identity.id, true);
    }
  }

  private setUserOidcInstance(email: string, id: string): void {
    Sentry.setUser({ email, id });

    LogRocket.identify(id, { email });

    if (AppInsights?.telemetry) {
      AppInsights.telemetry.setAuthenticatedUserContext(email, id, true);
    }
  }
}
