import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from '@app/shared/services/config.service';
import { CreateShareQuoteRequest } from '../models/ShareQuote/CreateShareQuoteRequest';
import { ShareQuote } from '../models/ShareQuote/ShareQuote';

@Injectable({
    providedIn: 'root',
})

export class ShareQuoteService {
    constructor(private configService: ConfigService, private httpClient: HttpClient) {}

    public async get(quoteRequestId: number, guid: string): Promise<ShareQuote> {
        const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoteshare/${guid}`);
      const response = await (this.httpClient.get<ShareQuote>(path)).toPromise<ShareQuote>();

        return response;
    }

    public async post(quoteRequestId: number, createRequest: CreateShareQuoteRequest): Promise<ShareQuote> {
        const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoteshare/`);
      const response = await (this.httpClient.post<ShareQuote>(path, createRequest)).toPromise<ShareQuote>();

        return response;
  }

  public export(quoteRequestId: number, createRequest: CreateShareQuoteRequest) {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoteshare/export`);
    const response = this.httpClient.post<Blob>(path, createRequest, { responseType: 'blob' as 'json' });
    return response;
  }

    public async rejectPrice(quoteRequestId: number, guid: string, message: any): Promise<ShareQuote> {
        const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoteshare/${guid}/rejectAllPrices`);
      const response = await (this.httpClient.patch<ShareQuote>(path, message)).toPromise<ShareQuote>();

        return response;
    }

    public async acceptPrice(quoteRequestId: number, guid: string, term: number, planType: string): Promise<ShareQuote> {
        const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoteshare/${guid}/acceptPrice`);
      const response = await (this.httpClient.patch<ShareQuote>(path, { quotePriceTerm: term, planType: planType })).toPromise<ShareQuote>();

        return response;
    }

    public async incrementVisitCount(quoteRequestId: number, guid: string): Promise<ShareQuote> {
        const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoteshare/${guid}/incrementVisit`);
      const response = await (this.httpClient.patch<ShareQuote>(path, {})).toPromise<ShareQuote>();

        return response;
    }
}
