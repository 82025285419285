import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../../shared/components/confirm/confirm.component';
import { UdFile } from '../../UdFile';
import { DeleteFileService } from '../../delete-file.service';
import { WizardService } from '@app/wizard-bar/services/wizard.service';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';
import { isReadOnlySelector } from '@app/state/app.selectors';

@Component({
  selector: 'app-delete-file',
  templateUrl: './delete-file.component.html',
  styleUrls: ['./delete-file.component.scss'],
})
export class DeleteFileComponent implements OnInit, OnDestroy {
  @Input() file: UdFile;
  @Input() title: string;
  @Input() message: string;
  @Input() popupDeleteBtnText: string;
  @Input() showConfirmation: boolean = true;
  @Input('disabled') disabled: boolean;
  @Input() buttonText = 'Delete';
  @Input() quoteRequestId: number;
  @Input() changeInvalidatesQuoteRequest: boolean = false;
  @Output('after-delete') afterDelete = new EventEmitter();
  deleteFile$: Subscription;
  dialog$: Subscription;
  warningDialog$: Subscription;
  deleteService$: Subscription;

  // TODO: Parameter issues.
  // There are issues with this component due to the fact it take in conflicting parameters.
  // Really it should either take in the file object or just individual parameters
  constructor(public dialog: MatDialog, private deleteFileService: DeleteFileService, private wizardServer: WizardService, private store: Store<AppState>) {}

  ngOnDestroy(): void {
    this.deleteFile$?.unsubscribe();
    this.dialog$?.unsubscribe();
    this.warningDialog$?.unsubscribe();
    this.deleteService$?.unsubscribe();
  }

  ngOnInit() {
    this.store.select(isReadOnlySelector).subscribe((isReadOnly) => {
      this.disabled = isReadOnly;
    });
  }

  public deleteFile() {
    const fileName = this.file.documentName ? this.file.documentName : (this.file.documentDisplayName ? this.file.documentDisplayName + ' (' + this.file.fileName + ')' : this.file.fileName);

    if (!this.showConfirmation) {
      this.deleteFile$ = this.deleteFileService
        .deleteFile(this.file, this.quoteRequestId)
        .subscribe((x) => this.afterDelete.emit({ file: fileName, fileId: this.file.fileId }));
      return;
    }
    const dialogRef = this.dialog.open(ConfirmComponent, {
      width: '25%',
      data: {
        title: this.title ?? 'Delete File',
        message: this.message ?? 'Are you sure you want to delete ' + fileName + '?',
        primaryButtonText: this.popupDeleteBtnText ?? 'Delete',
      },
    });

    this.dialog$ = dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed && this.changeInvalidatesQuoteRequest) {
        const warningDialogRef = this.wizardServer.resolveExpireQuoteWarningDialog();
        if (warningDialogRef.required) {
          this.warningDialog$ = warningDialogRef.dialogRef.afterClosed().subscribe((cfrmWarning) => {
            if (cfrmWarning) {
              this.deleteService$ = this.deleteFileService
                .deleteFile(this.file, this.quoteRequestId)
                .subscribe((x) => this.afterDelete.emit({ file: fileName, fileId: this.file.fileId }));
              // TODO: Since the above emitted object is bubbled up through many controls it should really have a class.
            }
          });
        } else {
          this.deleteService$ = this.deleteFileService
            .deleteFile(this.file, this.quoteRequestId)
            .subscribe((x) => this.afterDelete.emit({ file: fileName, fileId: this.file.fileId }));
        }
      } else if (confirmed){
        this.deleteService$ = this.deleteFileService
          .deleteFile(this.file, this.quoteRequestId)
          .subscribe((x) => this.afterDelete.emit({ file: fileName, fileId: this.file.fileId }));
      }
    });
  }
}
