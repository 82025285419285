import { Component, OnInit } from '@angular/core';
import { angularMaterialRenderers } from '@jsonforms/angular-material';
import { NotificationService } from '@app/shared/services/notification.service';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';
import SchemaAsset from '../../../../assets/settings-notification-schema.json';
import DisplaySchemaAsset from '../../../../assets/settings-notification-schema-ui.json';
import { CheckboxGroupControlRenderer } from './CustomControls/checkbox-button-controls';
import { CheckboxGroupControlRendererTester } from './CustomControls/checkbox-button-controls';
import { Router } from '@angular/router';
import { loggedInUserSelector, loggedInUtilityHubIdentitySelector, configurationSelector } from '@app/state/app.selectors';

@Component({
  selector: 'settings-notifications',
  templateUrl: './settings-notifications.component.html',
  styleUrls: ['./settings-notifications.component.scss'],
})

export class SettingsNotificationsComponent implements OnInit {

  renderers = [
    ...angularMaterialRenderers,
    { renderer: CheckboxGroupControlRenderer, tester: CheckboxGroupControlRendererTester }
  ];

  NotificationDataSchema: any = SchemaAsset;
  NotificationDataSchemaUI: any = DisplaySchemaAsset;

  public userId: string;
  public userRole: string;
  public docuSignEnabled: boolean = false;
  public notificationData: any;
  public initialNotificationData: any;
  loading = true;
  selectLoggedInUserSubscription$: any;
  selectLoggedInUtilityHubIdentitySubscription$: any;
  onChange: any = () => { };
  defaultNotificationData: any =
    {
      "usageDataReceived": {
        "Email": true,
        "Text": false
      },
      "contractExpiringSoon": {
        "Email": true,
        "Text": true,
        "days01": true,
        "days45": false
      }
    };

  constructor(private notificationService: NotificationService, private router: Router,
    private store: Store<AppState>
  ) { }

  async ngOnInit() {
    this.selectLoggedInUserSubscription$ = this.store.select(loggedInUserSelector).subscribe((user) => {
      if (user && !user.expired) {
        this.selectLoggedInUtilityHubIdentitySubscription$ = this.store
          .select(loggedInUtilityHubIdentitySelector)
          .subscribe((uhUser) => {
            this.userId = uhUser.id;
            this.userRole = uhUser.currentOrganisation.role;
          });
        this.store.select(configurationSelector).subscribe((config) => {
          console.log("settinsnotificationcomponent");

          this.docuSignEnabled = config.supplierConfiguration.enableDocuSignContracting ?? false;
        });
      }
    });
    await this.loadNotification();
    this.loading = false;
  }

  async loadNotification(): Promise<any> {
    this.notificationData =
    {
      ...(await this.getNotificationData()),
      "userRole": this.userRole.toString(),
      "docusignEnabled": this.docuSignEnabled
    }
    this.initialNotificationData = this.notificationData;
  }

  async getNotificationData(): Promise<any> {
    try {
      const notification = await this.notificationService.getNotification(this.userId);
      return notification.notificationData;
    }
    catch (error) {
      this.notificationData = this.defaultNotificationData;
    }
  }


  public setData($event) {
    this.notificationData = $event;
  }

  public saveNotification() {
    var saveData = this.notificationData;
    delete saveData.userRole;
    delete saveData.docusignEnabled;
    console.log(saveData);
    this.notificationService.saveNotification(this.userId, saveData);
    return true;
  }

  public resetNotification ()
  {
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
  }
}
