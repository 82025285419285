import { WizardStep } from '@app/wizard-bar/services/wizard.service';

export class CustomConstants {
  public static wizardSteps: WizardStep[] = [
    { name: 'Customer Details', svg: 'company-details', slug: 'customer-details' },
    { name: 'Utility Accounts', svg: 'supply-points', slug: 'utility-accounts' },
    { name: 'Usage Data', svg: 'consumption-upload', slug: 'consumption-data' },
    { name: 'File Validation', svg: 'file-validation', slug: 'file-validation' },
    { name: 'Supply Details', svg: 'supply-details', slug: 'supply-details' },
    { name: 'Get Quote', svg: 'icon-quote', slug: 'get-quote' },
    { name: 'Contract Details', svg: 'icon-contract', slug: 'contract-details' },
    { name: "You're Done!", svg: 'youre-done', slug: 'youre-done' },
  ];

  public static wizardStepsQuickSubmit: WizardStep[] = [
    { name: 'Customer Details', svg: 'company-details', slug: 'customer-details' },
    { name: 'Utility Accounts', svg: 'supply-points', slug: 'utility-accounts' },
    { name: 'Add Quote', svg: 'icon-quote', slug: 'get-quote' },
    { name: 'Contract Details', svg: 'icon-contract', slug: 'contract-details' },
    { name: "You're Done!", svg: 'youre-done', slug: 'youre-done' },
  ];
}
