import { map } from 'rxjs/operators';
import { SupplierPricingAdderModel } from './../../../shared/models/utility-information';
import { Store } from '@ngrx/store';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { UtilityHubService } from '@app/shared/services/utilityhub.service';
import { AppState } from '@app/state/app.state';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';
import { CommodityType } from '@app/shared/enums/commodity-type';
import { Subscription, Observable } from 'rxjs';
import { AdderType } from '@app/shared/enums/adder-type';
import { processQuoteSettingData } from '@app/settings/setting-helper/setting-helper';
import { utilityUOMSelector } from '@utility-information/state/selectors/utility-information.selectors';
import { loggedInUtilityHubIdentitySelector, configurationSelector } from '@app/state/app.selectors';

@Component({
  selector: 'quote-settings',
  templateUrl: './quote-settings.component.html',
  styleUrls: ['./quote-settings.component.scss'],
})
export class SettingQuoteComponent implements OnInit, OnDestroy {
  supplierId: number;
  isDisabled: any;
  utilityUOM$: Observable<string>;
  electricityAllowCustom: boolean;
  electricityAllowMatrix: boolean;
  gasAllowCustom: boolean;
  gasAllowMatrix: boolean;
  selectUtilityHubConfiguration$: Subscription;
  hasData: boolean;

  public get setElectricityAdditionalCostMatrix() {
    return this.frmSettingQuote?.get('setAdditionalCostMatrix').value;
  }
  public get setElectricityAdditionalCostCustom() {
    return this.frmSettingQuote?.get('setAdditionalCostCustom').value;
  }
  public get matrixAdditionalCostIsoneCrtl() {
    return this.frmSettingQuote?.get('matrixAdditionalCostIsone');
  }
  public get matrixAdditionalCostErcotCrtl() {
    return this.frmSettingQuote?.get('matrixAdditionalCostErcot');
  }
  public get matrixAdditionalCostMisoCrtl() {
    return this.frmSettingQuote?.get('matrixAdditionalCostMiso');
  }
  public get matrixAdditionalCostNyisoCrtl() {
    return this.frmSettingQuote?.get('matrixAdditionalCostNyiso');
  }
  public get matrixAdditionalCostPjmCrtl() {
    return this.frmSettingQuote?.get('matrixAdditionalCostPjm');
  }
  public get customAdditionalCostErcotCrtl() {
    return this.frmSettingQuote?.get('customAdditionalCostErcot');
  }
  public get customAdditionalCostIsonertl() {
    return this.frmSettingQuote?.get('customAdditionalCostIsone');
  }

  public frmSettingQuote: FormGroup;
  public matrixElecticity: FormGroup;
  public customElecticity: FormGroup;
  isEditable: any;
  selectLoggedInUtilityHubIdentitySubscription$: Subscription;
  userId: string;

  constructor(private utilityHubService: UtilityHubService, private store: Store<AppState>, private formBuilder: FormBuilder) {
    this.utilityUOM$ = this.store.select(utilityUOMSelector);
  }

  ngOnDestroy(): void {
    this.selectLoggedInUtilityHubIdentitySubscription$.unsubscribe();
    this.selectUtilityHubConfiguration$.unsubscribe();
  }

  ngOnInit() {
    this.selectLoggedInUtilityHubIdentitySubscription$ = this.store
      .select(loggedInUtilityHubIdentitySelector)
      .subscribe((uhUser) => {
        this.userId = uhUser.id;
        this.supplierId = +uhUser?.currentOrganisation?.id;
      });
    this.selectUtilityHubConfiguration$ = this.store
      .select(configurationSelector)
      .pipe(map(({ supplierConfiguration }) => ({ supplierConfiguration })))
      .subscribe((config) => {
        const { pricing } = config.supplierConfiguration;
        this.electricityAllowCustom = pricing.electric.allowCustom;
        this.electricityAllowMatrix = pricing.electric.allowMatrix;
        this.gasAllowCustom = pricing.gas.allowCustom;
        this.gasAllowMatrix = pricing.gas.allowMatrix;
      });
    this.initialForms();
    this.load();
  }
  updateValueAndValidityforCustom() {
    this.customAdditionalCostErcotCrtl.updateValueAndValidity();
    this.customAdditionalCostIsonertl.updateValueAndValidity();
    this.frmSettingQuote?.get('customAdditionalCostMiso').updateValueAndValidity();
    this.frmSettingQuote?.get('customAdditionalCostNyiso').updateValueAndValidity();
    this.frmSettingQuote?.get('customAdditionalCostPjm').updateValueAndValidity();
  }
  updateValueAndValidityforMatrix() {
    this.matrixAdditionalCostIsoneCrtl.updateValueAndValidity();
    this.matrixAdditionalCostErcotCrtl.updateValueAndValidity();
    this.matrixAdditionalCostMisoCrtl.updateValueAndValidity();
    this.matrixAdditionalCostNyisoCrtl.updateValueAndValidity();
    this.matrixAdditionalCostPjmCrtl.updateValueAndValidity();
  }
  clearValidationCustom() {
    this.customAdditionalCostErcotCrtl.clearValidators();
    this.customAdditionalCostIsonertl.clearValidators();
    this.frmSettingQuote?.get('customAdditionalCostMiso').clearValidators();
    this.frmSettingQuote?.get('customAdditionalCostNyiso').clearValidators();
    this.frmSettingQuote?.get('customAdditionalCostPjm').clearValidators();
    this.updateValueAndValidityforCustom();
  }
  clearValidationMatrix() {
    this.matrixAdditionalCostIsoneCrtl.clearValidators();
    this.matrixAdditionalCostErcotCrtl.clearValidators();
    this.matrixAdditionalCostMisoCrtl.clearValidators();
    this.matrixAdditionalCostNyisoCrtl.clearValidators();
    this.matrixAdditionalCostPjmCrtl.clearValidators();
    this.updateValueAndValidityforMatrix();
  }

  initialForms() {
    this.frmSettingQuote = this.formBuilder.group({
      matrixAdditionalCostOverall: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      setAdditionalCostMatrix: [false],
      matrixAdditionalCostIsone: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      matrixAdditionalCostErcot: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      matrixAdditionalCostMiso: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      matrixAdditionalCostNyiso: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      matrixAdditionalCostPjm: [0.0, Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])],
      customAdditionalCostErcot: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      customAdditionalCostIsone: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      customAdditionalCostMiso: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      customAdditionalCostNyiso: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      customAdditionalCostPjm: [0.0, Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])],
      customAdditionalCostOverall: [
        0.0,
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ],
      setAdditionalCostCustom: [false],
      gascustomAdditionalCost: [0.0, Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])],
      gasmatrixAdditionalCost: [0.0, Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])],
    });
  }
  setValidation() {
    if (this.setElectricityAdditionalCostMatrix) {
      this.clearValidationCustom();
      this.matrixAdditionalCostIsoneCrtl.setValidators([
        Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]),
      ]);
      this.frmSettingQuote
        ?.get('matrixAdditionalCostErcot')
        .setValidators([Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])]);
      this.frmSettingQuote
        ?.get('matrixAdditionalCostMiso')
        .setValidators([Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])]);
      this.frmSettingQuote
        ?.get('matrixAdditionalCostNyiso')
        .setValidators([Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])]);
      this.frmSettingQuote
        ?.get('matrixAdditionalCostPjm')
        .setValidators([Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)])]);
    } else {
      this.clearValidationMatrix();
    }
    if (this.setElectricityAdditionalCostCustom) {
      this.clearValidationMatrix();
      this.frmSettingQuote
        ?.get('customAdditionalCostErcot')
        .setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]));
      this.frmSettingQuote
        ?.get('customAdditionalCostIsone')
        .setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]));
      this.frmSettingQuote
        ?.get('customAdditionalCostMiso')
        .setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]));
      this.frmSettingQuote
        ?.get('customAdditionalCostNyiso')
        .setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]));
      this.frmSettingQuote
        ?.get('customAdditionalCostPjm')
        .setValidators(Validators.compose([Validators.required, Validators.pattern(/^(?:-?\d{0,9}\.\d{1,6})$|^-?\d{0,6}$/)]));
    } else {
      this.clearValidationCustom();
    }
  }

  load() {
    this.utilityHubService.getsupplierPricingAdder(this.supplierId).subscribe((x) => this.instantiateForm(x));
  }

  instantiateForm(supplierAdders: SupplierPricingAdderModel[]) {
    if (supplierAdders.length == 0) return;
    this.isEditable = supplierAdders.length > 0;
    this.hasData = supplierAdders.length > 0;
    this.disableForm(this.isEditable);
    supplierAdders.forEach((element) => {
      this.populateFormWithData(element);
    });
  }

  populateFormWithData(element: SupplierPricingAdderModel) {
    if (element.commodityType == CommodityType.Gas && element.quoteType == QuoteRequestType.Matrix && element.adderType == AdderType.overall) {
      this.frmSettingQuote?.get('gasmatrixAdditionalCost').patchValue(element.adderMillRate);
    }
    if (element.commodityType == CommodityType.Gas && element.quoteType == QuoteRequestType.Custom && element.adderType == AdderType.overall) {
      this.frmSettingQuote?.get('gascustomAdditionalCost').patchValue(element.adderMillRate);
    }
    if (
      element.commodityType == CommodityType.Electricity &&
      element.quoteType == QuoteRequestType.Matrix &&
      element.adderType == AdderType.overall
    ) {
      this.frmSettingQuote?.get('matrixAdditionalCostOverall').patchValue(element.adderMillRate);
    }
    if (
      element.commodityType == CommodityType.Electricity &&
      element.quoteType == QuoteRequestType.Custom &&
      element.adderType == AdderType.overall
    ) {
      this.frmSettingQuote?.get('customAdditionalCostOverall').patchValue(element.adderMillRate);
    }
    this.setMatrixAndCustomValuesForElectricity(element);
  }

  setMatrixAndCustomValuesForElectricity(element: SupplierPricingAdderModel) {
    if (
      element.commodityType == CommodityType.Electricity &&
      element.quoteType == QuoteRequestType.Matrix &&
      element.adderType != AdderType.overall
    ) {
      this.frmSettingQuote?.get('setAdditionalCostMatrix').patchValue(true);
      switch (element.adderType) {
        case AdderType.ercot: {
          this.matrixAdditionalCostErcotCrtl.patchValue(element.adderMillRate);
          break;
        }
        case AdderType.isone: {
          this.matrixAdditionalCostIsoneCrtl.patchValue(element.adderMillRate);
          break;
        }
        case AdderType.miso: {
          this.matrixAdditionalCostMisoCrtl.patchValue(element.adderMillRate);
          break;
        }
        case AdderType.nyiso: {
          this.matrixAdditionalCostNyisoCrtl.patchValue(element.adderMillRate);
          break;
        }
        case AdderType.pjm: {
          this.matrixAdditionalCostPjmCrtl.patchValue(element.adderMillRate);
          break;
        }
        default: {
          break;
        }
      }
    }
    if (
      element.commodityType == CommodityType.Electricity &&
      element.quoteType == QuoteRequestType.Custom &&
      element.adderType != AdderType.overall
    ) {
      this.frmSettingQuote?.get('setAdditionalCostCustom').patchValue(true);
      switch (element.adderType) {
        case AdderType.ercot: {
          this.customAdditionalCostErcotCrtl.patchValue(element.adderMillRate);
          break;
        }
        case AdderType.isone: {
          this.customAdditionalCostIsonertl.patchValue(element.adderMillRate);
          break;
        }
        case AdderType.miso: {
          this.frmSettingQuote?.get('customAdditionalCostMiso').patchValue(element.adderMillRate);
          break;
        }
        case AdderType.nyiso: {
          this.frmSettingQuote?.get('customAdditionalCostNyiso').patchValue(element.adderMillRate);
          break;
        }
        case AdderType.pjm: {
          this.frmSettingQuote?.get('customAdditionalCostPjm').patchValue(element.adderMillRate);
          break;
        }
        default: {
          break;
        }
      }
    }
  }
  disableOverallProperties() {
    this.setElectricityAdditionalCostMatrix
      ? this.frmSettingQuote?.get('matrixAdditionalCostOverall').disable()
      : this.frmSettingQuote?.get('matrixAdditionalCostOverall').enable();
    this.setElectricityAdditionalCostCustom
      ? this.frmSettingQuote?.get('customAdditionalCostOverall').disable()
      : this.frmSettingQuote?.get('customAdditionalCostOverall').enable();
  }

  setAdditionalCostMatrix() {
    let value = this.frmSettingQuote?.get('matrixAdditionalCostOverall').value;
    this.disableOverallProperties();
    this.matrixAdditionalCostIsoneCrtl.patchValue(value);
    this.matrixAdditionalCostErcotCrtl.patchValue(value);
    this.matrixAdditionalCostMisoCrtl.patchValue(value);
    this.matrixAdditionalCostNyisoCrtl.patchValue(value);
    this.matrixAdditionalCostPjmCrtl.patchValue(value);
  }
  setAdditionalCostCustom() {
    let value = this.frmSettingQuote?.get('customAdditionalCostOverall').value;
    this.disableOverallProperties();
    this.customAdditionalCostIsonertl.patchValue(value);
    this.customAdditionalCostErcotCrtl.patchValue(value);
    this.frmSettingQuote?.get('customAdditionalCostMiso').patchValue(value);
    this.frmSettingQuote?.get('customAdditionalCostNyiso').patchValue(value);
    this.frmSettingQuote?.get('customAdditionalCostPjm').patchValue(value);
  }

  saves() {
    if (this.hasData) {
      this.update();
    } else {
      this.setValidation();
      this.frmSettingQuote.markAllAsTouched();

      if (!this.frmSettingQuote.valid) return;

      let supplierPricingAdderModel = processQuoteSettingData(
        this.frmSettingQuote.getRawValue(),
        this.userId,
        this.electricityAllowCustom,
        this.electricityAllowMatrix,
        this.gasAllowCustom,
        this.gasAllowMatrix
      );
      this.utilityHubService.saveSupplierPricingAdder(this.supplierId, supplierPricingAdderModel).subscribe((success) => {
        this.refresh();
        this.load();
      });
    }
  }

  private refresh() {
    window.location.reload();
  }

  disableForm(editable: boolean) {
    if (editable) {
      this.frmSettingQuote.disable();
    }
  }
  reset() {
    this.frmSettingQuote.reset();
    this.initialForms();
    this.load();
  }

  edit() {
    this.isEditable = false;
    this.frmSettingQuote.enable();
  }

  update() {
    this.setValidation();
    this.frmSettingQuote.markAllAsTouched();
    if (!this.frmSettingQuote.valid) return;
    let supplierPricingAdderModel = processQuoteSettingData(
      this.frmSettingQuote.getRawValue(),
      this.userId,
      this.electricityAllowCustom,
      this.electricityAllowMatrix,
      this.gasAllowCustom,
      this.gasAllowMatrix
    );
    this.utilityHubService.updateSupplierPricingAdder(this.supplierId, supplierPricingAdderModel).subscribe((success) => {
      this.refresh();
      this.load();
    });
  }
}
