<div>
  <div class="dialog-heading">
    <h5>{{ data.title }}</h5>
    <span style="flex: 1 1 auto"></span>
    <mat-icon class="close-dialog" aria-hidden="false" mat-dialog-close aria-label="Close icon">close</mat-icon>
  </div>
  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content>
    <p class="mat-body" [innerHTML]="data.message"></p>
    <div class="mat-typography" *ngIf="data?.listData">
      <p *ngFor="let data of data.listData">{{ data }}</p>
    </div>
  </mat-dialog-content>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <button id="confirmPrimaryBtn" *ngIf="!data.hideCancel" mat-stroked-button color="accent" [mat-dialog-close]="true">{{ data.primaryButtonText }}</button>
    <button id="confirmPrimaryBtnHiddenCancel" *ngIf="data.hideCancel" mat-raised-button color="accent" [mat-dialog-close]="true">{{ data.primaryButtonText }}</button>
    <button mat-raised-button color="accent" mat-dialog-close *ngIf="!data.hideCancel">{{ data.negativeButtonText || 'Cancel' }}</button>
  </mat-dialog-actions>
</div>
