import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';
import { AuthService } from '../../../auth/auth.service';
import { UserInfo } from '../../../shared/models/utility-information';
import { UtilityHubService } from '../../../shared/services/utilityhub.service';
import { loggedInUserSelector, loggedInUtilityHubIdentitySelector } from '@app/state/app.selectors';


@Component({
  selector: 'settings-myaccount',
  templateUrl: './settings-myaccount.component.html',
  styleUrls: ['./settings-myaccount.component.scss'],
})
export class SettingsMyaccountLayoutComponent implements OnInit {
  public frmUser: FormGroup;
  public userId: string;
  public isEditable: boolean;
  selectLoggedInUserSubscription$: any;
  selectLoggedInUtilityHubIdentitySubscription$: any;


  //example
  //phonenumber format
  //+1 717-827-7017
  //+1 (717)-827-7017
  //+1 (555)555-5556
  
  constructor(
    private utilityHubService: UtilityHubService,
    private authService: AuthService,
    private activedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private fb: FormBuilder
  )
 {
    this.frmUser = this.fb.group({
      fullName: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(254)])],
      phoneNumber: ['', Validators.compose([Validators.required,  Validators.maxLength(24)])],
      mobileNumber: ['', Validators.compose([Validators.required, Validators.maxLength(24)])],
    });
  }

  ngOnInit(): void {
    this.initUser();
  }

  private initUser() {
    this.isEditable = false;
    this.loadUser();
    this.setVisibility();
  }

  private loadUser() {
    this.selectLoggedInUserSubscription$ = this.store.select(loggedInUserSelector).subscribe((user) => {
      if (user && !user.expired) {
        this.selectLoggedInUtilityHubIdentitySubscription$ = this.store
          .select(loggedInUtilityHubIdentitySelector)
          .subscribe((uhUser) => {
            this.userId = uhUser.id;
            this.frmUser.get("fullName").setValue(uhUser.fullName);
            this.frmUser.get("email").setValue(uhUser.email);
            this.frmUser.get("phoneNumber").setValue(uhUser.phoneNumber);
            this.frmUser.get("mobileNumber").setValue(uhUser.mobileNumber);
          });
      }
    });
  }

  private refreshUser() {
    this.authService.loadIdentity().subscribe((res) => {
      window.location.reload();
    });
  }

  private setVisibility() {
    this.frmUser.get("email").disable();
    if (this.isEditable) {
      this.frmUser.get("fullName").enable();
      this.frmUser.get("phoneNumber").enable();
      this.frmUser.get("mobileNumber").enable();
    }
    else {
      this.frmUser.get("fullName").disable();
      this.frmUser.get("phoneNumber").disable();
      this.frmUser.get("mobileNumber").disable();
    }

  }

  public editUser() {
    this.isEditable = true;
    this.setVisibility();
  }

  public reset() {
    this.frmUser.reset();
    this.initUser()
  }

  public saveUser() {
    this.frmUser.markAllAsTouched();
    this.frmUser.updateValueAndValidity();

    if (this.frmUser.valid) {
      const user = new UserInfo();
      user.Id = this.userId;
      user.FullName = this.frmUser.get("fullName").value;
      user.PhoneNumber = this.frmUser.get("phoneNumber").value;
      user.MobileNumber = this.frmUser.get("mobileNumber").value;
      user.Email = this.frmUser.get('email').value;
      this.utilityHubService.saveUser(user).subscribe((success) => {
        const diagArgs = { title: 'User Updated', message: `Email update confirmation sent.` };
        if (success) {
          this.refreshUser();
        } else {
          diagArgs.title = 'Error';
          diagArgs.message = 'Could not update email. Either email is already in use or there was an issue.';
        }
      });
  }
  }
}
