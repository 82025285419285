import { UtilityInformationState } from '../utility-information.state';
import { AppState } from '@app/state/app.state';
import { UsageSourceMap } from '../../models/usage-source-map';
import { MarginTerm } from '@app/quoting/models/quote-request-summary';
import { UtilityInformation, UtilityZoneInformation } from '@app/shared/models/utility-information';
import { SupplierUtilityModel } from '@app/shared/models/supplier-utility-model';
import { ProductTypeConfiguration } from '@configuration/models/product-type-configuration';
import { UtilityHubConfiguration } from '@configuration/models/utilityhub-configuration';
import { Offer } from '@app/quoting/models/quote';
import { MonthlyConsumptionData } from '@app/shared/components/more-details/more-details.component';
import { CommodityType } from '@app/shared/enums/commodity-type';
import { removeDuplicateObjects, removeDuplicatePrimitives } from '@app/shared/helpers/array-helper';
import { nameof } from '@app/shared/helpers/object-helper';
import { ContractLength } from '@app/shared/models/contract-length';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';

export const selectUtilityInformationState = (state: UtilityInformationState) => state;

export function usageSourcesSelector(state: AppState): UsageSourceMap[] {
  return state.utilityInformationState?.usageSources ?? [];
}

export function brokerageNameSelector(state: AppState): string {
  return state.utilityInformationState?.brokerageName ?? '';
}

export function marginDollarSelector(state: AppState): number {
  return state.utilityInformationState?.marginDollar ?? 0;
}

export function marginTermSelector(state: AppState): MarginTerm[] {
  return state.utilityInformationState?.marginTerm ?? [];
}

export function customerTypeSelector(state: AppState): string {
  return state.utilityInformationState?.customerType ?? '';
}

export function customerTypeSizeSelector(state: AppState): string {
  return state.utilityInformationState?.customerTypeSize ?? '';
}

export function salesTypeSelector(state: AppState): string {
  return state.utilityInformationState?.salesType ?? '';
}

export function stateSelector(state: AppState): string {
  return state.utilityInformationState?.state ?? '';
}

export function zipCodeSelector(state: AppState): string {
  return state.utilityInformationState?.zipCode ?? '';
}

export function commoditySelector(state: AppState): string {
  return state.utilityInformationState?.commodityType ?? '';
}

export function utilitySelector(state: AppState): string {
  return state.utilityInformationState?.utility ?? '';
}

export function commodityTypeSelector(state: AppState): string {
  return state.utilityInformationState?.commodityType ?? '';
}

export function utilityInformationSelector(state: AppState): UtilityInformation[] {
  return state.utilityInformationState?.utilityInformation ?? [];
}

export function supplierUtilityInformationSelector(state: AppState): SupplierUtilityModel[] {
  return state.utilityInformationState?.supplierUtilityInformation ?? [];
}

export function isDynamicSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.powwrSupplier;
}

// SALES360-1998 We will use the SupplierConfiguration pricing electric and gas to determine the journey
export function isCustomJourneyEnabledSelector(state: AppState): boolean {
  return (
    (state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.allowCustom ?? true) ||
    (state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.allowCustom ?? true)
  );
}

export function isMatrixJourneyEnabledSelector(state: AppState): boolean {
  return (
    (state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.allowMatrix ?? true) ||
    (state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.allowMatrix ?? true)
  );
}

export function isQuickQuoteJoruneyEnabledSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.configuration?.quoteTypes?.quickQuoteJourneyEnabled;
}

export function isElectricEnabledSelector(state: AppState): boolean {
  return (
    state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.allowCustom ||
    state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.allowMatrix
  );
}

export function isGasEnabledSelector(state: AppState): boolean {
  return (
    state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.allowCustom ||
    state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.allowMatrix
  );
}

// SALES360-1998 We will use the SupplierConfiguration pricing "electric, gas" and "allowCustom, allowMatrix" to determine the journey commodity
export function isCustomJourneyElectricEnabledSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.allowCustom ?? false;
}

export function isCustomJourneyGasEnabledSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.allowCustom ?? false;
}

export function isMatrixJourneyElectricEnabledSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.allowMatrix ?? false;
}

export function isMatrixJourneyGasEnabledSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.allowMatrix ?? false;
}

export function isReportEnabledSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.configuration?.reportsEnabled ?? false;
}

export function isDisplayReportsEnabledSelector(state: AppState): boolean {
  return state?.configurationModuleState?.config?.configuration?.displayReports ?? false;
}

export function utilityhubConfigurationSelector(state: AppState): UtilityHubConfiguration {
  return state?.configurationModuleState?.config;
}

export function contractLengthsFromProductTypeSelector(state: AppState): ContractLength[] {
  const productTypes = productTypesFromConfigSelector(state);
  const selectedProductType = state?.utilityInformationState?.productType;
  const currentSelectedProductTypes = state?.utilityInformationState?.contractLengths_Selected;

  return (
    currentSelectedProductTypes ??
    productTypes
      .filter((productType) => productType.productType === selectedProductType)[0]
      ?.contractLength.split(',')
      .map((x) => ({ months: +x, selected: true } as ContractLength))
  );
}

export function productTypesSelector(state: AppState): string[] {
  return removeDuplicatePrimitives([
    'Show All',
    ...removeDuplicatePrimitives(state.quotingModuleState?.quote?.prices?.filter((price) => price.planType).map((price) => price.planType)),
  ]);
}

export function productTypeSelector(state: AppState): string {
  return state.utilityInformationState?.productType;
}

export function zonesSelector(state: AppState): string[] {
  let uzi: UtilityZoneInformation[] = [];
  let uinfoList = state.utilityInformationState?.utilityInformation;
  let selectedUtility = state.utilityInformationState?.utility;

  if (!selectedUtility || !uinfoList || typeof uinfoList === 'string') return [];

  for (let i = 0; i < uinfoList.length; i++) {
    if (uinfoList[i].utilityZoneInformation) {
      uzi = [...uzi, ...uinfoList[i].utilityZoneInformation?.filter((uzinfo) => uzinfo.utilityShort === selectedUtility)] || [];
    } else {
      uzi = [];
    }
    
  }
  return uzi.map((x) => x.loadZone) ?? [];
}

export function rateCodesSelector(state: AppState): string[] {
  let uzi: UtilityZoneInformation[] = [];
  let uinfoList = state.utilityInformationState?.utilityInformation;
  let selectedUtility = state.utilityInformationState?.utility;

  if (!selectedUtility || !uinfoList || typeof uinfoList === 'string') return [];

  for (let i = 0; i < uinfoList.length; i++) {
    uzi = [...uzi, ...uinfoList[i].utilityZoneInformation?.filter((uzinfo) => uzinfo.utilityShort === selectedUtility)] || [];
  }
  return uzi.map((x) => x.loadZone) ?? [];
}

export function weatherZonesSelector(state: AppState): string[] {
  let uzi: UtilityZoneInformation[] = [];
  let uinfoList = state.utilityInformationState?.utilityInformation;
  let selectedUtility = state.utilityInformationState?.utility;

  if (!selectedUtility || !uinfoList || typeof uinfoList === 'string') return [];

  for (let i = 0; i < uinfoList.length; i++) {
    uzi = [...uzi, ...uinfoList[i].utilityZoneInformation?.filter((uzinfo) => uzinfo.utilityShort === selectedUtility)] || [];
  }
  return Array.from(new Set(uzi.map((x) => x.weatherZone)));
}

export function isoSelector(state: AppState): string {
  let uinfoList = state.utilityInformationState?.utilityInformation;
  let selectedUtility = state.utilityInformationState?.utility;

  if (!selectedUtility || !uinfoList || typeof uinfoList === 'string') return;

  const selectedUInfos = uinfoList.filter((uinfo) => uinfo.utilityShort === selectedUtility);
  if (selectedUInfos.length < 1) return;

  return selectedUInfos[0].iso;
}

export function pricesSelector(state: AppState): Offer[] {
  return state.quotingModuleState?.quote?.prices;
}

export function productTypeFiltersSelector(state: AppState): string[] {
  return state?.utilityInformationState?.productTypeFilters;
}

export function contractLengths_Selected(state: AppState): ContractLength[] {
  return state?.utilityInformationState?.contractLengths_Selected;
}

export function contractLengthsWithSelectedSelector(state: AppState): ContractLength[] {
  const productType = state?.utilityInformationState?.productType;
  const productTypes = productTypesFromConfigSelector(state);
  const selectedProductType = productTypes?.filter((x) => x.productType === productType);
  const selectedContractLengths = contractLengths_Selected(state);
  const pageName = state?.utilityInformationState?.pageName;
  let getQuoteStartingButtons = state?.utilityInformationState?.getQuoteContractLengthStartingButtons;

  if (selectedContractLengths && selectedProductType?.length > 0 && pageName === 'getquote') {
    getQuoteStartingButtons = selectedContractLengths;
    return (
      getQuoteStartingButtons ??
      selectedProductType[0]?.contractLength.split(',').map(
        (x) =>
          ({
            months: +x,
            selected: selectedContractLengths
              .filter((y) => y.selected === true)
              .map((z) => z.months)
              .includes(+x),
          } as ContractLength)
      )
    );
  }

  if (selectedContractLengths && selectedProductType?.length > 0 && pageName === 'companydetails') {
    return selectedProductType[0]?.contractLength.split(',').map(
      (x) =>
        ({
          months: +x,
          selected: selectedContractLengths
            .filter((y) => y.selected === true)
            .map((z) => z.months)
            .includes(+x),
        } as ContractLength)
    );
  }

  return selectedProductType[0]?.contractLength.split(',').map((x) => ({ months: +x, selected: true } as ContractLength));
}

export function sweetSpotFilterSelector(state: AppState): boolean {
  return state?.utilityInformationState?.showSweetSpotFilter;
}

export function sweetSpotCheckBoxStateSelector(state: AppState): boolean {
  return state?.utilityInformationState?.sweetSpotCheckBoxState;
}

export function sweetSpotWasRequestedSelector(state: AppState): boolean {
  return state?.utilityInformationState?.sweetSpotWasRequested;
}

export function filteredPricesSelector(state: AppState): Offer[] {
  const prices = pricesSelector(state);
  const ptFilters = productTypeFiltersSelector(state);
  const clFilters = contractLengths_Selected(state);
  const ssFilter = sweetSpotCheckBoxStateSelector(state);
  // return prices;
  return prices
    ?.filter(
      (price) =>
        clFilters
          ?.filter((x) => x.selected === true)
          .map((x) => x.months)
          .includes(price.term) ||
        (ssFilter && ssFilter === price.isSweetSpot)
    )
    .filter((price) => ptFilters?.includes(price.planType) || ptFilters?.includes('Show All'));
}

export function productTypesFromConfigSelector(state: AppState): ProductTypeConfiguration[] {
  if (state?.utilityInformationState?.quoteRequestType === QuoteRequestType.Custom) {
    if (state?.utilityInformationState?.commodityType === CommodityType.Electricity)
      return removeDuplicateObjects(
        state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.customPricingConfiguration?.productTypes,
        nameof<ProductTypeConfiguration>('productType')
      );
    if (state?.utilityInformationState?.commodityType === CommodityType.Gas)
      return removeDuplicateObjects(
        state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.customPricingConfiguration?.productTypes,
        nameof<ProductTypeConfiguration>('productType')
      );
  }
  if (state?.utilityInformationState?.quoteRequestType === QuoteRequestType.Matrix) {
    if (state?.utilityInformationState?.commodityType === CommodityType.Electricity)
      return removeDuplicateObjects(
        state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.electric?.matrixPricingConfiguration?.productTypes,
        nameof<ProductTypeConfiguration>('productType')
      );
    if (state?.utilityInformationState?.commodityType === CommodityType.Gas)
      return removeDuplicateObjects(
        state?.configurationModuleState?.config?.supplierConfiguration?.pricing?.gas?.matrixPricingConfiguration?.productTypes,
        nameof<ProductTypeConfiguration>('productType')
      );
  }
  if (state?.utilityInformationState?.quoteRequestType === QuoteRequestType.QuickQuote) {
    return [{ productType: 'Fixed', contractLength: '6,12,18,24,30,36' }] as ProductTypeConfiguration[];
  }
}

export function consumptionDataSelector(state: AppState): MonthlyConsumptionData[] {
  return state?.utilityInformationState?.consumptionData;
}

export function quoteRequestStatusSelector(state: AppState): string {
  return state.quotingModuleState?.quote?.quoteRequestState?.status;
}

export function utilityUOMSelector(state: AppState): string {
  return state.utilityInformationState?.utilityUOM;
}

export function billingTypeSelector(state: AppState): string {
  return state?.utilityInformationState?.selectedBillingType
}

export function quoteRequestTypeSelector(state: AppState): string {
  return state?.utilityInformationState?.quoteRequestType;
}

export function utilityUomByUtilityShortNameSelector(state: AppState): string {
  const x = utilityInformationSelector(state);
  return x.find((y) => y.utilityShort === utilitySelector(state))?.uom ?? '';
}

export function utilityConsolidatedBillingSelector(state: AppState): boolean {
  const x = utilityInformationSelector(state);
  return x.find((y) => y.utilityShort === utilitySelector(state))?.supportsConsolidatedBilling;
}

export function utilityDualBillingSelector(state: AppState): boolean {
  const x = utilityInformationSelector(state);
  return x.find((y) => y.utilityShort === utilitySelector(state))?.supportsDualBilling;
}
