import { SupplierPricingAdderModel } from './../models/utility-information';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { UserInfo } from '../models/utility-information';
import { UtilityHubConfiguration } from "@configuration/models/utilityhub-configuration";
import { SupplierUtilityModel } from '../models/supplier-utility-model';
import { UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { SupplierModel, } from '../models/supplierModel';

@Injectable({
  providedIn: 'root',
})
export class UtilityHubService {
    constructor(private configService: ConfigService, private httpClient: HttpClient) {}

  // List of all users for a user's organisation
  public getSupplierCreatedByUsers(organisationId: string): Observable<any[]> {
    const path = this.configService.getApiPath(`utilityhub/get-supplier-users/${organisationId}`);
    return this.httpClient.get<any[]>(path);
  }

  // List of all users for a user's organisation
  public getBrokerageCreatedByUsers(organisationId: string): Observable<any[]> {
    const path = this.configService.getApiPath(`utilityhub/get-brokerage-users/${organisationId}`);
    return this.httpClient.get<any[]>(path);
  }

  public getBrokerageConfiguration(brokerageId: string): Observable<UtilityHubConfiguration> {
    const path = this.configService.getApiPath(`utilityhub/brokerage-configuration/${brokerageId}`);
    return this.httpClient.get<UtilityHubConfiguration>(path);
  }
  public getSupplierUtilityInformation(powwrId: string): Observable<SupplierUtilityModel[]> {
    const path = this.configService.getApiPath(`utilityhub/get-supplier-utility-information/${powwrId}`);
    return this.httpClient.get<SupplierUtilityModel[]>(path);
  }

  // Save User
  public saveUser(user: UserInfo): Observable<any[]> {
    console.log(user);
    const path = this.configService.getApiPath(`utilityhub/save-user`);
    return this.httpClient.post<any[]>(path,user);
  }

  public saveSupplierPricingAdder(supplierId: number,supplierPricingAdder: SupplierPricingAdderModel[]): Observable<any> {
    const path = this.configService.getApiPath(`utilityhub/${supplierId}/pricingAdder`);
    return this.httpClient.post(path,supplierPricingAdder);
  }

  public updateSupplierPricingAdder(supplierId: number,supplierPricingAdder: SupplierPricingAdderModel[]): Observable<any> {
    const path = this.configService.getApiPath(`utilityhub/${supplierId}/pricingAdder`);
    return this.httpClient.put(path,supplierPricingAdder);
  }

  public getsupplierPricingAdder(supplierId: number): Observable<SupplierPricingAdderModel[]> {
    const path = this.configService.getApiPath(`utilityhub/${supplierId}/pricingAdder`);
    return this.httpClient.get<SupplierPricingAdderModel[]>(path);
  }

  public getCreatedByUsers(organisationId: string, isSupplier: boolean) {
    if(isSupplier) {
      return this.getSupplierCreatedByUsers(organisationId);
    } else {
      return this.getBrokerageCreatedByUsers(organisationId);
    }
  }

  public getExternalId(userIdentity: UtilityHubIdentity): string {
    if (userIdentity.isBroker) {
      for (let i = 0; i < userIdentity.assignedBrokerages.length; i++) {
        if (userIdentity.currentOrganisation.externalId === userIdentity.assignedBrokerages[i].externalId) {
          return userIdentity.assignedBrokerages[i].assignedSuppliers[0].externalId;
        };
      }
    } else if (userIdentity.isSupplier || userIdentity.isSalesManager) {
          return userIdentity.currentOrganisation.externalId;
        }
  }

  public getSupplierByExternalId(externalId: string): Observable<SupplierModel> {
    const path = this.configService.getApiPath(`utilityhub/${externalId}/get-supplier`);
    return this.httpClient.get<SupplierModel>(path);
  }
}
