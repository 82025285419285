import { ContractLength } from '@app/shared/models/contract-length';
import { MarginTerm } from './../../quoting/models/quote-request-summary';
import { Offer } from '@app/quoting/models/quote';

export function removeDuplicateObjects(array, key) {
  return array.reduce((arr, item) => {
    const removed = arr.filter((i) => i[key] !== item[key]);
    return [...removed, item];
  }, []);
}

export function removeDuplicatePrimitives(values) {
  return values.filter((elem, index, self) => {
    return index === self.indexOf(elem);
  });
}

export function hasDuplicateElements(elements: any[]): boolean {
  // check the length of array with size of the Set created with array
  return elements.length != new Set(elements).size;
}

export function setCustomMarginDollarInPriceObject(prices: Offer[], offer: Offer, marginCustom: number): Offer[] {
  let currentPrices = [...prices];
  let index = currentPrices.findIndex((x) => x.term === offer.term);

  let newOffer = { ...currentPrices[index], margin: marginCustom };

  let priceOffer = prices.filter((x) => x.term !== offer.term);

  let pricesOfferResult = currentPrices.splice(index, 1, newOffer);

  pricesOfferResult.push.apply(pricesOfferResult, priceOffer);

  return pricesOfferResult.sort((a, b) => a.sellingPrice - b.sellingPrice);
}

export function convertPriceToMarginTerm(prices: Offer[]): MarginTerm[] {
  let marginTerms: Array<MarginTerm> = [];
  let marginTerm: MarginTerm;
  prices.forEach((element) => {
    marginTerm = {
      term: element.term,
      margin: element.margin,
      isSweetSpot: element.isSweetSpot
    };
    marginTerms.push(marginTerm);
  });
  return marginTerms;
}

export function convertContractToMarginTerm(contract: ContractLength[], margin: 0): MarginTerm[] {
  let marginTerms: Array<MarginTerm> = [];
  let marginTerm: MarginTerm;
  contract.forEach((element) => {
    marginTerm = {
      term: element.months,
      margin: margin,
      isSweetSpot: false
    };
    marginTerms.push(marginTerm);
  });
  return marginTerms;
}

export function sortAscendingByProperty<T>(array: T[], propertyName: string): T[] {
  if (array){
    return array.sort((a, b) => {
      if (!a[propertyName]) {
        return 1;
      }
      if (!b[propertyName]) {
        return -1;
      }
      if (a[propertyName] === b[propertyName]) {
        return 0;
      }
      return a[propertyName] > b[propertyName] ? 1 : -1;
    });
  }
}