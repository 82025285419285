import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UtilityHubIdentity, UtilityHubOrganisation } from '../../../../../auth/models/utility-hub-identity';

@Component({
  selector: 'app-user-organisation-list-dialog',
  templateUrl: './user-organisation-list-dialog.component.html',
  styleUrls: ['./user-organisation-list-dialog.component.scss'],
})
export class UserOrganisationListDialogComponent implements OnInit {
  public userIdentity: UtilityHubIdentity;
  public organisationList: UtilityHubOrganisation[];

  constructor(public dialogRef: MatDialogRef<UserOrganisationListDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.userIdentity = this.data.userIdentity;
    this.organisationList = this.userIdentity.getAllAssignedOrganisations();
  }

  ngOnInit(): void {}

  search(event): UtilityHubOrganisation[] {
    if (event.target.value.trim() === '' || !event.target.value) {
      this.organisationList = this.userIdentity.getAllAssignedOrganisations();
      return;
    }
    return (this.organisationList = this.userIdentity
      .getAllAssignedOrganisations()
      .filter((x) => x.name.toLowerCase().trim().includes(event.target.value.toLowerCase().trim())));
  }

  public roleAsNiceString(role: string): string {
    if (role === 'BrokerManager') {
      return 'Broker Manager';
    }

    return role;
  }
}
