import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { OidcCallbackComponent } from './components/oidc-callback/oidc-callback.component';
import { OidcCallbackSignoutComponent } from './components/oidc-callback-signout/oidc-callback-signout.component';
import { OidcSilentRenewComponent } from './components/oidc-silent-renew/oidc-silent-renew.component';
import { AuthEffects } from './state/effects/auth.effects';
import { AuthRoutingModule } from './auth-routing.module';
import { AuthService } from './auth.service';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [OidcCallbackComponent, OidcCallbackSignoutComponent, OidcSilentRenewComponent],
  imports: [
    CommonModule,
    AuthRoutingModule,
    SharedModule,
    EffectsModule.forFeature([AuthEffects])
  ],
  providers: [AuthService],
})
export class AuthModule { }
