<div class="container main-content">
  <h3>Notifications</h3>
  <div class="subtitle"> Let me know when...</div>
  <div class="flex">
    <span class="flex-fill">
      <jsonforms *ngIf="!loading"
                 [data]="notificationData"
                 [schema]="NotificationDataSchema"
                 [uischema]="NotificationDataSchemaUI"
                 [renderers]="renderers"
                 (dataChange)="setData($event)">
      </jsonforms>
      <div *ngIf="loading">Loading...</div>
    </span>
  </div>
  <div class="action-buttons">
    <button mat-flat-button color="accent" (click)="saveNotification()" cdkFocusInitial>Save Changes</button>
    <button mat-stroked-button color="accent" (click)="resetNotification()">Cancel</button>
  </div>
</div>
