import { CreditCheckStatus } from '@app/shared/enums/credit-check-status';
import { Offer, Quote } from '../models/quote';
import { UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { CustomerDetails } from '@app/shared/models/customer-details';
import { QuoteRequestState } from '@app/shared';
import { UtilityAccount } from '@app/shared/models/UtilityAccount';

export interface QuotingModuleState {
  userViewingQuote: UtilityHubIdentity;
  quote: Quote;
  acceptedOffer: Offer;
  approvedOffers: Offer[];
  fetching: boolean;
  hasIDontKnows: boolean;
  quoteDueDate: Date;
  creditCheckStatus: CreditCheckStatus;
  isCreditCheckApproved: boolean;
  customerDetails: CustomerDetails;
  quoteRequestState: QuoteRequestState;
  utilityAccounts: UtilityAccount[];
  quoteConfiguration: any;
}

export const quotingModuleInitialState: QuotingModuleState = {
  userViewingQuote: null,
  quote: null,
  approvedOffers: [],
  acceptedOffer: null,
  fetching: false,
  hasIDontKnows: false,  
  quoteDueDate: new Date(Date.UTC(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 23, 59, 59)),
  creditCheckStatus: null,
  isCreditCheckApproved: null,
  customerDetails: null,
  quoteRequestState: null,
  utilityAccounts: [],
  quoteConfiguration: null
};
