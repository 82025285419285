import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InlineSVGModule } from 'ng-inline-svg';
import { SettingsMyaccountLayoutComponent } from './pages/myaccount/settings-myaccount.component';
import { SettingsNotificationsComponent } from './pages/notifications/settings-notifications.component';
import { SettingsLayoutComponent } from './components/layout/settings-layout.component';
import { SettingQuoteComponent } from './pages/quote-setting/quote-settings.component';

const routes: Routes = [
  { path: ':userId', component: SettingsLayoutComponent ,
    children: [
      { path: '', component: SettingsMyaccountLayoutComponent },
      { path: 'notifications', component: SettingsNotificationsComponent },
      { path: 'setting', component: SettingQuoteComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes), InlineSVGModule],
  exports: [RouterModule]
})
export class SettingsRoutingModule { }
