import { Injectable } from "@angular/core";
import { QuoteRequestState } from "../models/quote-request-state";
import { QuoteRequestType } from "../models/QuoteRequestType";

@Injectable({
    providedIn: 'root',
  })
  export class QuoteStateHelperService {
    public isQuickQuote(quoteRequestState: QuoteRequestState) : boolean {
        return quoteRequestState?.quoteRequestType?.replace(' ', '') === QuoteRequestType.QuickQuote;
    }
  }