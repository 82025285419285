import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Logout, Login } from '@auth/state/actions/auth.actions';
import { AuthService } from '@auth/auth.service';
import { AppState } from '@app/state/app.state';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss'],
})
export class ForbiddenComponent implements OnInit {
  public loggedIn: boolean;

  constructor(private store: Store<AppState>, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getUser().subscribe((user) => {
      if (user) {
        this.loggedIn = true;
        this.authService.hydrateUtilityHubIdentity();
      }
    });
  }

  public logout() {
    this.store.dispatch(new Logout());
  }

  public login() {
    this.store.dispatch(new Login());
  }
}
