import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { DndDirective } from './directives/dnd.directive';
import { MatCardModule } from '@angular/material/card';
import { DeleteFileComponent } from './components/delete-file/delete-file.component';
import { UploadComponent } from './components/upload/upload.component';
import { UploadProgressDialogComponent } from './components/upload-progress-dialog/upload-progress-dialog.component';
import { UploadPopupComponent } from './components/upload-popup/upload-popup.component';
import { DownloadLinkDirective } from './directives/download-link';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { UploadDialogComponent } from './upload-dialog/upload-dialog.component'
import { EditableUploadDialogComponent } from './editable-upload-dialog/editable-upload-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    MatCardModule,
    MatIconModule,
    MatRadioModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
  ],
  declarations: [
    UploadComponent,
    UploadProgressDialogComponent,
    DndDirective,
    DeleteFileComponent,
    UploadPopupComponent,
    DownloadLinkDirective,
    UploadDialogComponent,
    EditableUploadDialogComponent
  ],
  exports: [
    UploadComponent,
    DeleteFileComponent,
    UploadPopupComponent,
    DownloadLinkDirective,
  ],
  entryComponents: [UploadProgressDialogComponent, UploadPopupComponent]
})
export class FileManagementModule {
}
