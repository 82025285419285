<div class="flex-container">
  <div class="flex-item" style="padding-right: 5em">
    <img style="display: inline" src="../../../../assets/images/broken-link.png" />
  </div>
  <div class="flex-item">
    <h2 style="padding-bottom: 0.1em">
      <p>OOPS...</p>
      <p>THIS IS NOT THE PAGE YOU'RE LOOKING FOR</p>
    </h2>
    <p style="padding-bottom: 0.1em">Here are some links to get back on track:</p>
    <a class="nav-link text-dark" style="display: block" [routerLink]="['/']">Dashboard</a>
    <a class="nav-link text-dark" style="display: block" [routerLink]="['/', 'quotes']">Quotes</a>
  </div>
</div>
