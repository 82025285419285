import { UtilityHubConfiguration } from "../../models/utilityhub-configuration";
import { ConfigurationActions, ConfigurationTypes } from '../actions/configuration.actions';
import { ConfigurationModuleState, initialConfigurationState } from '../configuration.state';

export function configurationReducer(state = initialConfigurationState, action: ConfigurationActions): ConfigurationModuleState {
  switch (action.type) {
    case ConfigurationTypes.ApplyConfig:
      return { ...state, config: new UtilityHubConfiguration(action.config) };
    case ConfigurationTypes.FetchTableauWorkbooksSuccess:
      return { ...state, tableauWorkbooks: action.organisationWorkbooks.workbooks };
    default:
      return state;
  }
}