export class UtilityInformation {
  public utilityShort: string;
  public dgsShortName: string;
  public powwrPricingName: string;
  public supplierPricingName: string;
  public risk360Name: string;
  public state: string;
  public iso: string;
  public accountLength: number;
  public accountLengthMax: number;
  public accountNumberStartWith: string;
  public utilityZoneInformation: UtilityZoneInformation[];
  public uom: string;
  public useServiceNumber: boolean;
  public useNameKey: boolean;
  public keyLength: number;
  public meterNumberIsMandatory: boolean;
  public useAccountNumber: boolean;
  public usePod: boolean;
  public useEsiId: boolean;
  public supportsDualBilling: boolean;
  public supportsConsolidatedBilling: boolean;
  public porUtility: boolean;
}

export class UtilityZoneInformation {
  public utilityShort: string;
  public loadZone: string;
  public weatherZone: string;
  public capacityZone: string;
}

export interface UtilityZones {
  utilityName: string;
  zones: string[];
  weatherZones: string[];
}

export class UserInfo {
  public Id: string;
  public FullName: string;
  public Email: string;
  public PhoneNumber: string;
  public MobileNumber: string;
}

export class SupplierPricingAdderModel {
  id: number;
  quoteType: string;
  commodityType: string;
  adderType: string;
  adderMillRate: number;
  supplierId: number;
  userId: string;
}

export const UtilityAccountNaming = { 
  UTILITY_ACCOUNT_NUMBER: "Utility Account Number", 
  POD_ID: "POD ID", 
  ESI_ID: "ESI ID"
} as const;

export function getAccountName(utilInfo: UtilityInformation): string{
  if(utilInfo.useEsiId === true){
    return UtilityAccountNaming.ESI_ID;
  }else if(utilInfo.usePod === true){
    return UtilityAccountNaming.POD_ID;
  }else{
    return UtilityAccountNaming.UTILITY_ACCOUNT_NUMBER;
  }
}


export class UtilityHubUtilityInformation {
  public name: string;
  public shortName: string;
  public state: string;
  public iso: string;
  public accountLength: number | null;
  public accountLengthMax: number | null;
  public accountNumberStartWith: string | null;
  public useAccountNumber: boolean;
  public useServiceNumber: boolean;
  public usePod: boolean = false;
  public useNameKey: boolean;
  public useEsiId: boolean;
  public porUtility: boolean;
  public keyLength: number;
  public meterNumberIsMandatory: boolean | null;
  public commodity: string;
  public zipCodes: UtilityHubZoneInformation[];  
  public uom: string;
  public dgsShortName: string;
  public powwrPricingName: string;
  public supplierPricingName: string;
  public risk360Name: string;
  public supportsConsolidatedBilling: boolean;
  public supportsDualBilling: boolean;
}

export class UtilityHubZoneInformation {
  public zipCode: string;
  public zone: string;
  public utilityId: string;
  public weatherZone: string;
}

