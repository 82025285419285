import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ConfigService } from './services/config.service';
import { HttpClient } from '@angular/common/http';
import { AlertComponent } from './components/alert/alert.component';
import { MaterialModule } from '../app.material.module';
import { FireLightningBackgroundComponent } from './components/fire-lightning-background/fire-lightning-background.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { ContractLengthsComponent } from './components/contract-lengths/contract-lengths.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { CamelToSentenceCasePipe } from './pipes/camel-to-sentence-case.pipe';
import { ApplyToAllComponent } from './components/apply-to-all/apply-to-all.component';
import { UserAccountActionsComponent } from './components/user-account-actions/user-account-actions.component';
import { UserOrganisationListDialogComponent } from './components/user-account-actions/components/user-organisation-list-dialog/user-organisation-list-dialog.component';
import { CompanyQuoteidHeaderComponent } from './components/company-quoteid-header/company-quoteid-header.component';
import { ZipLookupComponent } from './components/zip-lookup/zip-lookup.component';
import { UtilityAccountsGridComponent } from './components/utility-accounts-grid/utility-accounts-grid.component';
import { RatePerYearPipe } from './pipes/rate-per-year.pipe';
import { UtilityDetailsComponent } from './components/utility-details/utility-details.component';
import { ShowErrorDirective } from './directives/show-error.directive';
import { HeadingInfoDirective } from './directives/heading-info.directive';
import { YoureDoneContentComponent } from './components/youre-done-content/youre-done-content.component';
import { RouterModule } from '@angular/router';
import { FileManagementModule } from 'src/app/filemanagement/file-management.module';
import { MoreDetailsComponent } from './components/more-details/more-details.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { PluraliseUnitPipe } from './pipes/pluralise-unit.pipe';
import { DashIfNotDefinedPipe } from './pipes/dash-if-not-defined.pipe';
import { WizardStepNavButtonsComponent } from './components/wizard-step-nav-buttons/wizard-step-nav-buttons.component';
import { ResolveOffersDialogComponent } from './components/resolve-offers-dialog/resolve-offers-dialog.component';
import { YoureDoneCardComponent } from './components/youre-done-card/youre-done-card.component';
import { LoadingModalComponent } from './components/loading-modal/loading-modal.component';
import { TextAreaDialogComponent } from './components/text-area-dialog/text-area-dialog.component';
import { ConfirmActionComponent } from './components/confirm-action/confirm-action.component';
import { TextFieldDialogComponent } from './components/text-field-dialog/text-field-dialog.component';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { CompanySearchResultsDialogComponent } from './components/company-search-results-dialog/company-search-results-dialog.component';
import { LoqateAddressDirective } from './directives/loqate-address.directive';
import { ContractDetailsComponent } from './components/contract-details/contract-details.component';
import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';
import { RadioControlRenderer } from './components/contract-details/CustomControls/radio-button-controls';
import { DatePickerRenderer } from './components/contract-details/CustomControls/date-picker-control';
import { AddressLookupControl } from './components/contract-details/CustomControls/smartystreet-address-lookup-control';
import { ContractGenerationComponent } from './components/contract-details/contract-generation/contract-generation.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { AddressboxComponent } from './components/address/addressbox.component';
import { MatMenuModule } from '@angular/material/menu';
import { NextButtonDirective } from './directives/next-button-directive';
import { SideBarLayoutComponent } from './components/side-bar-layout/side-bar-layout.component';
import { ContractTypeControlRenderer } from './components/contract-details/CustomControls/contract-type-control';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { RadioButtonDialogComponent } from './components/radio-button-dialog/radio-button-dialog.component';
import { UnitForCommodityFieldsPipe } from './pipes/unit-for-commodity-fields.pipe';
import { CustomerTypeRadioButtonsComponent } from './components/customer-type-radio-buttons/customer-type-radio-buttons.component';
import { CommodityTypeRadioButtonsComponent } from './components/commodity-type-radio-buttons/commodity-type-radio-buttons.component';
import { AnnualUsageInputComponent } from './components/annual-usage-input/annual-usage-input.component';
import { LoadProfileInputComponent } from './components/load-profile-input/load-profile-input.component';
import { NewContractStartMonthInputComponent } from './components/new-contract-start-month-input/new-contract-start-month-input.component';
import { UtilityDetailsPresentationComponent } from './components/utility-details-presentation/utility-details-presentation.component';
import { ContractLengthsPresentationComponent } from './components/contract-lengths-presentation/contract-lengths-presentation.component';
import { BillingComponent } from './components/billing/billing.component';
import { ConfirmDueDateComponent } from './components/confirm-due-date/confirm-due-date.component';
import { CreditCheckComponent } from './components/credit-check/credit-check.component';

import { QuoteExpirationDisclaimerDialogComponent } from './components/quote-expiration-disclaimer-dialog/quote-expiration-disclaimer-dialog.component';
import { QuoteHistoryDialogComponent } from './components/quote-history-dialog/quote-history-dialog/quote-history-dialog.component';
import { QuoteHistoryButtonComponent } from './components/quote-history-button/quote-history-button.component';
import { MultipleAddressDialogComponent } from './components/address/multiple-address-dialog/multiple-address-dialog/multiple-address-dialog.component';
import { StateLookupComponent } from './components/state-lookup/state-lookup/state-lookup.component';

@NgModule({
  declarations: [
    AlertComponent,
    FireLightningBackgroundComponent,
    ContractLengthsComponent,
    ConfirmComponent,
    CamelToSentenceCasePipe,
    ApplyToAllComponent,
    UserAccountActionsComponent,
    UserOrganisationListDialogComponent,
    CompanyQuoteidHeaderComponent,
    ZipLookupComponent,
    UtilityAccountsGridComponent,
    RatePerYearPipe,
    UtilityDetailsComponent,
    ShowErrorDirective,
    HeadingInfoDirective,
    YoureDoneContentComponent,
    MoreDetailsComponent,
    PluraliseUnitPipe,
    DashIfNotDefinedPipe,
    WizardStepNavButtonsComponent,
    ResolveOffersDialogComponent,
    YoureDoneCardComponent,
    LoadingModalComponent,
    TextAreaDialogComponent,
    ConfirmActionComponent,
    TextFieldDialogComponent,
    CustomerDetailsComponent,
    CompanySearchResultsDialogComponent,
    LoqateAddressDirective,
    ContractDetailsComponent,
    RadioControlRenderer,
    DatePickerRenderer,
    AddressLookupControl,
    ContractGenerationComponent,
    AddressboxComponent,
    NextButtonDirective,
    SideBarLayoutComponent,
    ContractTypeControlRenderer,
    SearchBarComponent,
    RadioButtonDialogComponent,
    UnitForCommodityFieldsPipe,
    CustomerTypeRadioButtonsComponent,
    CommodityTypeRadioButtonsComponent,
    AnnualUsageInputComponent,
    LoadProfileInputComponent,
    NewContractStartMonthInputComponent,
    UtilityDetailsPresentationComponent,
    ContractLengthsPresentationComponent,
    BillingComponent,
    ConfirmDueDateComponent,
    CreditCheckComponent,
    QuoteExpirationDisclaimerDialogComponent,
    QuoteHistoryDialogComponent,
    QuoteHistoryButtonComponent,
    MultipleAddressDialogComponent,
    StateLookupComponent,  
  ],
  imports: [
    CommonModule,
    MaterialModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FileManagementModule,
    MatSlideToggleModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    MatAutocompleteModule,
    MatMenuModule,
  ],
  exports: [
    AlertComponent,
    FireLightningBackgroundComponent,
    ContractLengthsComponent,
    CamelToSentenceCasePipe,
    ApplyToAllComponent,
    UserAccountActionsComponent,
    UserOrganisationListDialogComponent,
    CompanyQuoteidHeaderComponent,
    ZipLookupComponent,
    UtilityAccountsGridComponent,
    RatePerYearPipe,
    UtilityDetailsComponent,
    ShowErrorDirective,
    HeadingInfoDirective,
    YoureDoneContentComponent,
    ContractDetailsComponent,
    WizardStepNavButtonsComponent,
    CustomerDetailsComponent,
    CompanySearchResultsDialogComponent,
    LoqateAddressDirective,
    AddressboxComponent,
    NextButtonDirective,
    SideBarLayoutComponent,
    SearchBarComponent,
    UnitForCommodityFieldsPipe,
    CustomerTypeRadioButtonsComponent,
    CommodityTypeRadioButtonsComponent,
    AnnualUsageInputComponent,
    LoadProfileInputComponent,
    NewContractStartMonthInputComponent,
    UtilityDetailsPresentationComponent,
    ContractLengthsPresentationComponent,
    BillingComponent,
    QuoteHistoryButtonComponent,
    StateLookupComponent, 
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: ConfigService.loadConfiguration,
      deps: [HttpClient, ConfigService],
      multi: true,
    },
    DatePipe
  ]
})
export class SharedModule { }
