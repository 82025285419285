import { MarginTerm } from "@app/quoting/models/quote-request-summary";
import { MonthlyConsumptionData } from "@app/shared/components/more-details/more-details.component";
import { CommodityType } from "@app/shared/enums/commodity-type";
import { ContractLength } from "@app/shared/models/contract-length";
import { CustomerType } from "@app/shared/models/customer-type";
import { CustomerTypeSize } from "@app/shared/models/customer-type-size";
import { SupplierUtilityModel } from "@app/shared/models/supplier-utility-model";
import { UtilityInformation } from "@app/shared/models/utility-information";
import { UsageSourceMap } from "../models/usage-source-map";

export interface UtilityInformationState {
  quoteRequestId: string;
  brokerageName: string;
  contractLengths: number[];
  contractLengthsFilterValues: number[];
  customerType: CustomerType;
  customerTypeSize: CustomerTypeSize;
  state: string;
  zipCode: string;
  utility: string;
  commodityType: CommodityType;
  utilityInformation: UtilityInformation[];
  supplierUtilityInformation: SupplierUtilityModel[];
  productType: string;
  productTerms: string[];
  productTypeFilters: string[];
  contractLengthsFilters: string[];
  showSweetSpotFilter: boolean;
  sweetSpotCheckBoxState: boolean;
  sweetSpotWasRequested: boolean;
  quoteRequestType: string;
  contractLengths_Selected: ContractLength[];
  pageName: string;
  salesType: string;
  usageSources: UsageSourceMap[];
  getQuoteContractLengthStartingButtons: ContractLength[];
  consumptionData: MonthlyConsumptionData[];
  utilityUOM: string;
  marginDollar: number;
  marginTerm: MarginTerm[];
  selectedBillingType: string;
  isDisplayReportsEnabled: boolean;
  showShareQuoteButton: boolean;
  canNavigate: boolean;
  maximumLoadFactorPercentageIgnored: boolean;
  agentFee: number;
}

export const initialUtilityInformationState: UtilityInformationState = {
  quoteRequestId: undefined,
  brokerageName: undefined,
  contractLengths: undefined,
  contractLengthsFilterValues: undefined,
  customerType: undefined,
  customerTypeSize: undefined,
  state: undefined,
  zipCode: undefined,
  utility: undefined,
  commodityType: undefined,
  utilityInformation: undefined,
  supplierUtilityInformation: undefined,
  productType: undefined,
  productTerms: undefined,
  productTypeFilters: undefined,
  contractLengthsFilters: undefined,
  showSweetSpotFilter: undefined,
  sweetSpotCheckBoxState: undefined,
  sweetSpotWasRequested: undefined,
  quoteRequestType: undefined,
  contractLengths_Selected: undefined,
  pageName: undefined,
  salesType: undefined,
  usageSources: undefined,
  getQuoteContractLengthStartingButtons: undefined,
  consumptionData: undefined,
  utilityUOM: undefined,
  marginDollar: 0,
  marginTerm: undefined,
  selectedBillingType: undefined,
  isDisplayReportsEnabled: undefined,
  showShareQuoteButton: undefined,
  canNavigate: undefined,
  maximumLoadFactorPercentageIgnored: undefined,
  agentFee: undefined,
};