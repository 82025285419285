import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ErrorPagesRoutingModule } from './error-pages-routing.module';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MaterialModule } from '@app/app.material.module';
import { SharedModule } from '@progress/kendo-angular-grid';


@NgModule({
  declarations: [
    ForbiddenComponent,
    NotFoundComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    ErrorPagesRoutingModule
  ]
})
export class ErrorPagesModule { }
