import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { QuoteConfiguration } from '@app/shared/models/customer-details';
import { ActivatedRoute } from '@angular/router';
import { WizardStepProviderService } from '@app/wizard-bar/services/wizard-step-provider.service';

@Injectable({
  providedIn: 'root',
})
export class CustomService {
  
  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private wizardStepProviderService: WizardStepProviderService,
  ) {}

  public getServerStepsValidation(quoteRequestId: number, activatedRoute?: ActivatedRoute, journeyType?: string): Observable<any[]> {
    const slugs = this.wizardStepProviderService.getSteps(activatedRoute, journeyType).steps.map((x) => x.slug);
    const path = this.configService.getApiPath(`quoterequest/validate`);
    const payload = {
      quoteRequestId,
      slugs: slugs
    };
    return this.httpClient.post<any[]>(path, payload);
  }

  public getLatestValidSteps(customQuoteRequestIds: number[]) {
    const path = this.configService.getApiPath(`custom-quote-request/steps-validation-list`);
    return this.httpClient.post<any[]>(path, customQuoteRequestIds);
  }

  // Company Details
  public getCompanyDetails(quoteRequestId: number): Observable<any> {
    console.log("getCompanyDetails");
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/company-details`);
    return this.httpClient.get<any>(path).pipe(
      tap((x) => {
        return (x.location = { zipCode: x.zipCode, city: x.city, state: x.state });
      })
    );
  }

  public saveCompanyDetails(quoteRequestId: number, quoteRequest: any): Observable<any> {
    console.log("saveCompanyDetails");
    const responseData = { ...quoteRequest };
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/company-details`);
    return this.httpClient.put<number>(path, responseData);
  }

  // Quote Configuration
  public getQuoteConfiguration(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/quote-configuration`);
    return this.httpClient.get<any>(path).pipe(
      map((x: any) => {
        const result = x as QuoteConfiguration;
        return result;
      })
    );
  }

  public saveQuoteConfiguration(quoteRequestId: any, quoteConfiguration: any) {
    const responseData = { ...quoteConfiguration };
    delete responseData.supplyDetails;
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/quote-configuration`);
    return this.httpClient.post<number>(path, responseData);
  }

  // Utility Accounts
  public deleteUtilityAccountNumber(quoteRequestId: any, utilityAccountNumber: string): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/utility-accounts/${utilityAccountNumber}`);
    return this.httpClient.delete(path);
  }

  // File Validation
  public getFileValidation(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/file-validation`);
    return this.httpClient.get<any>(path);
  }

  public setForecasted(quoteRequestId: number, utilityAccountNumber: string, forecasted: boolean): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/utility-accounts/${utilityAccountNumber}`);
    return this.httpClient.patch<boolean>(path, { forecast: forecasted });
  }

  // Supply Details
  public getSupplyDetails(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/supply-details`);
    return this.httpClient.get<any>(path);
  }

  public saveSupplyDetails(quoteRequestId: number, UtilityDetailsModel: any[]): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/supply-details`);
    return this.httpClient.put<any>(path, UtilityDetailsModel);
  }

  // You're done
  public getYoureDone(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/youre-done`);
    return this.httpClient.get<any>(path);
  }

  public attachLoaDocuments(quoteRequestId: number, documentIds: string[]): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/attach-loa`);
    return this.httpClient.patch(path, documentIds);
  }

  public getNonDynamicResourceUri(quoteIntegratorReference: string): Observable<string> {
    const path = this.configService.getApiPath(`quoteRequest/${quoteIntegratorReference}/form-integrator-uri`);
    return this.httpClient.get(path, {
      responseType: 'text',
    });
  }

  public downloadUsageData(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/usage`);
    return this.httpClient.get(path, { responseType: 'blob' });
  }
}
