import { WizardStep } from '@app/wizard-bar/services/wizard.service';

export class MatrixConstants {
  public static wizardSteps: WizardStep[] = [
    { name: 'Customer Details', svg: 'company-details', slug: 'customer-details' },
    { name: 'Utility Accounts', svg: 'supply-points', slug: 'utility-accounts' },
    { name: 'Get Quote', svg: 'icon-quote', slug: 'get-quote' },
    { name: 'Contract Details', svg: 'icon-contract', slug: 'contract-details' },
    { name: "You're Done", svg: 'youre-done', slug: 'youre-done' },
  ];
}
