import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { UdFile, FileBlob } from './UdFile';
import { ConfigService } from '../shared/services/config.service';

@Injectable({
  providedIn: 'root',
})
export class UploadService {
  private url: string;

  constructor(private http: HttpClient, private appConfig: ConfigService) {}

  public upload(files: Array<UdFile>, url?: string, additionalData?: any): { [key: string]: { progress: Observable<UdFile> } } {
    this.url = this.appConfig.getApiPath(url ?? 'file/upload');

    // this will be the our resulting map
    const status: { [key: string]: { progress: Observable<UdFile> } } = {};

    files.forEach((file) => {
      // create a new multipart-form for every file
      const formData: FormData = new FormData();
      formData.append('file', file, file.name);
      formData.append('documentType', file.documentType);

      if (additionalData) {
        Object.entries(additionalData).forEach((e) => formData.append(e[0], e[1] as string));
      }

      // create a http-post request and pass the form
      // tell it to report the upload progress
      const req = new HttpRequest('POST', this.url, formData, {
        reportProgress: true,
      });

      // create a new progress-subject for every file
      const progress = new Subject<UdFile>();

      // send the http-request and subscribe for progress-updates
      this.http.request(req).subscribe(
        (event) => {
          if (event.type === HttpEventType.UploadProgress) {
            if (file.size > 20971520) {
              file.success = false;
              file.error = 'File exceeds maximum file size limit of 20mb.';
              progress.error(file);
            }

            // calculate the progress percentage
            file.percentDone = Math.round((100 * event.loaded) / event.total - Math.abs(Math.floor(Math.random() * 10) + 10));

            // pass the percentage into the progress-stream
            progress.next(file);
          } else if (event instanceof HttpResponse) {
            // Close the progress-stream if we get an answer from the API
            // The upload is complete
            file.percentDone = 100;
            file.success = true;
            file.data = event.body as FileBlob;
            progress.next(file);
            progress.complete();
          }
        },
        (err) => {
          file.percentDone = 0;
          if (file.error) {
          } else if (err.length > 0) {
            file.error = err;
          } else if (err.message) {
            file.error = err.message;
          } else if (!err.error.errors) {
            file.error = err.error;
          } else {
            file.error = err.error.errors.File[0];
          }

          if (file.error.startsWith('Http failure')) {
            file.error = 'There was a problem uploading this file.';
          }

          progress.next(file);
          progress.error(file);
        }
      );

      // Save every progress-observable in a map of all observables
      status[file.name] = {
        progress: progress.asObservable(),
      };
    });

    // return the map of progress.observables
    return status;
  }

  public forceParseConsumptionData(quoteRequestId: number, utilityAccountNumbers: string[], documentId: string) {
    const path = this.appConfig.getApiPath(`file/force-parse`);
    return this.http.post(path, {quoteRequestId, utilityAccountNumbers, documentId});
  }
}
