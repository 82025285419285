import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UtilityHubIdentity, UtilityHubOrganisation } from '../../../auth/models/utility-hub-identity';
import { UserOrganisationListDialogComponent } from './components/user-organisation-list-dialog/user-organisation-list-dialog.component';
import { ConfirmComponent } from '../confirm/confirm.component';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';
import { Login, Logout } from '@auth/state/actions/auth.actions';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from '@auth/auth.service';
import { Subscription } from 'rxjs';
import { ConfigService } from '@app/shared/services/config.service';
import { loggedInUserSelector, loggedInUtilityHubIdentitySelector } from '@app/state/app.selectors';

@Component({
  selector: 'app-user-account-actions',
  templateUrl: './user-account-actions.component.html',
  styleUrls: ['./user-account-actions.component.scss'],
})
export class UserAccountActionsComponent implements OnInit, OnDestroy {
  @Input() loggedIn;
  public userFullNameWithRoleAndOrganisation: string = 'Loading..';
  public userIdentity: UtilityHubIdentity;
  public hasMultipleOrgs: boolean;
  public cookieActiveOrganisation: string;
  public isProd: boolean;
  private activeOrganisationCookieKey: string;
  selectLoggedInUser$: Subscription;
  selectLoggedInUtilityHubIdentity$: Subscription;

  constructor(
    private dialog: MatDialog,
    private store: Store<AppState>,
    private cookieService: CookieService,
    private authService: AuthService,
    private configService: ConfigService
  ) {}

  ngOnDestroy(): void {
    this.selectLoggedInUtilityHubIdentity$?.unsubscribe();
    this.selectLoggedInUser$?.unsubscribe();
  }

  ngOnInit() {
    this.isProd = this.configService.config.environment === 'Production';
    this.selectLoggedInUser$ = this.store.select(loggedInUserSelector).subscribe((user) => {
      if (user && !user.expired) {
        this.selectLoggedInUtilityHubIdentity$ = this.store.select(loggedInUtilityHubIdentitySelector).subscribe((uhUser) => {
          uhUser = new UtilityHubIdentity(uhUser); // So we have access to class methods from state instance.
          this.userIdentity = uhUser;
          this.hasMultipleOrgs = uhUser.getAllAssignedOrganisations().length > 0;
          this.userFullNameWithRoleAndOrganisation = `${uhUser.fullName} (${
            uhUser.currentOrganisation?.name ?? ''
          } - ${this.roleAsNiceString(uhUser.currentOrganisation.role)})`;

          this.activeOrganisationCookieKey = `active-organisation-${uhUser.id}`;
          this.cookieActiveOrganisation = this.cookieService.get(this.activeOrganisationCookieKey);

          if (
            this.cookieActiveOrganisation &&
            uhUser?.currentOrganisation?.vanityName !== null &&
            uhUser.currentOrganisation.vanityName !== this.cookieActiveOrganisation
          ) {
            this.cookieActiveOrganisation = null;
          }
        });
      } else {
        this.userFullNameWithRoleAndOrganisation = '';
        this.loggedIn = false;
      }
    });
  }

  public logout() {
    this.userIdentity = null;
    this.userFullNameWithRoleAndOrganisation = '';
    this.store.dispatch(new Logout());
  }

  public login() {
    this.store.dispatch(new Login());
  }

  openSwitchContextDialog() {
    this.dialog
      .open(UserOrganisationListDialogComponent, {
        width: '100%',
        maxWidth: '550px',
        height: '100%',
        maxHeight: '600px',
        data: { userIdentity: this.userIdentity },
      })
      .afterClosed()
      .subscribe((org: UtilityHubOrganisation) => {
        if (org) {
          // Show config dialog
          this.dialog
            .open(ConfirmComponent, {
              width: '25%',
              data: {
                title: `Are you sure you want to switch to a different organisation?`,
                message: 'Changes you have made in the current logged-in session may be lost.',
                primaryButtonText: 'Change Organisation',
              },
            })
            .afterClosed()
            .subscribe((confirmed) => {
              if (confirmed) {
                // Route to new context
                this.cookieService.set(this.activeOrganisationCookieKey, org.vanityName, { expires: 1000000 });
                this.cookieActiveOrganisation = org.vanityName;
                this.logout();
                this.login();
                this.reloadPage();
              }
            });
        }
      });
  }

  public roleAsNiceString(role: string): string {
    if (role === 'BrokerManager') {
      return 'Broker Manager';
    }

    return role;
  }

  private reloadPage() {
    this.authService.utilityHubIdentity().subscribe((uhUser) => {
      this.userIdentity = uhUser;
      const portStr = window.location.port.length > 0 ? `:${window.location.port}` : '';
      window.location.assign(`${window.location.protocol}//${window.location.hostname}${portStr}`);
    });
  }
}
