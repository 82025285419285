import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from '../../shared/services/config.service';
import { HttpClient } from '@angular/common/http';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';
import { QuoteConfiguration } from '@app/shared/models/customer-details';
import { MatrixConstants } from '../models/matrix-constants';

@Injectable({
  providedIn: 'root',
})
export class MatrixService {
  public quoteRequests: any[] = [];
  public utilityAccounts: any[] = [];

  constructor(private configService: ConfigService, private httpClient: HttpClient) {
    const quotesRequestsJson = localStorage.getItem('AllQuotes');
    if (quotesRequestsJson) {
      this.quoteRequests = JSON.parse(quotesRequestsJson);
    }
  }

  // Server Side Validation

  public getServerStepsValidation(quoteRequestId: number): Observable<any[]> {
    const path = this.configService.getApiPath(`quoterequest/validate`);
    const payload = {
      quoteRequestId,
      slugs: MatrixConstants.wizardSteps.map((x) => x.slug),
    };
    return this.httpClient.post<any[]>(path, payload);
  }

  public getLatestValidSteps(matrixQuoteRequestIds: number[]) {
    const path = this.configService.getApiPath(`matrix-quote-request/steps-validation-list`);
    return this.httpClient.post<any[]>(path, matrixQuoteRequestIds);
  }

  // Quote Config

  public getQuoteConfiguration(quoteRequestId: number): Observable<QuoteConfiguration> {
    const path = this.configService.getApiPath(`matrix-quote-request/${quoteRequestId}`);
    return this.httpClient.get<QuoteConfiguration>(path).pipe(
      map((x: any) => {
        const result = x as QuoteConfiguration;
        return result;
      })
    );
  }

  public saveMatrixQuoteConfiguration(quoteRequest: QuoteConfiguration): Observable<any> {
    const responseData = {
      ...quoteRequest,
    };
    const path = this.configService.getApiPath('matrix-quote-request/quote-configuration');
    return this.httpClient.put<number>(path, responseData);
  }
}
