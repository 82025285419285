import { Store } from '@ngrx/store';
import { Component, OnInit } from '@angular/core';
import { AppState } from '@app/state/app.state';
import { UserRole } from '@auth/models/utility-hub-identity';
import { loggedInUtilityHubIdentitySelector } from '@app/state/app.selectors';

@Component({
  selector: 'settings-layout',
  templateUrl: './settings-layout.component.html',
  styleUrls: ['./settings-layout.component.scss'],
})
export class SettingsLayoutComponent implements OnInit {
  isSupplierManagerOrSalesManager: boolean;

  constructor(private store: Store<AppState>) {}

  ngOnInit(): void {
    this.store.select(loggedInUtilityHubIdentitySelector).subscribe((user) => {
      this.isSupplierManagerOrSalesManager = user.isSupplierManagerOrSalesManager;
    });
  }
}
