import { Component, OnInit } from '@angular/core';
import { UtilityHubConfigService } from '@configuration/services/utilityhub-config.service';

@Component({
  selector: 'app-oidc-callback-signout',
  templateUrl: './oidc-callback-signout.component.html',
  styleUrls: ['./oidc-callback-signout.component.scss'],
})
export class OidcCallbackSignoutComponent implements OnInit {
  public homeHref: string;

  constructor() {}

  ngOnInit(): void {
    localStorage.clear();
    sessionStorage.clear();

    const portStr = window.location.port.length > 0 ? `:${window.location.port}` : '';
    this.homeHref = `${window.location.protocol}//${window.location.hostname}${portStr}`;
  }
}
