import { ProductConfiguration } from "./product-configuration";
import { SupplierConfiguration } from "./supplier-configuration";

export class UtilityHubConfiguration {
  public productId: string;
  public configurationId: string;
  public supplierExternalId: string;
  public configuration: ProductConfiguration;
  public supplierConfiguration: SupplierConfiguration;

  constructor({
    productId, configurationId, supplierExternalId, configuration, supplierConfiguration,
  }: {
    productId: string;
    configurationId: string;
    supplierExternalId: string;
    configuration: ProductConfiguration;
    supplierConfiguration: any;
  }) {
    this.configuration = new ProductConfiguration({ ...configuration });
    this.productId = productId;
    this.configurationId = configurationId;
    this.supplierConfiguration = supplierConfiguration;
    this.supplierExternalId = supplierExternalId;
  }
}
