import { AuthActions, AuthActionTypes } from '@auth/state/actions/auth.actions';
import { AuthModuleState, initialState } from '@auth/state/auth.state';

export function authModuleReducer(state = initialState, action: AuthActions): AuthModuleState {
  switch (action.type) {
    case AuthActionTypes.LoginSuccess:
    case AuthActionTypes.LoginSilentSuccess:
    case AuthActionTypes.UtilityHubIdentitySet:
      return { ...state, user: action.user, utilityHubIdentity: action.utiltyHubIdentity };
    default:
      return state;
  }
}