<div class="container main-content">
  <div class="page-header">
    <h2>Quotes</h2>
    <span class="flex-fill"></span>
    <button *ngIf="this.matrixJourneyEnabled && !isCreditAdmin" mat-flat-button color="accent" [routerLink]="['/', 'matrix', 'new', 'customer-details']">
      <img src="/assets/icons/icon-new.svg" class="prefix" /> New Matrix Quote
    </button>
    <button *ngIf="customJourneyEnabled && (!isSupplierMgr || !powwrEnabled) && !isCreditAdmin" mat-flat-button color="accent" [routerLink]="['/', 'custom', 'new', 'customer-details']">
      <img src="/assets/icons/icon-new.svg" class="prefix" /> Request a Custom Quote
    </button>
    <button *ngIf="this.customElectricJourneyEnabled && isSupplierMgr && powwrEnabled" mat-flat-button color="accent" (click)="getCustomElectricQuoteBtn_Clicked()">
      <img src="/assets/icons/icon-new.svg" class="prefix" /> New Custom Electricity Quote
    </button>
    <button *ngIf="this.customGasJourneyEnabled && isSupplierMgr && powwrEnabled" mat-flat-button color="accent" (click)="getCustomGasQuoteBtn_Clicked()">
      <img src="/assets/icons/icon-new.svg" class="prefix" /> New Custom Gas Quote
    </button>
    <span class="space-fill"></span>
    <app-search-bar (search)="search($event)"></app-search-bar>
  </div>
  <app-quote-request-grid></app-quote-request-grid>
</div>
