import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserRole } from '@auth/models/utility-hub-identity';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppState } from '@app/state/app.state';
import { CommodityType } from '../enums/commodity-type';
import { QuoteRequestType } from '../models/QuoteRequestType';
import { configurationSelector } from '@app/state/app.selectors';
import { commoditySelector } from '@utility-information/state/selectors/utility-information.selectors';

@Injectable({
  providedIn: 'root',
})
export class PricingSourceService {
  private _pricingSource: BehaviorSubject<Pricing> = new BehaviorSubject(null);

  constructor(private store: Store<AppState>, private route: ActivatedRoute) {}

  public getPricingSource(quoteRequestType: QuoteRequestType, commodity?: CommodityType): Observable<Pricing> {
    if (!this._pricingSource.getValue()) {
      this.store.select(configurationSelector).subscribe((config) => {
        this.resolvePricingSource(quoteRequestType, config.supplierConfiguration.pricing.powwrSupplier, commodity);
      });
    }
    else if (this._pricingSource.getValue().commodity != commodity || this._pricingSource.getValue().quoteRequestType != quoteRequestType)
    {
      this.store.select(configurationSelector).subscribe((config) => {
        this.resolvePricingSource(quoteRequestType, config.supplierConfiguration.pricing.powwrSupplier, commodity);
      });
    }

    return this._pricingSource.asObservable();
  }

  public fetchPricingSource(quoteRequestType: QuoteRequestType, userRole: UserRole, isViaPowwrSupplier: boolean, commodity?: CommodityType): PricingSource {
    // deliberately left in as this is a rule specified by product
    if (!commodity)
    {
      this.store.select(commoditySelector).subscribe(c => commodity = CommodityType[c]);
      if (!(commodity == CommodityType.Electricity || commodity == CommodityType.Gas))
      {
        this.route.queryParams.subscribe((x) => {
          if (x.commodityType){
            commodity = x.commodityType;
          }
        });
      }
    }

    if (quoteRequestType === QuoteRequestType.Custom) {
      if (!isViaPowwrSupplier) {
        if (userRole === UserRole.SupplierManager || userRole === UserRole.SalesManager) {
          return PricingSource.ManualEntry;
        }
        else {
          return PricingSource.SupplierMatrix;
        }
      }
      else {
        if (commodity === CommodityType.Electricity) {
          return PricingSource.Dynamic;
        }
        else {
          return PricingSource.ManualEntry;
        }
      }
    }
    else {
      if (commodity === CommodityType.Electricity && isViaPowwrSupplier)
        return PricingSource.Dynamic;
    }
    
    return PricingSource.SupplierMatrix;
  }

  ///
  // Can be used instead of calling getPricingSource as we can determine if Dynamic pricing if they're a POWWR enabled supplier.
  // This terminology may change in the future to differentiate between POWWR Suppliers (those that are clients of POWWR) vs POWWR Priced Suppliers (those that use our Dynamic pricing)
  // and Suppliers with no POWWR association.
  ///
  public isDynamicPricingSource(): Observable<boolean> {
    return new Observable<boolean>((x) => {
      this.store.select(configurationSelector).subscribe((config) => {
        x.next(config.supplierConfiguration.pricing.powwrSupplier);
      });
    });
  }

  public updateCommodity(commodity: CommodityType) {
    const ps = this._pricingSource.getValue();
    this.resolvePricingSource(ps.quoteRequestType, ps.powwrSupplier, commodity);
  }

  private resolvePricingSource(quoteRequestType: QuoteRequestType, powwrSupplier: boolean, commodity: CommodityType) {
    if (powwrSupplier) {
      switch (quoteRequestType) {
        case QuoteRequestType.QuickQuote:
          this.setPricingSourceForCommodity(quoteRequestType, commodity, powwrSupplier, PricingSource.Dynamic, PricingSource.ManualEntry);
        case QuoteRequestType.Matrix:
          this.setPricingSourceForCommodity(
            quoteRequestType,
            commodity,
            powwrSupplier,
            PricingSource.Dynamic, // Elec pricing source
            PricingSource.SupplierMatrix // Gas pricing source
          );
          break;
        case QuoteRequestType.Custom:
          this.setPricingSourceForCommodity(quoteRequestType, commodity, powwrSupplier, PricingSource.Dynamic, PricingSource.ManualEntry);
          break;
      }
    } else {
      // Non dynamic suppliers use pricbooks for matrix and manuall entry for custom for both commodities
      switch (quoteRequestType) {
        case QuoteRequestType.Matrix:
          this.setPricingSourceForCommodity(
            quoteRequestType,
            commodity,
            powwrSupplier,
            PricingSource.SupplierMatrix,
            PricingSource.SupplierMatrix
          );
          break;
        case QuoteRequestType.Custom:
          this.setPricingSourceForCommodity(
            quoteRequestType,
            commodity,
            powwrSupplier,
            PricingSource.ManualEntry,
            PricingSource.ManualEntry
          );
          break;
        case QuoteRequestType.QuickQuote:
          this.setPricingSourceForCommodity(
            QuoteRequestType.Matrix,
            commodity,
            powwrSupplier,
            PricingSource.SupplierMatrix,
            PricingSource.SupplierMatrix
          )
      }
    }
  }

  private setPricingSourceForCommodity(
    quoteRequestType: QuoteRequestType,
    commodity: CommodityType,
    powwrSupplier: boolean,
    elecPricingSource: PricingSource,
    gasPricingSource: PricingSource
  ) {
    if (!commodity) {
      this._pricingSource.next({
        source: elecPricingSource,
        quoteRequestType,
        commodity: CommodityType.Unknown,
        powwrSupplier,
      });
    } else {
      switch (commodity) {
        case CommodityType.Electricity:
          this._pricingSource.next({ source: elecPricingSource, quoteRequestType, commodity, powwrSupplier });
          break;
        case CommodityType.Gas:
          this._pricingSource.next({ source: gasPricingSource, quoteRequestType, commodity, powwrSupplier });
          break;
      }
    }
  }
}

type Pricing = {
  source: PricingSource;
  quoteRequestType: QuoteRequestType;
  commodity: CommodityType;
  powwrSupplier: boolean;
};

export enum PricingSource {
  Dynamic = 'Dynamic',
  SupplierMatrix = 'SupplierMatrix',
  ManualEntry = 'ManualEntry',
}
