import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

export class Version {
  public version: string;
  public env: string;
}

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private http: HttpClient, private configService: ConfigService) {}

  public getVersion(): Observable<Version> {
    const path = this.configService.getApiPath('status');
    return this.http.get<Version>(path);
  }
}
