<div class="heading">
  <h5>Upload Files</h5>
  <span class="flex-fill"></span>
</div>

<div>
  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content>
    <mat-list>
      <mat-list-item *ngFor="let file of files">
        <img
          *ngIf="file.success"
          [src]="'../../../../assets/icons/icon_success.svg'"
          class="icon-sm upload-icon"
          aria-hidden="false"
          aria-label="File was uploaded"
        />
        <img
          *ngIf="!file.success && file.error"
          [src]="'../../../../assets/icons/icon_error.svg'"
          class="icon-sm upload-icon"
          aria-hidden="false"
          aria-label="File did not upload"
        />
        <h4 mat-line>{{ file.name }}</h4>
        <mat-progress-bar
          *ngIf="uploadStatus"
          mode="determinate"
          [value]="(uploadStatus[file.name].progress | async)?.percentDone"
        ></mat-progress-bar>
        <p *ngIf="!file.success && file.error" mat-line style="color: red">{{ file.error }}</p>
      </mat-list-item>
    </mat-list>
  </mat-dialog-content>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <button *ngIf="showCancelButton" mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button color="accent" *ngIf="canBeClosed" [disabled]="!canBeClosed" (click)="uploadFiles()">
      {{ primaryButtonText }}
    </button>
  </mat-dialog-actions>
</div>
