<span *ngIf="!loading">
  <div class="container flex border-bottom">
      <div id="title">
          <h3 id="title">
              Quote |
              <em>{{ shareQuote.companyOrCustomerName }} (QUOTE ID {{ shareQuote.quoteRequestId }})</em>
          </h3>
      </div>

      <div style="margin-left: auto; text-align: right;">
        <p>{{ this.getQuoteExpirationDisclaimer() }}</p>
      </div>

      <button
      mat-stroked-button
      id="rejectAllButton"
      color="accent"
      type="button"
      *ngIf="quotePendingAction() && !quoteStateHelperService.isQuickQuote(this.quoteRequestState)"
      (click)="rejectAllOffers()">
          Reject All Offers
      </button>
  </div>
  
  <app-quote-note></app-quote-note>
  
  <div *ngIf="this.hasExpired() | async" style="margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: bold;">
      <p style="text-align: center">Sorry! Your quote has expired.</p>
      <p style="text-align: center">Please request a new one.</p>
  </div>
  <div *ngIf="!(this.hasExpired() | async)">
    <div class="offer-container" *ngFor="let offer of shareQuote.prices">
      <div class="offer-grid" *ngIf="!offer.noResult" [ngClass]="greyedOutClass(offer.status) | async">

          <div *ngIf="shareQuote?.quoteSettings?.contractLength">
            <mat-chip-list>
                <mat-chip [ngClass]="matChipClass(offer.status) | async"><strong>{{ offer.term }} months</strong></mat-chip>
            </mat-chip-list>
          </div>

          <div style="text-align: center" *ngIf="offer.term || offer.totalConsumption" >
            <p *ngIf="offer.planType">Contract Type: <strong>{{ offer.planType ?? "Fixed"}}</strong></p>
            <p *ngIf="shareQuote?.quoteSettings?.usage">Usage: <strong>{{ offer.totalConsumption | number: '1.0-0' }} {{ shareQuote?.annualUsageUOM | lowercase }}</strong></p>
          </div>

          <div style="text-align: center">
            <p *ngIf="shareQuote?.quoteSettings?.totalCommission"><strong>Total Commission: ${{ offer.totalCommission | number: '1.2-2' }}</strong></p>

            <p *ngIf="shareQuote?.quoteSettings?.annualCommission" class="per-year"> Annual Commission: ${{ offer.annualCommission | number: '1.2-2' }}
            </p>
          </div>

          <div *ngIf="offer.totalCost || offer.annualCost" class="cost" >
            <p *ngIf="shareQuote?.quoteSettings?.totalCost" class="total">${{ offer.totalCost | number: '1.2-2' }} Total</p>

            <p *ngIf="shareQuote?.quoteSettings?.annualCost" class="per-year">
                ${{ offer.annualCost | number: '1.2-2' }}<em>/Year</em>
            </p>

            <p class="swtspot"> <em *ngIf="offer.sweetSpot">Sweet Spot</em></p>
          </div>

          <div *ngIf="offer.sellingPrice" class="sellingPrice">
            <p>${{ offer.sellingPrice | number: '1.5-5' }}/{{ shareQuote?.annualUsageUOM | lowercase }}</p>
          </div>

          <div class="offer-actions">
            <button
            mat-flat-button color="accent"
            id="accept-button"
            type="button"
            (click)="acceptOffer(offer)"
            *ngIf="quotePendingAction() && !quoteStateHelperService.isQuickQuote(this.quoteRequestState)"
            >
                <span>Accept Offer</span>
            </button>
            <div class="action-label">
                <mat-label
                  *ngIf="!quotePendingAction() && !quoteStateHelperService.isQuickQuote(this.quoteRequestState)"
                >
                  <mat-icon class="action-icon" [ngClass]="greyedOutClass(offer.status) | async" [color]="getStatusStyle(offer)" matPrefix>{{ getStatusIcon(offer) }}</mat-icon>
                  {{ offer.status }}
                  <div class="loading-spinner" *ngIf="this.loading">
                    <mat-spinner [diameter]="15"></mat-spinner>
                  </div>
                </mat-label>
              </div></div>

      </div>
      <div *ngIf="offer.noResult">
          <div class="no-prices">-- No price available for {{ offer.term }} month contract length --</div>
      </div>
  </div>
  </div>
</span>


