import { Action } from '@ngrx/store';
import { Offer, Quote } from '../../models/quote';
import { UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { QuoteRequestState } from '@app/shared';
import { YoureDone } from '@app/shared/models/youre-done';
import { CreditCheckStatus } from '@app/shared/enums/credit-check-status';
import { CustomerDetails } from '@app/shared/models/customer-details';
import { UtilityAccount } from '@app/shared/models/UtilityAccount';

export enum QuotingModuleActionTypes {
  FetchQuote = '[Quote] Fetching',
  SetQuote = '[Quote] Set',
  ApprovalAccept = '[Quote Approval] Accepted',
  ApprovalReject = '[Quote Approval] Rejected',
  OfferAccept = '[Quote Offer Review] Accepted',
  OfferApprovalRequest = '[Quote Offer Review] Requested',
  DocuSignContractSent = '[DocuSign Contract Sent]',
  OfferReject = '[Quote Offer Review] Rejected',
  Init = "[Quote] Init",
  ClearQuote = "[Quote] ClearQuote",
  SetYoureDone = "[Quote] SetYoureDone",
  SetContractLengths = "[Quote] SetContractLengths",
  SetBrokerageName = "[Quote] SetBrokerageName",
  SetCustomerType = "[Quote] SetCustomerType",
  SetQuoteDueDate = "[Quote] SetQuoteDueDate",
  SetCustomerDetails = '[Quote] Set customer details',
  SetQuoteRequestState = '[Quote] Set quote request state',
  SetUtilityAccounts = '[Quote] Set utility accounts',
  SetQuoteConfiguration = '[Quote] Set Quote Configuration',

  // move to shared module state
  SetCreditCheckStatus = '[Quote] Set credit check status',
  SetIsCreditCheckApproved = '[Quote] Set is credit check approved',
}

export class FetchQuote implements Action {
  readonly type = QuotingModuleActionTypes.FetchQuote;
}

export class SetQuote implements Action {
  readonly type = QuotingModuleActionTypes.SetQuote;
  constructor(public quote: Quote, public userViewingQuote: UtilityHubIdentity, public hasIDontKnows: boolean, public youreDone = null) {}
}

export class ApprovalAccept implements Action {
  readonly type = QuotingModuleActionTypes.ApprovalAccept;
  constructor(public offers: Offer[]) {}
}

export class ApprovalReject implements Action {
  readonly type = QuotingModuleActionTypes.ApprovalReject;
  constructor(public quoteRequestId: number) {}
}

export class OfferAccept implements Action {
  readonly type = QuotingModuleActionTypes.OfferAccept;
  constructor(public offer: Offer) {}
}

export class OfferReject implements Action {
  readonly type = QuotingModuleActionTypes.OfferReject;
  constructor(public quoteRequestId: number) {}
}

export class OfferApprovalRequest implements Action {
  readonly type = QuotingModuleActionTypes.OfferApprovalRequest;
  constructor(public offers: Offer[]) {}
}

export class DocuSignContractSent implements Action {
  readonly type = QuotingModuleActionTypes.DocuSignContractSent;
  constructor(public offer: Offer) {}
}

export class SetYoureDone implements Action {
  readonly type = QuotingModuleActionTypes.SetYoureDone;
  constructor(public state: QuoteRequestState, public youreDone: YoureDone) {}
}

export class ClearQuote implements Action {
  readonly type = QuotingModuleActionTypes.ClearQuote;
}

export class SetQuoteDueDate implements Action {
  readonly type = QuotingModuleActionTypes.SetQuoteDueDate;
  constructor(public quoteDueDate: Date) {}
}

export class SetCustomerDetails implements Action {
  readonly type = QuotingModuleActionTypes.SetCustomerDetails;
  constructor(public quoteRequestId: number, public customerDetails: CustomerDetails) {}
}

export class SetQuoteRequestState implements Action {
  readonly type = QuotingModuleActionTypes.SetQuoteRequestState;
  constructor(public quoteRequestId: number, public quoteRequestState: QuoteRequestState) {}
}

export class SetUtilityAccounts implements Action {
  readonly type = QuotingModuleActionTypes.SetUtilityAccounts;
  constructor(public quoteRequestId: number, public utilityAccounts: UtilityAccount[]) {}
}

export class SetQuoteConfiguration implements Action {
  readonly type = QuotingModuleActionTypes.SetQuoteConfiguration;
  constructor(public quoteRequestId: number, public quoteConfiguration) {}
} 

// move to shared module state
// Here because of bug of shared module state getting overwritten
export class SetCreditCheckStatus implements Action {
  readonly type = QuotingModuleActionTypes.SetCreditCheckStatus;
  constructor(public quoteRequestId: number, public creditCheckStatus: CreditCheckStatus) {}
}

export class SetIsCreditCheckApproved implements Action {
  readonly type = QuotingModuleActionTypes.SetIsCreditCheckApproved;
  constructor(public quoteRequestId: number, public isCreditCheckApproved: boolean) {}
}

export type QuotingModuleActions = FetchQuote | SetQuote | ApprovalAccept | ApprovalReject | OfferAccept | OfferReject | DocuSignContractSent | SetYoureDone | ClearQuote | SetQuoteDueDate | SetCreditCheckStatus | SetIsCreditCheckApproved | SetCustomerDetails | SetQuoteRequestState | SetUtilityAccounts | SetQuoteConfiguration;
