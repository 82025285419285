import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthModuleState } from '@auth/state/auth.state';
import { ConfigurationModuleState } from '@configuration/state/configuration.state';
import { selectConfigurationState } from '@configuration/state/selectors/configuration.selectors';
import { UtilityInformationState } from '@utility-information/state/utility-information.state';
import { QuotingModuleState } from '@quoting/state/quoting-module.state';
import { selectLoggedInUser, selectLoggedInUtilityHubIdentity } from '@auth/state/selectors/auth.selectors';
import { selectQuote } from '@quoting/state/selectors/quoting-module.selectors';
import { AppState } from './app.state';
import { CustomerType } from '@app/shared/models/customer-type';
import { CreditCheckStatus } from '@app/shared/enums/credit-check-status';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';
import { QuoteRequestStatus } from '@app/shared/models/quote-request-status';

export const appStateSelector = (state: AppState) => state;

/* Feature Selectors */
export const authFeatureSelector = createFeatureSelector<AuthModuleState>('authModuleState');
export const configurationFeatureSelector = createFeatureSelector<ConfigurationModuleState>('configurationModuleState');
export const quotingFeatureSelector = createFeatureSelector<QuotingModuleState>('quotingModuleState');
export const utilityInformationFeatureSelector = createFeatureSelector<UtilityInformationState>('utilityInformationState');

/* Selectors */
export const configurationSelector = createSelector(
  configurationFeatureSelector,
  selectConfigurationState
);
export const loggedInUserSelector = createSelector(authFeatureSelector, selectLoggedInUser);
export const loggedInUtilityHubIdentitySelector = createSelector(authFeatureSelector, selectLoggedInUtilityHubIdentity);
export const quoteSelector = createSelector(quotingFeatureSelector, selectQuote);
export const tableauWorkbooksSelector = createSelector(configurationFeatureSelector, (state) => state.tableauWorkbooks);
export const quoteRequestSelector = createSelector(quotingFeatureSelector, (state) => state.quoteRequestState);

export const selectRequireLetterOfAuthorityForMeterVault = createSelector(
  configurationFeatureSelector,
  utilityHubConfiguration => utilityHubConfiguration.config.configuration.requireLetterOfAuthorityForMeterVault
)

export const creditCheckViewModelSelector = createSelector(appStateSelector, (state) => {
  // Dependencies from the app state
  let porUtility = state?.utilityInformationState?.utilityInformation?.[0]?.porUtility;
  let billingType = state?.quotingModuleState?.customerDetails?.billingType;
  let creditCheckEnabled = state?.configurationModuleState?.config?.supplierConfiguration?.creditCheckEnabled;
  let customerType = state?.quotingModuleState?.customerDetails?.customerType;

  // Credit check not available for Quick Quote for now
  // Tech debt - whitespace needs to be removed for Quick Quote
  let quoteRequestType = state?.quotingModuleState?.quoteRequestState.quoteRequestType.replace(/\s+/g, '');
  let isQuickQuote = quoteRequestType === QuoteRequestType.QuickQuote;

  // Create a view model based on dependencies 
  let creditCheckViewModel = new CreditCheckViewModel(porUtility, billingType, creditCheckEnabled, customerType, isQuickQuote);
  return creditCheckViewModel;
});

export class CreditCheckViewModel {

  constructor(
    public porUtility: boolean, 
    public billingType: string, 
    public creditCheckEnabled: boolean, 
    public customerType: string,
    public isQuickQuote: boolean
   ) {}

  get isVisible(): boolean {
    // Credit check not available for Quick Quote for now
    //if (this.isQuickQuote) return false;
    if (!this.creditCheckEnabled || this.customerType !== CustomerType.Commercial) return false;

    if (!this.porUtility) return true;
    if (this.porUtility && this.billingType == "Dual") return true;

    return false;
  }
}
// Data needed to get the consumption data(usage)
export const termOfOfferSelector = createSelector(quotingFeatureSelector, (state) => {
  let term = state?.acceptedOffer?.term;
  return term;
});

export const creditCheckRequestDataSelector = createSelector(appStateSelector, (state) => {
  // Values to calculate credit limit
  let offerSellingPrice = state?.quotingModuleState?.acceptedOffer?.sellingPrice;
  let riskNumberOfMonths = state?.configurationModuleState?.config?.supplierConfiguration?.riskNumberOfMonths;
  let usage = state?.utilityInformationState?.consumptionData;

  // Request values
  let businessName = state?.quotingModuleState?.customerDetails?.organisationName;
  let creditLimit = new CreditLimit(usage, riskNumberOfMonths, offerSellingPrice).creditLimit;
  let dunsNumber = state?.quotingModuleState?.customerDetails?.duns;
  let amountThresholdApproval = state?.configurationModuleState?.config?.supplierConfiguration?.riskAmountThresholdApproval;

  let creditCheckRequestData = {
    businessName,
    creditLimit,
    dunsNumber,
    amountThresholdApproval
  }
  return creditCheckRequestData;
});

export class CreditLimit {

  constructor(public usage: any, public riskNumberOfMonths: number, public offerSellingPrice: number) {}

  get creditLimit(): number {

    if (this.usage) {
    // Stops mutating the state
      let copyOfUsage = [...this.usage];
      copyOfUsage?.sort((a?, b?) => b.consumption - a.consumption); 

      const topUsage = copyOfUsage?.slice(0, this.riskNumberOfMonths);
      const sum = topUsage?.reduce((a, c) => a + c.consumption, 0);

      let creditLimit = sum * this.offerSellingPrice;
      return creditLimit;
    }
    // Returns a default value if usage not available
    return 0;
  }
}

// Has credit check been approved or has passed so user can submit contract and complete quote
export const successfulCreditCheckSelector = createSelector(quotingFeatureSelector, (state) => {
  let creditCheckStatus = state?.creditCheckStatus;
  let approved = state?.isCreditCheckApproved;

  let isCreditCheckPassed = creditCheckStatus === CreditCheckStatus.CreditPassed;
  let sucessfulCreditCheck = isCreditCheckPassed || approved;

  return sucessfulCreditCheck;
});

export const quoteRequestStateSelector = createSelector(quotingFeatureSelector, (state) => {
  let quoteRequestState = state?.quoteRequestState;
  return quoteRequestState;
});

export const utilityAccountsSelector = createSelector(quotingFeatureSelector, (state) => {
  let utilityAccounts = state?.utilityAccounts;
  return utilityAccounts;
});

export const quoteConfigurationSelector = createSelector(quotingFeatureSelector, (state) => {
  let quoteConfiguration = state?.quoteConfiguration;
  return quoteConfiguration;
});

const readOnlyQuoteRequestStatuses: QuoteRequestStatus[] = 
  [
    QuoteRequestStatus.PartiallyEnrolled,
    QuoteRequestStatus.Enrolled,
    QuoteRequestStatus.PendingEnrollment,
    QuoteRequestStatus.ContractLive,
    QuoteRequestStatus.ContractDropped,
    QuoteRequestStatus.ContractDropPending,
    QuoteRequestStatus.ContractExpiring,
    QuoteRequestStatus.SubmissionSuccessful,
    QuoteRequestStatus.PartiallySubmitted,
    QuoteRequestStatus.SubmissionFailed
  ];

export const isReadOnlySelector = createSelector(quotingFeatureSelector, ({ quote }) => {
  let quoteRequestStatus = quote?.status || quote?.quoteRequestState?.status;
  let isReadOnlyQuoteRequestStatus = readOnlyQuoteRequestStatuses.includes(quoteRequestStatus as QuoteRequestStatus);
  return isReadOnlyQuoteRequestStatus;
});