export enum CreditCheckStatus {
    CreditPassed = 'CreditPassed',
    CreditPending = 'CreditPending',
    CreditFailed = 'CreditFailed',
    CreditReviewRequired = 'CreditReviewRequired',
    CreditInsufficientData = 'CreditInsufficientData',
    CreditError = 'CreditError',
    CreditUnknown = 'CreditUnknown',
    CreditNotPerformed = 'CreditNotPerformed'
}
