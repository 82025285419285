import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmComponent } from '../../shared/components/confirm/confirm.component';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '../../shared/services/config.service';
import { map, mergeMap } from 'rxjs/operators';
import { WizardStepProviderService } from './wizard-step-provider.service';

@Injectable({
  providedIn: 'any',
})
export class WizardService {
  public static deps = [Router, MatDialog, HttpClient, ConfigService];

  private saveFunc: (navigateArgs: NavigateArgs) => Observable<any>;
  private route: ActivatedRoute;

  private get currentStepSlug(): string {
    return this.route.snapshot.url[1].path;
  }

  private get quoteRequestId(): number {
    return isNaN(this.route?.snapshot?.params?.quoteRequestId) ? null : Number(this.route.snapshot.params.quoteRequestId);
  }

  private allSteps: WizardStep[];

  private initComplete: boolean;

  public get currentStep(): WizardStep {
    return this.allSteps?.filter((f) => f.slug === this.currentStepSlug)[0];
  }

  public get activeSteps(): WizardStep[] {
    return this.allSteps?.filter((f) => f.active);
  }

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private serverValidation: WizardValidationService,
    private stepsProvider: WizardStepProviderService
  ) {}

  public clickedStepIsPreviousStep(journeySteps: WizardStep[], clickedStep: WizardStep): boolean {
    if (!clickedStep) {
      return false;
    }
    return journeySteps.findIndex((x) => x.slug === clickedStep.slug) < journeySteps.findIndex((x) => x.slug === this.currentStep.slug);
  }

  public bindStep(route: ActivatedRoute, saveFunc: (navigateArgs: NavigateArgs) => Observable<any>) {
    const baseStep = { active: true, enabled: true, valid: false };
    this.saveFunc = saveFunc;
    this.route = route;

    const stepsCollection = this.stepsProvider.getSteps(route);

    this.allSteps = stepsCollection.steps.map((x) => ({ ...x, ...baseStep, selected: x.slug === this.currentStepSlug }));
    this.getWizardStepCompletionState();
    this.initComplete = true;
  }

  public stepClicked(step: any, isDirty: boolean) {
    this.saveAndUpVal({ step, isDirty }).subscribe();
  }

  public advanceToLastStep() {
    const youreDoneStep = this.activeSteps.find((step) => step.slug === 'youre-done');
    this.stepClicked(youreDoneStep, false);
  }

  public next(isDirty = false) {
    this.saveAndUpVal({ direction: 1, isDirty}).subscribe();
  }

  public previous(isDirty = false) {
    this.saveAndUpVal({ direction: -1, isDirty }).subscribe();
  }

  public resolveExpireQuoteWarningDialog(isDirty = true): QuoteExpireWarningDialogResult {
    const required = isDirty ?? true;
    return {
      dialogRef: required
        ? this.dialog.open(ConfirmComponent, {
            width: '30%',
            data: {
              title: 'Are you sure you want to edit the details on this page?',
              message:
                'If you edit any details, your quote will be invalidated and you will need to request a new quote.  Are you sure you want to continue?',
              primaryButtonText: 'Confirm Changes',
            },
          })
        : null,
      required,
    };
  }

  // add a parameter to the save observable
  private saveAndUpVal(navigateArgs: NavigateArgs): Observable<boolean> {
    return new Observable<boolean>((obs) => {
      const quoteExpireWarning = this.resolveExpireQuoteWarningDialog(navigateArgs.isDirty);
      if (quoteExpireWarning.required) {
        quoteExpireWarning.dialogRef.afterClosed().subscribe((confirmed) => {
          // confirmed means user edited details and will invalidate quote request
          if (confirmed) {         
            return this.generateSaveObservable(navigateArgs).subscribe();
          } else {
            // user cancelled edit and will not invalidate quote request
            return obs.next(false);
          }    
        });
      } else {
        return this.generateSaveObservable(navigateArgs).subscribe();
      }
    });
  }

  private generateSaveObservable(navigateArgs: NavigateArgs): Observable<boolean> {
    const save = this.saveFunc(navigateArgs);
    const getServerValidation = this.getServerValidation();

    return save.pipe(
      mergeMap((saved) =>
        getServerValidation.pipe(
          map((validationInfo) => {
            this.mapStepCompletionState(validationInfo);
            return this.finaliseNavigation(navigateArgs, saved);
          })
        )
      )
    );
  }

  private finaliseNavigation(navigateArgs: NavigateArgs, saved: boolean): boolean {
    const navToInfo = this.getNavToInfo(navigateArgs, saved);
    if (saved) {
      this.router.navigate(['../', navToInfo.slug], {
        relativeTo: this.route,
        queryParamsHandling: 'preserve',
      });
    } else if (navToInfo.isPrev && navigateArgs.isDirty) {
      const dialogRef = this.dialog.open(ConfirmComponent, {
        width: '25%',
        data: {
          title: 'Lose unsaved changes',
          message: `Please correct invalid data or click 'Go Back' to lose unsaved changes.`,
          primaryButtonText: 'Go Back',
          hideCancel: false,
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.router.navigate(['../', navToInfo.slug], {
            relativeTo: this.route,
          });
        }
      });
    }

    return saved;
  }

  private getNavToInfo(navigateArgs: NavigateArgs, saved: boolean) {
    let navigateToIndex = 0;
    const curStepIndex = this.activeSteps.indexOf(this.currentStep);
    if (navigateArgs.step) {
      navigateToIndex = this.activeSteps.indexOf(navigateArgs.step);
    } else {
      navigateToIndex = curStepIndex + navigateArgs.direction;
    }
    const firstInvalidStepIndex = this.activeSteps.findIndex((step) => !step.valid);

    if (navigateToIndex < 0) {
      navigateToIndex = 0;
    }
    if (firstInvalidStepIndex !== -1 && navigateToIndex > firstInvalidStepIndex) {
      navigateToIndex = firstInvalidStepIndex;
    }
    const navToSlug = this.activeSteps[navigateToIndex].slug;
    return { slug: navToSlug, isPrev: navigateToIndex < curStepIndex };
  }

  private getWizardStepCompletionState() {
    this.getServerValidation().subscribe((validationInfo) => {
      this.mapStepCompletionState(validationInfo);
    });
  }

  private mapStepCompletionState(validationInfo: any) {
    if (validationInfo) {
      this.allSteps.map((step) => {
        const validationResult = validationInfo.filter((f) => f.slug === step.slug)[0];
        return Object.assign(step, validationResult);
      });
    }
  }

  private getServerValidation(quoteRequestId: number = null): Observable<any> {
    const requestId = this.quoteRequestId ?? quoteRequestId;
    if (requestId) {
      return this.serverValidation.getServerStepsValidation(requestId, this.route);
    } else {
      return of(null);
    }
  }
}

export interface WizardStep {
  name: string;
  svg: string;
  slug: string;
  active?: boolean;
  enabled?: boolean;
  valid?: boolean;
  selected?: boolean;
  error?: boolean;
  errors?: [];
}

export interface NavigateArgs {
  direction?: number;
  step?: any;
  isDirty?: boolean;
}

@Injectable({
  providedIn: 'any',
})
export class WizardStepCol {
  public steps: WizardStep[] = [];
}

@Injectable({
  providedIn: 'any',
})
export class WizardValidationService {
  public getServerStepsValidation(quoteRequestId: number, activatedRoute?: ActivatedRoute): Observable<any> {
    return of([]); 
  }
}

export interface QuoteExpireWarningDialogResult {
  dialogRef: MatDialogRef<ConfirmComponent>;
  required: boolean;
}
