export enum QuoteRequestStatus {
  InProgress = 'InProgress',
  PricesRequested = 'PricesRequested',
  ApiCallFailed = 'ApiCallFailed',
  QuoteRequested = 'QuoteRequested',
  QuoteRequestApproved = 'QuoteRequestApproved',
  QuoteRequestRejected = 'QuoteRequestRejected',
  QuoteExpiredUnderReview = 'QuoteExpiredUnderReview',
  QuoteAccepted = 'QuoteAccepted',
  QuoteRejected = 'QuoteRejected',
  PendingContract = 'PendingContract',
  ContractSigned = 'ContractSigned',
  PendingEnrollment = 'PendingEnrollment',
  DocuSignRejected = 'DocuSignRejected',
  Enrolled = 'Enrolled',
  QuoteGenerated = 'QuoteGenerated',
  QuoteExpired = 'QuoteExpired',
  QuoteRequestComplete = 'QuoteRequestComplete',
  RefreshRequested = 'RefreshRequested',
  PartiallyEnrolled = 'PartiallyEnrolled',
  ContractLive = 'ContractLive',
  ContractExpiring = 'ContractExpiring',
  ContractExpired = 'ContractExpired',
  ContractDropPending = 'ContractDropPending',
  ContractDropped = 'ContractDropped',
  DocuSignCompleted = 'DocuSignCompleted',
  CreditFailed = 'CreditFailed',
  CreditReviewRequired = 'CreditReviewRequired',
  CreditApproved = 'CreditApproved',
  ApprovalRequired = 'ApprovalRequired',
  UsageRequested = 'UsageRequested',
  UsageReceived = 'UsageReceived',
  UsageUploaded = 'UsageUploaded',
  UsageRequired = 'UsageRequired',
  SubmissionSuccessful = 'SubmissionSuccessful',
  SubmissionFailed = 'SubmissionFailed',
  PartiallySubmitted = 'PartiallySubmitted',
}

export enum QuoteRequestDisplayStatus {
  InProgress = 'InProgress',
  QuoteGenerated = 'QuoteGenerated',
  QuoteExpired = 'QuoteExpired',
  OfferRejected = 'OfferRejected',
  OfferAccepted = 'OfferAccepted',
  ContractPending = 'ContractPending',
  ContractRejected = 'ContractRejected',
  CustomerEnrolled = 'CustomerEnrolled',
  QuoteRequested = 'QuoteRequested',
  QuoteSent = 'QuoteSent',
  RequestRejected = 'RequestRejected',
  EnrollmentPending = 'EnrollmentPending',
  QuoteRequestComplete = 'QuoteRequestComplete',
  RefreshRequested = 'RefreshRequested',
  QuoteEnrolled = 'QuoteEnrolled',
  DocuSignCompleted = 'DocuSignCompleted',
  PartiallyEnrolled = 'PartiallyEnrolled',
  Enrolled = 'Enrolled',
  ContractLive = 'ContractLive',
  ContractExpiring = 'ContractExpiring',
  ContractExpired = 'ContractExpired',
  ContractDropPending = 'ContractDropPending',
  ContractDropped = 'ContractDropped',
  ApiCallFailed = 'ApiCallFailed',
  CreditFailed = 'CreditFailed',
  CreditReviewRequired = 'CreditReviewRequired',
  QuoteDueToday = 'QuoteDueToday',
  CreditApproved = 'CreditApproved',
  QuoteDateOverdue = 'QuoteDateOverdue',
  UsageRequested = 'UsageRequested',
  UsageReceived = 'UsageReceived',
  UsageUploaded = 'UsageUploaded',
  UsageRequired = 'UsageRequired',
  SubmissionSuccessful = 'SubmissionSuccessful',
  SubmissionFailed = 'SubmissionFailed',
  PartiallySubmitted = 'PartiallySubmitted',
}
