<div class="container main-content">
  <h2> Quote Settings </h2>
  <br>
  <form novalidate [formGroup]="frmSettingQuote">
    <div class="subheading">
        <mat-icon  class="icon">
          <img src="../../../../../assets/icons/icon-electricity.svg" alt=""/> ELECTRICITY
        </mat-icon>
    </div>
    <section *ngIf="electricityAllowMatrix">
      <p>Matrix Quotes</p>
      <div>
        <mat-form-field appearance="standard" style="width: 20%">
          <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
          <input type="number" matInput formControlName="matrixAdditionalCostOverall" />
          <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostOverall')?.errors?.required"> This Field is
            Required </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostOverall')?.errors?.min"> the value must be greater
            than 0 </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostOverall')?.hasError('pattern')"> Numeric values
            only, max 6 decimal places</mat-error>
        </mat-form-field>
      </div>
      <div class="field-holder w2 checkbox-label row">
        <mat-checkbox formControlName="setAdditionalCostMatrix" id="setAdditionalCostMatrix" (change)="setAdditionalCostMatrix()"
        ></mat-checkbox>
        <mat-label> Set additional cost per ISO </mat-label>
      </div>
      <div *ngIf="setElectricityAdditionalCostMatrix">
        <div class="iso-container">
          <span>ERCOT</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="matrixAdditionalCostErcot" />
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostErcot')?.errors?.required"> This Field is
              Required </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostErcot')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostErcot')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span>ISO-NE</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="matrixAdditionalCostIsone" />
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostIsone')?.errors?.required"> This Field is
              Required </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostIsone')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostIsone')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span class="miso">MISO</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="matrixAdditionalCostMiso" />
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostMiso')?.errors?.required"> This Field is Required
            </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostMiso')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostMiso')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span>NYISO</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="matrixAdditionalCostNyiso" />
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostNyiso')?.errors?.required"> This Field is
              Required </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostNyiso')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostNyiso')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span class="pjm"> PJM</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="matrixAdditionalCostPjm" />
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostPjm')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostPjm')?.errors?.required"> This Field is Required
            </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('matrixAdditionalCostPjm')?.hasError('pattern')"> Numeric values only,
              max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
      </div>
    </section>
    <br>
    <br>
    <section *ngIf="electricityAllowCustom">
      <div>
        <p>Custom Quotes</p>
        <mat-form-field appearance="standard" style="width: 20%">
          <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
          <input type="number" matInput formControlName="customAdditionalCostOverall" />
          <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostOverall')?.errors?.required"> This Field is
            Required </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostOverall')?.errors?.min"> the value must be greater
            than 0 </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostOverall')?.hasError('pattern')"> Numeric values
            only, max 6 decimal places</mat-error>
        </mat-form-field>
      </div>
      <div class="field-holder w2 checkbox-label row">
        <mat-checkbox formControlName="setAdditionalCostCustom" id="setAdditionalCostCustom" (change)="setAdditionalCostCustom()"
          ></mat-checkbox>
        <mat-label> Set additional cost per ISO </mat-label>
      </div>
      <div *ngIf="setElectricityAdditionalCostCustom">
        <div class="iso-container">
          <span>ERCOT</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="customAdditionalCostErcot" />
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostErcot')?.errors?.required"> This Field is
              Required </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostErcot')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostErcot')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span>ISO-NE</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="customAdditionalCostIsone" />
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostIsone')?.errors?.required"> This Field is
              Required </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostIsone')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostIsone')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span class="miso">MISO</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="customAdditionalCostMiso" />
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostMiso')?.errors?.required"> This Field is Required
            </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostMiso')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostMiso')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span>NYISO</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="customAdditionalCostNyiso" />
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostNyiso')?.errors?.required"> This Field is
              Required </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostNyiso')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostNyiso')?.hasError('pattern')"> Numeric values
              only, max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
        <div class="iso-container">
          <span class="pjm">PJM</span>
          <mat-form-field appearance="standard" style="width: 20%">
            <mat-label>Additional Cost Per Offer (Mills/kWh)</mat-label>
            <input type="number" matInput formControlName="customAdditionalCostPjm" />
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostPjm')?.errors?.required"> This Field is Required
            </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostPjm')?.errors?.min"> the value must be greater
              than 0 </mat-error>
            <mat-error *ngIf="frmSettingQuote.get('customAdditionalCostPjm')?.hasError('pattern')"> Numeric values only,
              max 6 decimal places</mat-error>
          </mat-form-field>
        </div>
      </div>
      <br>
    </section>
    <br>
    <br>
      <div class="subheading">
        <div>
          <mat-icon class="icon">
            <img src="../../../../../assets/icons/icon-gas.svg" alt=""/> GAS
          </mat-icon>
        </div>
      </div>
    <section>
      <section *ngIf="gasAllowMatrix">
      <p>Matrix Quotes</p>
      <div>
        <mat-form-field appearance="standard" style="width: 20%">
          <mat-label>Additional Cost Per Offer (Mills/{{ ((utilityUOM$ | async) === 'therms' ) ? 'therms' : 'Ccf'  }})
          </mat-label>
          <input type="number" matInput formControlName="gasmatrixAdditionalCost" />
          <mat-error *ngIf="frmSettingQuote.get('gasmatrixAdditionalCost')?.errors?.required"> This Field is Required
          </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('gasmatrixAdditionalCost')?.errors?.min"> the value must be greater than
            0 </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('gasmatrixAdditionalCost')?.hasError('pattern')"> Numeric values only,
            max 6 decimal places</mat-error>
        </mat-form-field>
      </div>
    </section>
    <section *ngIf="gasAllowCustom">
      <p>Custom Quotes</p>
      <div>
        <mat-form-field appearance="standard" style="width: 20%">
          <mat-label>Additional Cost Per Offer (Mills/{{ ((utilityUOM$ | async) === 'therms' ) ? 'therms' : 'Ccf' }})
          </mat-label>
          <input type="number" matInput formControlName="gascustomAdditionalCost" />
          <mat-error *ngIf="frmSettingQuote.get('gascustomAdditionalCost')?.errors?.required"> This Field is Required
          </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('gascustomAdditionalCost')?.errors?.min"> the value must be greater than
            0 </mat-error>
          <mat-error *ngIf="frmSettingQuote.get('gascustomAdditionalCost')?.hasError('pattern')"> Numeric values only,
            max 6 decimal places</mat-error>
        </mat-form-field>
      </div>
    </section>
    </section>
  </form>
  <br>
  <div class="action-buttons">
    <button mat-stroked-button color="accent" (click)="edit()" cdkFocusInitial *ngIf="isEditable">
      <mat-icon color="accent" matPrefix>edit</mat-icon> Edit
    </button>
    <button mat-flat-button color="accent" (click)="saves()" cdkFocusInitial *ngIf="!isEditable">
      Save Changes
    </button>
    <button mat-stroked-button color="accent" (click)="reset()" *ngIf="!isEditable">Cancel</button>
  </div>
</div>
