export class UtilityHubIdentity {
  constructor(values: any) {
    for (const key in values) {
      if (values[key] !== null) {
        this[key] = values[key];
      }
    }
  }

  public id: string;
  public fullName: string;
  public email: string;
  public phoneNumber: string;
  public mobileNumber: string;
  public currentOrganisation: UtilityHubOrganisation;
  public validIdentity: boolean;
  public assignedBrokerages: UtilityHubOrganisation[];
  public assignedSuppliers: UtilityHubOrganisation[];

  public getAllAssignedOrganisations(): UtilityHubOrganisation[] {
    let orgs: UtilityHubOrganisation[] = [];

    if (this.assignedBrokerages) {
      orgs = orgs.concat(this.assignedBrokerages);
    }
    if (this.assignedSuppliers) {
      orgs = orgs.concat(this.assignedSuppliers);
    }

    return orgs
      .filter((x) => x.id !== this.currentOrganisation.id)
      .sort((a, b) => {
        return a.role.localeCompare(b.role, 'gb', { sensitivity: 'base' });
      })
      .sort((a, b) => {
        return a.name.localeCompare(b.name, 'gb', { sensitivity: 'base' });
      });
  }

  public get isSupplier(): boolean {
    return this.currentOrganisation.role === UserRole.Supplier || this.currentOrganisation.role === UserRole.SupplierManager;
  }

  public get isSupplierManager(): boolean {
    return this.currentOrganisation.role === UserRole.SupplierManager;
  }

  public get isSupplierManagerOrSalesManager(): boolean {
    return this.currentOrganisation.role === UserRole.SupplierManager || this.currentOrganisation.role === UserRole.SalesManager;
  }

  public get isBroker(): boolean {
    return this.currentOrganisation.role === UserRole.Broker || this.currentOrganisation.role === UserRole.BrokerManager;
  }

  public get isSalesManager(): boolean {
    return this.currentOrganisation.role === UserRole.SalesManager;
  }

  public hasRole(role: string): boolean {
    return this.currentOrganisation.role === role;
  }
}

export const HasRole = (user: UtilityHubIdentity, role: string): boolean => user?.currentOrganisation?.role === role;

export class UtilityHubOrganisation {
  public id: string;
  public name: string;
  public role: string;
  public entityType: string;
  public vanityName: string;
  public externalId: string;
  public assignedSuppliers: UtilityHubOrganisation[];
}

export enum UserRole {
  Supplier = 'Supplier',
  SupplierManager = 'SupplierManager',
  CreditAdmin = 'CreditAdmin',
  Broker = 'Broker',
  BrokerManager = 'BrokerManager',
  SalesManager = 'SalesManager'
}
