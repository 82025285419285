import { HttpErrorResponse } from '@angular/common/http';
import { UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import * as Sentry from '@sentry/angular';
import { AppInsights } from './ai.service';

export class Logger {
  public static Log: Logger;

  private options: { transports: ILogTransport[]; user?: UtilityHubIdentity; componentName?: string };

  constructor(options: { transports: ILogTransport[]; user?: UtilityHubIdentity; componentName?: string }) {
    this.options = options;
  }

  public static for<TComponent>(component: TComponent): Logger {
    const logger = new Logger({ ...this.Log.options, componentName: component?.constructor.name });
    return logger;
  }

  public setContext(context: { user: UtilityHubIdentity }) {
    this.options.user = context.user;
  }

  public addTransport(transport: ILogTransport) {
    this.options.transports.push(transport);
  }

  public info(message: string, props?: object): void {
    if (this.options.user) {
      props = {
        user: { email: this.options.user.email, organisation: this.options.user.currentOrganisation, userId: this.options.user.id },
        ...props,
      };
    }

    this.options.transports.forEach((t) => t.info(message, props, this.options.componentName));
  }

  public error(error: Error | HttpErrorResponse | any, props?: object): void {
    if (this.options.user) {
      props = {
        user: { email: this.options.user.email, organisation: this.options.user.currentOrganisation, userId: this.options.user.id },
        ...props,
      };
    }

    this.options.transports.forEach((t) => t.error(error, props, this.options.componentName));
  }
}

export class AppInsightTransport implements ILogTransport {
  info(message: string, props?: object): void {
    if (props) {
      AppInsights.telemetry.trackEvent({ name: message, properties: props });
    } else {
      AppInsights.telemetry.trackEvent({ name: message });
    }
  }
  error(error: Error | HttpErrorResponse | any, props?: object): void {
    if (props) {
      AppInsights.telemetry.trackException({ exception: error, properties: props });
    } else {
      AppInsights.telemetry.trackException({ exception: error });
    }
  }
}

export class ConsoleTransport implements ILogTransport {
  info(message: string, props?: object, componentName?: string): void {
    const ts = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const line = `[${ts}${componentName ? ` ${componentName}` : ''} INFO]: ${message}`;
    if (props) {
      console.log(line, props);
    } else {
      console.log(line);
    }
  }
  error(error: Error | HttpErrorResponse | any, props?: object, componentName?: string): void {
    const ts = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const line = `[${ts}${componentName ? ` ${componentName}` : ''} ERROR]: ${error.message}`;
    if (props) {
      console.error(line, { error, ...props });
    } else {
      console.error(line, error);
    }
  }
}

export class SentryTransport implements ILogTransport {
  info(message: string, props?: object): void {
    const ts = new Date().toISOString().replace(/T/, ' ').replace(/\..+/, '');
    const line = `[${ts} INFO]: ${message}`;
    if (props) {
      Sentry.captureMessage(line, props);
    } else {
      Sentry.captureMessage(line);
    }
  }
  error(error: Error | HttpErrorResponse | any, props?: object): void {
    if (error instanceof HttpErrorResponse) {
      let err: Error;
      err = (error.error.error ? error.error.error : error.error) as Error;
      Sentry.captureException(err, props);
    } else {
      if (error.error) {
        Sentry.captureException(error.error, props);
      } else {
        Sentry.captureException(error, props);
      }
    }
  }
}

interface ILogTransport {
  info(message: string, props?: object, componentName?: string): void;
  error(error: Error | HttpErrorResponse | any, props?: object, componentName?: string): void;
}
