import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "@app/shared/services/config.service";
import { Observable } from "rxjs";
import { tap } from "rxjs/operators";
import { TableauDashboardModel } from "../models/tableau-dashboard.model";
import { TableauOrganisationWorkbooksModel } from "../models/tableau-organisation-workbooks.model";

@Injectable({
    providedIn: 'root',
  })
export class ReportsService {
    constructor(private http: HttpClient, private configService: ConfigService) { }

    public getTableauWorkbooks(organisationId: string): Observable<TableauOrganisationWorkbooksModel> {
        const url = this.configService.getApiPath(`tableau/workbooks/${organisationId}`);
        return this.http.get<TableauOrganisationWorkbooksModel>(url);
    }

  public generateReportUri(workbookName: string, dashboardTitle: string): Observable<any>
  {
    const url = this.configService.getApiPath(`tableau/${workbookName}/${dashboardTitle}`);
    return this.http.get<any>(url).pipe(
      tap((x) =>
      {
        return (x.location = { x });
      })
    );
  }

  public getAvailableDashboards(workbookName: string): Observable<TableauDashboardModel[]> {
      const url = this.configService.getApiPath(`tableau/dashboards/${workbookName}`); 
      return this.http.get<TableauDashboardModel[]>(url);
  } 
}
