import { QuotingModuleActions, QuotingModuleActionTypes } from '../actions/quoting-module.actions';
import { ApprovalStatus, QuotePriceStatus } from '../../models/quote';
import { quotingModuleInitialState, QuotingModuleState } from '../quoting-module.state';

export function quotingModuleReducer(state = quotingModuleInitialState, action: QuotingModuleActions): QuotingModuleState {
  switch (action.type) {
    case QuotingModuleActionTypes.FetchQuote:
      return { ...state, fetching: true };
    case QuotingModuleActionTypes.SetQuote:
      return {
        ...state,
        quote: action.quote,
        userViewingQuote: action.userViewingQuote,
        acceptedOffer: action.quote.prices?.filter((p) => p.status === QuotePriceStatus.OfferAccepted)[0],
        approvedOffers: action.quote.prices?.filter((p) => p.approvalStatus === ApprovalStatus.Approved),
        fetching: false,
        hasIDontKnows: action.hasIDontKnows,
      };
    case QuotingModuleActionTypes.ApprovalAccept:
      return { ...state, approvedOffers: action.offers };
    case QuotingModuleActionTypes.OfferAccept:
      return { ...state, acceptedOffer: action.offer };
    case QuotingModuleActionTypes.DocuSignContractSent:
      return { ...state, acceptedOffer: action.offer };
    case QuotingModuleActionTypes.SetYoureDone:
      return { ...state, quote: { quoteRequestState: action.state, youreDone: action.youreDone } };
    case QuotingModuleActionTypes.ClearQuote:
      return { ...state, quote: undefined, approvedOffers: undefined, acceptedOffer: undefined, hasIDontKnows: undefined };
    case QuotingModuleActionTypes.SetQuoteDueDate:
      return { ...state, quoteDueDate: action.quoteDueDate };
    case QuotingModuleActionTypes.SetCreditCheckStatus:
      return { ...state, creditCheckStatus: action.creditCheckStatus };
    case QuotingModuleActionTypes.SetIsCreditCheckApproved:
      return { ...state, isCreditCheckApproved: action.isCreditCheckApproved };
    case QuotingModuleActionTypes.SetCustomerDetails:
      return { ...state, customerDetails: action.customerDetails };
    case QuotingModuleActionTypes.SetQuoteRequestState:
      return { ...state, quoteRequestState: action.quoteRequestState };
    case QuotingModuleActionTypes.SetUtilityAccounts:
      return { ...state, utilityAccounts: action.utilityAccounts};
    case QuotingModuleActionTypes.SetQuoteConfiguration:
      return { ...state, quoteConfiguration: action.quoteConfiguration}
    default:
      return state;
  }
}
