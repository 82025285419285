import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UploadService } from '../../upload.service';
import { forkJoin, of, Observable, Subscription } from 'rxjs';
import { UdFile } from '../../UdFile';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError } from 'rxjs/operators';

@Component({
  selector: 'app-upload-progress-dialog',
  templateUrl: './upload-progress-dialog.component.html',
  styleUrls: ['./upload-progress-dialog.component.scss'],
})
export class UploadProgressDialogComponent implements OnInit, OnDestroy {
  uploadStatus;
  canBeClosed = true;
  primaryButtonText = 'Uploading';
  showCancelButton = true;
  uploading = false;
  uploadSuccessful = false;
  closeOnSuccess = true;
  files: Array<UdFile> = new Array<UdFile>();
  uploadUrl: string;
  additionalData: any;
  filesWithErrors: UdFile[];
  forceUpload: boolean;
  allProgress$: Subscription;

  // tslint:disable-next-line:max-line-length
  constructor(
    public dialogRef: MatDialogRef<UploadProgressDialogComponent>,
    public uploadService: UploadService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.files = this.data.files;
    this.uploadUrl = this.data.uploadUrl;
    this.forceUpload = this.data.forceUpload;
    this.additionalData = this.data.additionalData;

    if (this.data.closeOnSuccess !== undefined) {
      this.closeOnSuccess = this.data.closeOnSuccess;
    }
    if (this.files.length === 0) {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.allProgress$?.unsubscribe();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnInit() {
    this.uploadFiles();
  }

  uploadFiles() {
    // if everything was uploaded already, just close the dialog
    if (this.uploadSuccessful) {
      return this.dialogRef.close(this.filesWithErrors);
    }

    // set the component state to "uploading"
    this.uploading = true;

    // start the upload and save the progress map
    this.uploadStatus = this.uploadService.upload(this.data.files, this.uploadUrl, this.additionalData);

    // convert the progress map into an array
    const allProgressObservables: Observable<UdFile>[] = [];
    // tslint:disable-next-line:forin
    for (const key in this.uploadStatus) {
      allProgressObservables.push(this.uploadStatus[key].progress.pipe(catchError((err) => of(err))));
    }

    // Adjust the state variables
    // The OK-button should have the text "Finish" now
    this.primaryButtonText = 'OK';

    // The dialog should not be closed while uploading
    this.canBeClosed = false;
    this.dialogRef.disableClose = true;

    // Hide the cancel-button
    this.showCancelButton = false;

    // When all progress-observables are completed...
    this.allProgress$ = forkJoin(allProgressObservables).subscribe(
      (next) => {
        next.forEach((x) => {
          if (!x.error) {
            this.data.completedFiles.push(x);
          }
        });
        this.completeUpload(next);
      },
      () => {
        this.completeUpload(null);
      }
    );
  }

  completeUpload(completedFiles) {
    // ... the dialog can be closed again...
    this.canBeClosed = true;
    this.dialogRef.disableClose = false;

    // ... the upload was successful...
    this.uploadSuccessful = true;

    // ... and the component is no longer uploading
    this.uploading = false;

    this.filesWithErrors = completedFiles.filter((x) => x.data?.errors || x.error);
    if (this.closeOnSuccess && completedFiles && !completedFiles.some((x) => x.data.errors)) {
      this.dialogRef.close();
    }
  }
}
