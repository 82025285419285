<div class="dialog-heading">
  <h5>{{ data.title }}</h5>
  <span class="flex-fill"></span>
  <mat-icon class="close-dialog" aria-hidden="false" [mat-dialog-close]="{ confirmed: false }" aria-label="Close icon"> close</mat-icon>
</div>

<mat-dialog-content [formGroup]="quoteRejectionForm">
  <div class="combos">
    <div>
      <mat-form-field appearance="standard">
        <mat-label>Offer Rejection Reason</mat-label>
        <mat-select formControlName="quoteRejectionReason" placeholder="-- Please Select --">
          <mat-option value="">-- Please Select --</mat-option>
          <mat-option value="Agreed directly with a supplier">Agreed directly with a supplier</mat-option>
          <mat-option value="Agreed with the current supplier">Agreed with the current supplier</mat-option>
          <mat-option value="Credit reasons">Credit reasons</mat-option>
          <mat-option value="Customer rejects supplier\'s offer">Customer rejects supplier's offer</mat-option>
          <mat-option value="Customer unavailable">Customer unavailable</mat-option>
          <mat-option value="The quote is too high">The quote is too high</mat-option>
        </mat-select>
        <mat-error> Please select </mat-error>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="standard">
        <mat-label>Where Did We Rank?</mat-label>
        <mat-select formControlName="whereDidWeRank" placeholder="-- Please Select --">
          <mat-option value="">-- Please Select --</mat-option>
          <mat-option value="1">1</mat-option>
          <mat-option value="2">2</mat-option>
          <mat-option value="3">3</mat-option>
          <mat-option value="4">4</mat-option>
          <mat-option value="5">5</mat-option>
          <mat-option value="6">6</mat-option>
          <mat-option value="7">7</mat-option>
          <mat-option value="8">8</mat-option>
          <mat-option value="9">9</mat-option>
          <mat-option value="10">10</mat-option>
        </mat-select>
        <mat-error> Please select </mat-error>
      </mat-form-field>
    </div>
  </div>

  <div>
    <label class="feedback-label">Offer Feedback</label>
    <mat-form-field class="full-width" appearance="outline">
      <textarea rows="6" class="quoteFeedback full-width" matInput formControlName="quoteFeedback"></textarea>
      <mat-error> Cannot be more than 255 characters. </mat-error>
    </mat-form-field>
  </div>
</mat-dialog-content>

<!-- This are the actions of the dialog, containing the primary and the cancel button-->
<mat-dialog-actions class="actions">
  <button mat-flat-button color="accent" (click)="send()">Send</button>
  <button mat-stroked-button color="accent" [mat-dialog-close]="{ confirmed: false }">Cancel</button>
</mat-dialog-actions>
