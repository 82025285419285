<div class="container main-content">
  <h3>My Account</h3>
  <div class="flex">
    <form novalidate [formGroup]="frmUser">
      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Full Name</mat-label>
        <input type="text" matInput formControlName="fullName" />
        <mat-error *ngIf="frmUser.get('fullName').errors?.required"> Please enter your Full Name </mat-error>
        <mat-error *ngIf="frmUser.get('fullName').errors?.maxlength"> Full Name cannot exceed 100 characters </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Email</mat-label>
        <input type="text" matInput formControlName="email" />
        <mat-error *ngIf="frmUser.get('email').errors?.required"> Please enter an email </mat-error>
        <mat-error *ngIf="frmUser.get('email').errors?.taken"> Email has already been taken, please enter a different email </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Phone</mat-label>
        <input type="text" matInput formControlName="phoneNumber" placeholder="+1(XXX)-XXX-XXXX" pattern="([+]?\d{1,2}[.-\s]?)?(\([0-9]{3}\)[.-]?|\d{3}[.-]?){2}\d{4}" />
        <mat-error *ngIf="frmUser.get('phoneNumber').errors?.required"> Please enter a phone number </mat-error>
        <mat-error *ngIf="frmUser.get('phoneNumber').hasError('pattern')"> Please enter a valid phone number </mat-error>
      </mat-form-field>

      <mat-form-field appearance="standard" style="width: 100%">
        <mat-label>Cell</mat-label>
        <input type="text" matInput formControlName="mobileNumber" placeholder="+1-XXX-XXX-XXXX" pattern="([+]?\d{1,2}[.-\s]?)?(\([0-9]{3}\)[.-]?|\d{3}[.-]?){2}\d{4}" />
        <mat-error *ngIf="frmUser.get('mobileNumber').errors?.required"> Please enter a cell number </mat-error>
        <mat-error *ngIf="frmUser.get('mobileNumber').hasError('pattern')"> Please enter a valid cell number </mat-error>
      </mat-form-field>
    </form>
  </div>
  <div class="action-buttons">
    <button mat-stroked-button color="accent" (click)="editUser()" cdkFocusInitial *ngIf="!isEditable"><mat-icon color="accent" matPrefix>edit</mat-icon> Edit</button>
    <button mat-flat-button color="accent" (click)="saveUser()" cdkFocusInitial *ngIf="isEditable">
      Save Changes
    </button>
    <button mat-stroked-button color="accent" (click)="reset()" *ngIf="isEditable">Cancel</button>
  </div>
</div>
