import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {OidcCallbackComponent} from './components/oidc-callback/oidc-callback.component';
import {OidcCallbackSignoutComponent} from './components/oidc-callback-signout/oidc-callback-signout.component';
import {OidcSilentRenewComponent} from './components/oidc-silent-renew/oidc-silent-renew.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {path: 'oidc-callback', component: OidcCallbackComponent},
      {path: 'oidc-callback-signout', component: OidcCallbackSignoutComponent},
      {path: 'oidc-silent-renew', component: OidcSilentRenewComponent},
    ])
  ],
  exports: [RouterModule]
})
export class AuthRoutingModule {
}
