import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ApplyConfig, ThemeApplied } from '../state/actions/configuration.actions';
import { ConfigService } from '../../shared/services/config.service';
import { Theme } from "../models/theme";
import { UtilityHubConfiguration } from "../models/utilityhub-configuration";
import { AppState } from '@app/state/app.state';
import { configurationSelector } from '@app/state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class UtilityHubConfigService {
  constructor(private httpClient: HttpClient, private configService: ConfigService, private store: Store<AppState>) {}

  public getConfiguration(): Subscription {
    return this.httpClient.get<UtilityHubConfiguration>(this.configService.getApiPath('utilityhub/configuration')).subscribe((config) => {
      if (config === null) {
        return;
      }
      this.store.dispatch(new ApplyConfig(config));
    });
  }

  public applyConfiguration(): void {
    // Apply theming and any other global configuration.
    this.store.select(configurationSelector).subscribe((config) => {
      if (config) {
        this.applyTheme(config.configuration.theme);
      }
    });
  }

  public applyTheme(theme: Theme) {
    // Set CSS properties from theme.
    if(theme != null && theme.Colours)
    {
      Object.entries(theme.Colours).forEach((k) => {
        document.documentElement.style.setProperty(`--${k[0]}`, k[1]);
      });
      document.querySelector('#appLogo').setAttribute('src', theme.LogoUrl);

      this.store.dispatch(new ThemeApplied())
    }
  }
}
