<div class="container main-content">
  <div class="flex">
    <h2>Documents</h2>
    <span class="flex-fill"></span>
    <button mat-flat-button color="accent" *ngIf="userIsSupplierManagerOrSalesManager"  (click)="uploadDocument()">
      <img src="/assets/icons/btn_icon_upload.svg" class="prefix" /> Upload Document
    </button>
  </div>
</div>
<table id="documentTable" mat-table [dataSource]="this.trainingDocuments" class="noselect tenders-grid">
  <ng-container matColumnDef="fileId">
    <th mat-header-cell *matHeaderCellDef>Document ID</th>
    <td mat-cell *matCellDef="let documentRequest">
      {{ documentRequest.fileId }}
    </td>
  </ng-container>
  <ng-container matColumnDef="documentDisplayName">
    <th mat-header-cell *matHeaderCellDef>DOCUMENT NAME</th>
    <td mat-cell style="width:800px" *matCellDef="let documentRequest">
      <a class="download-link" appDownloadLink [file]="documentRequest">{{ documentRequest.documentDisplayName }}</a>

    </td>
  </ng-container>
  <ng-container matColumnDef="contentType">
    <th mat-header-cell *matHeaderCellDef>DOCUMENT TYPE</th>
    <td mat-cell *matCellDef="let documentRequest">
      {{ documentRequest.contentType | uppercase}}
    </td>
  </ng-container>
  <ng-container matColumnDef="fileSize">
    <th mat-header-cell *matHeaderCellDef>FILE SIZE<span class="not-upper"> (MB)</span></th>
    <td mat-cell *matCellDef="let documentRequest">
      {{ documentRequest.fileSize | number: '0.0' }}
    </td>
  </ng-container>
  <ng-container matColumnDef="supplierName">
    <th mat-header-cell *matHeaderCellDef>SUPPLIER NAME</th>
    <td mat-cell *matCellDef="let documentRequest">
      {{ documentRequest.supplierName }}
    </td>
  </ng-container>
  <ng-container matColumnDef="createdBy">
    <th mat-header-cell *matHeaderCellDef>UPLOADED BY</th>
    <td mat-cell *matCellDef="let documentRequest">
      {{ documentRequest.createdBy }}
    </td>
  </ng-container>
  <ng-container matColumnDef="uploadedOn">
    <th mat-header-cell *matHeaderCellDef>Created</th>
    <td mat-cell *matCellDef="let documentRequest">
      {{ documentRequest.uploadedOn | date: dateFormat }}
    </td>
  </ng-container>
  <ng-container matColumnDef="modifiedOn">
    <th mat-header-cell *matHeaderCellDef>Last Updated</th>
    <td mat-cell *matCellDef="let documentRequest">
      {{ documentRequest.modifiedOn | date: dateFormat }}
    </td>
  </ng-container>
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let documentRequest">
      <div class="action-buttons" *ngIf="userIsSupplierManagerOrSalesManager">
        <button id="editBtn"  mat-flat-button color="accent" type="button" (click)="uploadDocument(this.documentRequest)">
          <img class="prefix" src="/assets/icons/icon-edit.svg" style="padding-right:6px;"  /> Edit
        </button>
        <app-delete-file [file]="documentRequest" (after-delete)="loadDocumentList()" [showConfirmation]="true" title="DELETE DOCUMENT"></app-delete-file>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let element; columns: displayedColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[20, 50, 100, 150]"
               [pageIndex]="pageIndex"
               [pageSize]="pageSize"
               [length]="length"
               (page)="loadDocumentList()"></mat-paginator>
