import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityRateCode } from '../models/utility-rate-code';
import { UtilityHubUtilityInformation, UtilityInformation } from '../models/utility-information';
import { WeatherInformation } from '../models/weather-information';
import { ConfigService } from './config.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CommodityType } from '../enums/commodity-type';
import { QuoteRequestState } from '..';
import { map } from 'rxjs/operators';
import { UtilityDetails } from '../models/customer-details';

@Injectable({
  providedIn: 'root',
})
export class UtilityInformationService {
  constructor(private configService: ConfigService, private httpClient: HttpClient) {}

  public getUtilityRateCodes(quoteRequestId: number): Observable<UtilityRateCode[]> {
    const path = this.configService.getApiPath(`utility-information/utility-rate-codes/${quoteRequestId}`);
    return this.httpClient.get<UtilityRateCode[]>(path);
  }

  public getUtilityRateCodesByCriteria(
    state: string,
    commodity: CommodityType,
    utilityDetails: UtilityDetails
  ): Observable<UtilityRateCode[]> {
    const path = this.configService.getApiPath(`utility-information/utility-rate-codes-by-criteria`);
    return this.httpClient.post<UtilityRateCode[]>(path, { state, commodity, utilityDetails });
  }

  public getWeatherInformation(zone: string, quoteRequestId: number): Observable<WeatherInformation> {
    quoteRequestId = quoteRequestId ?? 0;
    const path = this.configService.getApiPath(`utility-information/weather-information/${zone}/${quoteRequestId}`);
    return this.httpClient.get<WeatherInformation>(path);
  }

  public getUtilityInformation(state: string, quoteRequestType: string, commodity: CommodityType, zipCode: string) {
    const path = this.configService.getApiPath(
      `utility-information/${state}/${quoteRequestType.replace(/\s+/g, '')}/${commodity}/${zipCode}`
    );
    return this.httpClient.get<UtilityInformation[]>(path);
  }

  public getUtilityInformationByShortName(utilityShortName: string) {
    const path = this.configService.getApiPath(`utility-information/shortname/${utilityShortName}`);
    return this.httpClient.get<UtilityHubUtilityInformation[]>(path);
  }

  public getRateCodeFromLoadProfile(loadProfile: string, utilityId: string): Observable<any> {
    const path = this.configService.getApiPath(`utility-information/utility-ratecode-from-loadprofile/${loadProfile}/${utilityId}`);
    var headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    var res = this.httpClient.get<any>(path, { headers, responseType: 'text' as 'json' });
    return res;
  }

  /**
   * Gets the utility information about the current utility that we are quoting for.
   *
   * @param quoteRequestState
   * @returns Observable containing a single utility account
   */
  public getUtilityInformationForQuote(quoteRequestState: QuoteRequestState): Observable<UtilityInformation> {
    return this.getUtilityInformation(
      `${quoteRequestState.state}`, //TODO: investigate why this is numeric but returning string from api
      quoteRequestState.quoteRequestType,
      quoteRequestState.commodity,
      quoteRequestState.zipCode
    ).pipe(map((utilities) => utilities.find((util) => util.utilityShort === quoteRequestState.utilityShort)));
  }
}
