<app-side-bar-layout title="SETTINGS">
  <section navigation>
    <a [routerLink]="['./']" routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
      <div style="height:20px"></div>
      <i inlineSVG="/assets/icons/icon_settings_account.svg" class="icon-parts"></i>
      <div style="height:40px">My Account</div>
    </a>
    <!--commented out for now as per requirements-->
    <!--<a [routerLink]="['notifications']" routerLinkActive="active">
      <div style="height:20px"></div>
      <i inlineSVG="/assets/icons/icon_settings_notifications.svg"></i>
      <div style="height:40px">Notifications</div>
    </a>-->
    <a *ngIf="isSupplierManagerOrSalesManager" [routerLink]="['setting']" routerLinkActive="active">
      <div style="height:20px"></div>
      <i inlineSVG="/assets/icons/icon-quote-subtraction.svg"></i>
      <div style="height:40px">Quote Settings</div>
    </a>
  </section>
  <section main>
    <router-outlet></router-outlet>
  </section>
</app-side-bar-layout>
