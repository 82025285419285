import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { Theme } from "@configuration/models/theme";
import { UtilityHubConfigService } from '@configuration/services/utilityhub-config.service';
import { QuoteRejectReasonDialogComponent } from '@app/matrix/components/quote-reject-reason-dialog/quote-reject-reason-dialog.component';
import { QuotePriceStatus } from '@app/quoting/models/quote';
import { ShareQuote } from '@app/quoting/models/ShareQuote/ShareQuote';
import { ShareQuotePrice } from '@app/quoting/models/ShareQuote/ShareQuotePrice';
import { ShareQuoteService } from '@app/quoting/services/share-quote.service';
import { ConfirmComponent } from '@app/shared/components/confirm/confirm.component';
import { QuoteRequestStatus } from '@app/shared/models/quote-request-status';
import { of, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Quote } from '@app/quoting/models/quote';
import { AppState } from '@app/state/app.state';
import { utilityUOMSelector } from '@utility-information/state/selectors/utility-information.selectors';
import { QuotationDeactivationCheckerService } from '@app/shared/services/quotation-deactivation-checker/quotation-deactivation-checker.service';
import { UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';
import { QuoteRequestState } from '@app/shared/models/quote-request-state';
import { QuoteRequestService } from '@app/shared/services/quote-request.service';
import { QuoteStateHelperService } from '@app/shared/services/quote-state-helper.service';


@Component({
  selector: 'app-share-quote',
  templateUrl: './share-quote.component.html',
  styleUrls: ['./share-quote.component.scss'],
  providers: [QuotationDeactivationCheckerService]
})
export class ShareQuoteComponent implements OnInit {
  public quoteStateType = QuoteRequestStatus;
  public quoteRequestId: number;
  public quoteRequestState: QuoteRequestState;
  public quotePriceStatus = QuotePriceStatus;
  public quote: Quote;
  public quoteRequestType = QuoteRequestType;

  loading: boolean = true;
  shareQuote: ShareQuote;
  rejectDialog$: Subscription;
  sendOfferDialog$: Subscription;
  route$: Subscription;
  selectQuote$: Subscription;
  utilityUOM$: any;
  public user: UtilityHubIdentity;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private shareQuoteService: ShareQuoteService,
    private quoteRequestService: QuoteRequestService,
    private utilityHubConfigService: UtilityHubConfigService,
    private store: Store<AppState>,
    public quotationDeactivationCheckerService: QuotationDeactivationCheckerService,
    public quoteStateHelperService: QuoteStateHelperService
  ) {
    this.utilityUOM$ = this.store.select(utilityUOMSelector);
  }

  async ngOnInit(): Promise<void> {
    const routeParams = await this.route.snapshot.params;
    var quoteRequestId = routeParams.quoteRequestId;
    var quoteShareGuid = routeParams.QuoteShareGuid;

    this.quoteRequestState = await this.quoteRequestService.getQuoteRequestState(quoteRequestId).toPromise();

    this.shareQuote = await this.shareQuoteService.incrementVisitCount(quoteRequestId, quoteShareGuid).then((x) => {
      var copy = JSON.parse(JSON.stringify(x));
      this.utilityHubConfigService.applyTheme(new Theme({ logoOnLight: copy.styling.logoOnLight, cssVars: copy.styling.cssVars }));
      return x;
    });
    this.loading = false;
  }

  /**
   * Returns true if the shared quote has not been either acepted or rejected.
   *
   * @returns boolean
   */
  quotePendingAction(): boolean {
    
    return (
      (this.shareQuote.quoteRequestStatus == QuoteRequestStatus.InProgress ||
        this.shareQuote.quoteRequestStatus == QuoteRequestStatus.QuoteRequestApproved ||
        this.shareQuote.quoteRequestStatus == QuoteRequestStatus.QuoteGenerated) &&
      this.shareQuote.prices.every((price) => price.status == QuotePriceStatus.Generated || price.status == QuotePriceStatus.UnderReview)
    );
  }

  public hasExpired() {
    return of(this.shareQuote.quoteRequestStatus == QuoteRequestStatus.QuoteExpired);
  }

  public acceptOffer(price: ShareQuotePrice) {
    const sendOfferDialog = this.dialog.open(ConfirmComponent, {
      width: '40%',
      data: {
        title: 'Accept Offer',
        message: 'This action cannot be undone',
        primaryButtonText: 'Confirm',
      },
    });

    this.sendOfferDialog$ = sendOfferDialog.afterClosed().subscribe(async (result) => {
      if (result) {
        this.shareQuote = await this.shareQuoteService.acceptPrice(
          this.shareQuote.quoteRequestId,
          this.shareQuote.guid,
          price.term,
          price.planType
        );
      }
    });
  }

  rejectAllOffers() {
    const rejectDialog = this.dialog.open(QuoteRejectReasonDialogComponent, {
      width: '40%',
      data: {
        title: 'Offer Rejection Reason',
        maxCharacters: 255,
        quoteRequestId: this.shareQuote.quoteRequestId,
        rejectionAction: async (rejectionMessage: any) => {
          return await this.shareQuoteService.rejectPrice(this.shareQuote.quoteRequestId, this.shareQuote.guid, rejectionMessage);
        },
      },
    });

    this.rejectDialog$ = rejectDialog.afterClosed().subscribe((result) => {
      if (result.confirmed) {
        this.shareQuote = result.response;
      }
    });
  }

  getStatusIcon(offer: ShareQuotePrice): string {
    switch (offer.status) {
      case QuotePriceStatus.OfferRejected || QuotePriceStatus.Expired:
        return 'cancel';
      case QuotePriceStatus.OfferAccepted:
        return 'check_circle';
    }
  }

  greyedOutClass(status: string) {
    if (status == QuotePriceStatus.OfferRejected) return of('rejected');

    return of('');
  }

  matChipClass(status: string) {
    if (status == QuotePriceStatus.OfferRejected) return of('mat-chip-rejected');

    return of('mat-chip-accent');
  }

  getStatusStyle(offer: ShareQuotePrice): string {
    if (offer.status == QuotePriceStatus.OfferAccepted) {
      return 'accent';
    }

    return 'primary';
  }
  
  public getQuoteExpirationDisclaimer() {
    let doesQuoteHavePrices = this.shareQuote.prices?.length > 0;
    if (this.isQuotationDisabledForTheDay && this.shareQuote.productConfiguration.configuration.quoteExpirationDisclaimerEnabled && doesQuoteHavePrices) {
      return this.shareQuote.productConfiguration.configuration.quoteGenerationDisclaimerProcess;
    }
    return "";
  }

  public isQuotationDisabledForTheDay() {
    if (this.shareQuote.productConfiguration.configuration.quoteValidityNumberOfDays == 0) {
      let now = new Date();
      return now > new Date(`${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()} ${this.shareQuote.productConfiguration.configuration.quoteExpiryIntervalTimeSpan}`);
    }
    return false;
  }

}
