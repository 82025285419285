import { Directive, HostListener, HostBinding, EventEmitter, Output, Input } from '@angular/core';
import { UdFile } from '../UdFile';

@Directive({
  selector: '[appDnd]',
})
export class DndDirective {
  @Input() private allowed_extensions: Array<string> = [];
  @Output() private filesChangeEmitter: EventEmitter<Set<UdFile>> = new EventEmitter();
  @Output() private filesInvalidEmitter: EventEmitter<Set<UdFile>> = new EventEmitter();
  @HostBinding('style.background') private background = '';

  constructor() {}

  @HostListener('dragover', ['$event'])
  public onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = 'rgba(163,213,238,0.39)';
  }

  @HostListener('dragleave', ['$event'])
  public onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
  }

  @HostListener('drop', ['$event'])
  public onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.background = '';
    let files = evt.dataTransfer.files;
    let valid_files: Set<UdFile> = new Set<UdFile>();
    let invalid_files: Set<UdFile> = new Set<UdFile>();
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        let ext = file.name.split('.')[file.name.split('.').length - 1];
        if (this.allowed_extensions.length < 1) {
          valid_files.add(file);
        } else {
          if (this.allowed_extensions.lastIndexOf(ext) !== -1) {
            valid_files.add(file);
          } else {
            invalid_files.add(file);
          }
        }
      }
      this.filesChangeEmitter.emit(valid_files);
      this.filesInvalidEmitter.emit(invalid_files);
    }
  }
}
