import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsSubject, Action, Store } from '@ngrx/store';
import { AuthActionTypes } from './auth/state/actions/auth.actions';
import { VersionService } from './shared/services/version.service';
import { FetchUtilityHubConfig, ConfigurationTypes, FetchTableauWorkbooks } from './configuration/state/actions/configuration.actions';
import { UtilityHubConfiguration } from "./configuration/models/utilityhub-configuration";
import { Subscription } from 'rxjs';
import { UserRole, HasRole } from '@auth/models/utility-hub-identity';
import { ConfigService } from '@app/shared/services/config.service';
import { Logger, SentryTransport } from './logging.service';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
import LogRocket from 'logrocket';
import { environment } from '../environments/environment';
import { isDynamicSelector, isCustomJourneyEnabledSelector, isMatrixJourneyEnabledSelector, isQuickQuoteJoruneyEnabledSelector, utilityhubConfigurationSelector } from '@utility-information/state/selectors/utility-information.selectors';
import { AppState } from './state/app.state';
import { loggedInUserSelector, loggedInUtilityHubIdentitySelector, tableauWorkbooksSelector } from './state/app.selectors';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public title = 'Sales360 Portal';
  public status: string;
  public version: string;
  public userIsSupplierManagerOrSalesManager: boolean;
  public supplierPricingRequestsUri: string;
  public loggedIn: boolean;
  public themeApplied = false;
  public userRole: string;
  public reportsLength: number;
  customJourneyEnabled: boolean;
  matrixJourneyEnabled: boolean;
  quickQuoteJourneyEnabled: boolean;
  powwrEnabled: boolean;
  selectLoggedInUserSubscription$: Subscription;
  getVersionSubscription$: Subscription;
  utilityHubConfiguration: UtilityHubConfiguration;
  fetchTableauWorkbooksSubscription$: Subscription;

  reportEnabled: boolean;
  displayReports: boolean;

  constructor(
    private actionSubject$: ActionsSubject,
    private store: Store<AppState>,
    private versionService: VersionService,
    private configService: ConfigService,
  ) {
    this.selectLoggedInUserSubscription$ = this.store.select(loggedInUserSelector).subscribe((user) => {
      if (user && !user.expired) {
        this.store.dispatch(new FetchUtilityHubConfig());
      } else if (window.location.href.indexOf('oidc-callback-signout?') > 0) {
        // TODO: Need to remove this logic from here and pull the auth related components out into their own router outlet
        this.themeApplied = true;
      }
    });

    this.store.select(loggedInUtilityHubIdentitySelector).subscribe((uhUser) => {
      if (uhUser) {
        this.loggedIn = true;
        this.userRole = uhUser.currentOrganisation.role;
        this.userIsSupplierManagerOrSalesManager = uhUser.isSupplierManagerOrSalesManager;
        Logger.Log.setContext({ user: uhUser });
        const organisationId = uhUser.currentOrganisation.id;
        this.store.dispatch(new FetchTableauWorkbooks(organisationId));
      } else {
        this.loggedIn = false;
        this.themeApplied = true;
      }
    });

    this.configService.configLoaded.subscribe((_) => {});

    this.fetchTableauWorkbooksSubscription$ = this.store.select(tableauWorkbooksSelector).subscribe((workbooks) => {
      if(!workbooks) return;
      this.reportEnabled = workbooks.length > 0;
      this.displayReports = this.reportEnabled && (!(this.userRole == UserRole.Broker || this.userRole == UserRole.Supplier));
    });
  }

  ngOnDestroy(): void {
    this.selectLoggedInUserSubscription$?.unsubscribe();
    this.getVersionSubscription$?.unsubscribe();
    this.fetchTableauWorkbooksSubscription$.unsubscribe();
  }

  ngOnInit() {
    if (environment.production) {
      this.init3rdPartyMonitoring();
    }

    this.actionSubject$.subscribe((action: Action) => {
      const actionsToApplyTheme = [ConfigurationTypes.ThemeApplied, AuthActionTypes.Logout];

      if (actionsToApplyTheme.some((themeAction) => action.type === themeAction)) {
        this.themeApplied = true;
      }
    });

    this.supplierPricingRequestsUri = this.configService.config.endpoints.supplierManagerPortal;
    this.store.select(isDynamicSelector).subscribe(d => this.powwrEnabled = d);
    this.store.select(isCustomJourneyEnabledSelector).subscribe(c => this.customJourneyEnabled = c);
    this.store.select(isMatrixJourneyEnabledSelector).subscribe(m => this.matrixJourneyEnabled = m);
    this.store.select(isQuickQuoteJoruneyEnabledSelector).subscribe(q => this.quickQuoteJourneyEnabled = q);
    this.store.select(utilityhubConfigurationSelector).subscribe(uh => this.utilityHubConfiguration = uh);

    this.getVersionSubscription$ = this.versionService
      .getVersion()
      .subscribe((v) => (this.version = `${v.env} ${v.version.split('+')[0]}`));
  }

  init3rdPartyMonitoring() {
    // LogRocket init
    LogRocket.init(this.configService.config.logRocketId, { release: this.configService.config.version });

    // Sentry init
    Sentry.init({
      // Move to env config
      dsn: this.configService.config.sentry.dns,
      environment: this.configService.config.environment,
      release: this.configService.config.version,
      integrations: [
        new Integrations.BrowserTracing({
          tracingOrigins: [
            'localhost',
            'http://localhost:4400',
            'https://ci-energypricing-wa.azurewebsites.net',
            'https://qa-energypricing-wa.azurewebsites.net',
            'https://sbx-energypricing-wa.azurewebsites.net',
            'https://prd-energypricing-wa.azurewebsites.net',
            'https://portal.powwr.com',
            'https://demo-energypricing-wa-use2.azurewebsites.net',
            'https://demo-portal.powwr.com'
          ],
          routingInstrumentation: Sentry.routingInstrumentation,
        }),
      ],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: this.configService.config.sentry.tracesSampleRate,
    });

    LogRocket.getSessionURL((sessionURL) => {
      Sentry.configureScope((scope) => {
        scope.setExtra('sessionURL', sessionURL);
      });
    });

    Logger.Log.addTransport(new SentryTransport());
  }
}
