import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { DateConstants } from '@app/constants/date-constants';
import { CommodityType } from '@app/shared/enums/commodity-type';
import { QuoteRequestIndexItem } from '@app/quote-index/models/quote-request-index-item.model';
import { QuoteRequestIndexState } from '@app/quote-index/state/quote-index.state';
import { UserRole, UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { Store } from '@ngrx/store';
import { distinct, State } from '@progress/kendo-data-query';
import { GridDataResult, DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { QuoteRequestStatus } from '../../../shared/models/quote-request-status';
import { CustomService } from '../../../custom/services/custom.service';
import { MatrixService } from '../../../matrix/services/matrix.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigService } from '../../../shared/services/config.service';
import { DatePipe } from '@angular/common';
import { QuoteGridService } from '@app/quote-index/services/quote-grid.service';
import { AppState } from '@app/state/app.state';
import { isDynamicSelector } from '@utility-information/state/selectors/utility-information.selectors';
import { SetCommodityType } from '@utility-information/state/actions/utility-information.actions';
import { loggedInUtilityHubIdentitySelector } from '@app/state/app.selectors';
import { QuoteRequestsIndexService } from '@app/quote-index/services/quote-index.service';
import { Filter } from '@app/shared/models/filter';
import { QuickQuoteService } from '@app/quick-quote/services/quick-quote.service';

@Component({
  selector: 'app-quote-request-grid',
  templateUrl: './quote-grid.component.html',
  styleUrls: ['./quote-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteRequestGridComponent implements OnInit {
  public Commodity = CommodityType;
  public dateFormat = DateConstants.LOCALE_FORMAT_SHORT();
  public organisationId: string;
  public powwrEnabled: boolean;
  public userIdentity: UtilityHubIdentity;
  public QuoteTypeValues = [{ name: 'Matrix', id: 'Matrix' }, { name: 'Custom', id: 'Custom' }];
  public statsFilterValue: string[] = [];
  public activeFilters: Filter;

  public quoteStatuses =
    [
      { name: 'In Progress', id: 'InProgress' },
      { name: 'Offer Accepted', id: 'QuoteAccepted' },
      { name: 'Offer Rejected', id: 'QuoteRejected' },
      { name: 'Quote Sent', id: 'QuoteRequestApproved' },
      { name: 'Quote Expired', id: 'QuoteExpired' },
      { name: 'Quote Generated', id: 'QuoteGenerated' },
      { name: 'Quote Requested', id: 'QuoteRequested',
        filterValues:
        [
          QuoteRequestStatus.QuoteRequested,
          QuoteRequestStatus.QuoteExpiredUnderReview
        ]
      },
      { name: 'Quote Enrolled', id: 'QuoteEnrolled',
        filterValues:
        [
          QuoteRequestStatus.PartiallyEnrolled,
          QuoteRequestStatus.Enrolled,
          QuoteRequestStatus.SubmissionSuccessful,
          QuoteRequestStatus.SubmissionFailed,
          QuoteRequestStatus.PartiallySubmitted,
          QuoteRequestStatus.ContractLive,
          QuoteRequestStatus.ContractExpiring,
          QuoteRequestStatus.ContractExpired,
          QuoteRequestStatus.ContractDropPending,
          QuoteRequestStatus.ContractDropped
        ]
      },
      { name: 'Request Rejected', id: 'QuoteRequestRejected' },
      { name: 'Contract Rejected', id: 'DocuSignRejected' },
      { name: 'DocuSign Completed', id: 'DocuSignCompleted' },
      { name: 'Enrollment Pending', id: 'PendingEnrollment' },
      { name: 'Partially Enrolled', id: 'PartiallyEnrolled' },
      { name: 'Enrolled', id: 'Enrolled' },
      { name: 'Submission Failed', id: 'SubmissionFailed' },
      { name: 'Submission Successful', id: 'SubmissionSuccessful' },
      { name: 'Partially Submitted', id: 'PartiallySubmitted' },
      { name: 'Contract Live', id: 'ContractLive' },
      { name: 'Contract Expiring', id: 'ContractExpiring' },
      { name: 'Contract Expired', id: 'ContractExpired' },
      { name: 'Contract Drop Pending', id: 'ContractDropPending' },
      { name: 'Contract Dropped', id: 'ContractDropped' },
      { name: 'Contract Pending', id: 'PendingContract',
        filterValues:
        [
          QuoteRequestStatus.PendingContract,
          QuoteRequestStatus.ContractSigned
        ]
      },
      { name: 'Api Call Failed', id: 'ApiCallFailed' },
      { name: 'Refresh Requested', id: 'RefreshRequested' },
      { name: 'Credit Approved', id: 'CreditApproved' },
      { name: 'Credit Failed', id:'CreditFailed'},
      { name: 'Credit Review Required', id:'CreditReviewRequired'},
      { name: 'Approval Required', id: 'ApprovalRequired' },
      { name: 'Usage Requested', id: 'UsageRequested' },
      { name: 'Usage Received', id: 'UsageReceived' },
      { name: 'Usage Uploaded', id: 'UsageUploaded' },
      { name: 'Usage Required', id: 'UsageRequired' },
    ]

  public cells: any = {
    // format: this.dateFormat, - include if we need other type of date format.
    textAlign: 'left',
  };

  public gridState: State = {
    skip: 0,
    take: 20,
    sort: [
      {
        field: 'QuoteRequestId',
        dir: 'desc',
      },
    ],
    filter: {
      filters: [],
      logic: 'and',
    },
  };
  public gridView: GridDataResult;

  constructor(
    private store: Store<AppState>,
    public quoteRequestIndexState: QuoteRequestIndexState,
    public configService: ConfigService,
    private customService: CustomService,
    private matrixService: MatrixService,
    private router: Router,
    private route: ActivatedRoute,
    public datepipe: DatePipe,
    public quoteGridService: QuoteGridService,
    public quoteRequestsIndexService: QuoteRequestsIndexService,
    private quickQuoteService: QuickQuoteService,
  ) {
    this.store.select(loggedInUtilityHubIdentitySelector).subscribe((user) => {
      this.userIdentity = user;
      this.organisationId = user.currentOrganisation.id;
      this.getUhConfiguration();
    });
  }

  public getBrokerColumnVisibility(): boolean {
    return (
      this.userIdentity.isSupplierManagerOrSalesManager ||
      this.userIdentity.currentOrganisation.role === UserRole.BrokerManager ||
      this.userIdentity.currentOrganisation.role === UserRole.Supplier
    );
  }

  public getBrokerageColumnVisibility(): boolean {
    return (
      this.userIdentity.isSupplierManagerOrSalesManager ||
      this.userIdentity.currentOrganisation.role === UserRole.Supplier
    );
  }

  public getCreatedByColumnVisibility(): boolean {
    return (
      this.userIdentity.isSupplierManagerOrSalesManager
    );
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const statsFilterValue = [params.key];
      const field = params.field ?? 'QuoteRequestStatus';
      const filters = (typeof(params.type) == 'undefined' || params.type == 'string')
                                ? this.quoteGridService.generateFilterConditions(statsFilterValue, this.quoteStatuses, field)
                                : this.quoteGridService.generateFilterConditionsDate(statsFilterValue, field);
      if (filters.length <= 0 )
      {
        this.clearFilters();
      }
      else{
        this.gridState.filter.filters = [
          {
            filters,
            logic: (filters.length > 1) ? 'or' : 'and'
          }
        ]
      }
    });

    this.quoteRequestIndexState.setActiveFilter({ searchText: '', request: this.gridState });
    this.quoteRequestIndexState.getActiveFilters$().subscribe((filters) => {
      this.activeFilters = filters;
      this.quoteRequestIndexState.loadQuoteRequests(filters);
    });

    this.quoteRequestIndexState.getQuoteRequests$().subscribe((data) => {
      if (data) {
        this.gridView = data;
      }
    });
  }

  clearFilters() {
    this.gridState.filter = {
      logic: 'and',
      filters: [],
    };
    this.quoteRequestIndexState.setActiveFilter({ request: this.gridState });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
    if (state.sort[0].dir) state.sort[0].dir = 'desc';
    const filter = this.quoteRequestIndexState.getActiveFilters();
    this.quoteRequestIndexState.setActiveFilter({
      searchText: filter.searchText,
      request: state,
    });
  }

  public save(component): void {
    const filtersCopy: Filter = JSON.parse(JSON.stringify(this.activeFilters));
    filtersCopy.request.take = this.gridView.total;

    this.quoteRequestsIndexService.getQuoteRequests(filtersCopy).subscribe((quoteRequests) => {
      component.data = quoteRequests.data;

      const options = component.workbookOptions();
      const rows = options.sheets[0].rows;
      var quoteStatusCellIndex = rows[0].cells.length - 1;
      var lastUpdatedCellIndex = rows[0].cells.length - 2;
      var createdDateCellIndex = rows[0].cells.length - 3;
      let altIdx = 0;
      rows.forEach((row) => {
        if (row.type === 'data') {
          row.cells[quoteStatusCellIndex].value = this.quoteStatuses.find(
            (element) => element.id === row.cells[quoteStatusCellIndex].value
          )?.name;
          row.cells[lastUpdatedCellIndex].value = this.datepipe.transform(row.cells[lastUpdatedCellIndex].value, this.dateFormat);
          row.cells[createdDateCellIndex].value = this.datepipe.transform(row.cells[createdDateCellIndex].value, this.dateFormat);
          if (altIdx % 2 !== 0) {
            row.cells.forEach((cell) => {
              cell.background = '#aabbcc';
            });
          }
          altIdx++;
        }
      });
      component.save(options);
    });
  }

  getUhConfiguration() {
    this.store.select(isDynamicSelector).subscribe(d => this.powwrEnabled = d);
  }

  public distinctPrimitive(fieldName: string): any {
    console.log(fieldName);
    return distinct(this.gridView.data, fieldName).map((item) => item[fieldName]);
  }

  resolveButtonStyle(requests: QuoteRequestIndexItem[]): QuoteRequestIndexItem[] {
    return requests.map((quoteRequest) => {
      const buttonData = this.resolveSelectQuoteRequestButton(quoteRequest);
      quoteRequest.iconSrc = buttonData.iconSrc;
      quoteRequest.label = buttonData.label;
      return quoteRequest;
    });
  }

  public navigateToSelectedQuoteRequest(quoteRequest: QuoteRequestIndexItem): void {
    this.store.dispatch(new SetCommodityType(quoteRequest.quoteRequestId.toString(), <CommodityType>quoteRequest.commodity));
    const actionRoute: (vi: any) => void = (vi) => {
      const firstInvalidStep = vi.filter((x) => !x.valid || x.error)[0];
      const step = firstInvalidStep ? firstInvalidStep : vi.filter((x) => x.valid).slice(-1)[0];
      const route = ['../', quoteRequest.quoteType.toLowerCase().replace(' ', ''), quoteRequest.quoteRequestId.toString(), step.slug];
      if(quoteRequest.quoteType.replace(' ', '')=='Custom'){
        this.router.navigate(route,{queryParams : {commodityType: quoteRequest.commodity}});
      }
      else {
        this.router.navigate(route);
      }
    };

    switch (quoteRequest.quoteType.replace(' ', '')) {
      case 'QuickQuote':
        this.quickQuoteService.getServerStepsValidation(quoteRequest.quoteRequestId).subscribe((vi) => {
          actionRoute(vi);
        });
        break;
      case 'Matrix':

        if (quoteRequest.quoteRequestStatus.startsWith('Credit') && quoteRequest.quoteRequestStatus !== QuoteRequestStatus.CreditApproved)
        {
          const getQuoteRouter = ['../', quoteRequest.quoteType.toLowerCase().replace(' ', ''), quoteRequest.quoteRequestId.toString(), 'contract-details'];
          this.router.navigate(getQuoteRouter);
        }
        else if (quoteRequest.quoteRequestStatus === QuoteRequestStatus.QuoteRejected)
        {
          const getQuoteRouter = ['../', quoteRequest.quoteType.toLowerCase().replace(' ', ''), quoteRequest.quoteRequestId.toString(), 'get-quote'];
          this.router.navigate(getQuoteRouter);
        }
        else {
          this.matrixService.getServerStepsValidation(quoteRequest.quoteRequestId).subscribe((vi) => {
            actionRoute(vi);
          });
        }
        break;
      case 'Custom':
        if (quoteRequest.quoteRequestStatus.startsWith('Credit') && quoteRequest.quoteRequestStatus !== QuoteRequestStatus.CreditApproved)
        {
          const getQuoteRouter = ['../', quoteRequest.quoteType.toLowerCase().replace(' ', ''), quoteRequest.quoteRequestId.toString(), 'contract-details'];
          this.router.navigate(getQuoteRouter,{queryParams : {commodityType: quoteRequest.commodity}});
        }
        else if (quoteRequest.quoteRequestStatus === QuoteRequestStatus.QuoteRejected)
        {
          const getQuoteRouter = ['../', quoteRequest.quoteType.toLowerCase().replace(' ', ''), quoteRequest.quoteRequestId.toString(), 'get-quote'];
          this.router.navigate(getQuoteRouter,{queryParams : {commodityType: quoteRequest.commodity}});
        }
        else {
          this.customService.getServerStepsValidation(quoteRequest.quoteRequestId, null, 'custom').subscribe((vi) => {
            actionRoute(vi);
          });
        }
        break;
    }
  }

  public resolveSelectQuoteRequestButton(quoteRequest: QuoteRequestIndexItem) {
    switch (quoteRequest.quoteRequestStatus) {
      case QuoteRequestStatus.QuoteRejected:
        return {
          label: 'View',
          iconSrc: '/assets/icons/icon-view.svg',
        };
      case QuoteRequestStatus.QuoteRequestApproved:
        if (this.userIdentity.isSupplierManagerOrSalesManager) {
          return {
            label: 'View',
            iconSrc: '/assets/icons/icon-view.svg',
          };
        } else {
          return {
            label: 'Review',
            iconSrc: '/assets/icons/icon-review.svg',
          };
        }
      case QuoteRequestStatus.QuoteRequested:
      case QuoteRequestStatus.QuoteExpiredUnderReview:
        if (this.userIdentity.isSupplierManagerOrSalesManager) {
          return {
            label: 'Review',
            iconSrc: '/assets/icons/icon-review.svg',
          };
        } else {
          return {
            label: 'View',
            iconSrc: '/assets/icons/icon-view.svg',
          };
        }
      case QuoteRequestStatus.QuoteExpired:
        return {
          label: 'Requote',
          iconSrc: '/assets/icons/icon-redo.svg',
        };
      case QuoteRequestStatus.QuoteRequestRejected: {
        if (this.userIdentity.isSupplierManagerOrSalesManager) {
          return {
            label: 'View',
            iconSrc: '/assets/icons/icon-view.svg',
          };
        } else {
          return {
            label: 'View',
            iconSrc: '/assets/icons/icon-view.svg',
          };
        }
      }
      case QuoteRequestStatus.QuoteGenerated:
        if (this.userIdentity.isSupplierManagerOrSalesManager) {
          return {
            label: 'View',
            iconSrc: '/assets/icons/icon-view.svg',
          };
        } else {
          return {
            label: 'Review',
            iconSrc: '/assets/icons/icon-review.svg',
          };
        }
      default:
        return {
          label: 'View',
          iconSrc: '/assets/icons/icon-view.svg',
        };
    }
  }
}
