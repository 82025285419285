import { Router } from '@angular/router';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { QuoteRequestService } from '@app/shared/services/quote-request.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-quote-reject-reason-dialog',
  templateUrl: './quote-reject-reason-dialog.component.html',
  styleUrls: ['./quote-reject-reason-dialog.component.scss'],
})
export class QuoteRejectReasonDialogComponent implements OnInit, OnDestroy {
  public quoteRejectionReason: string;
  public quoteRejectionForm: FormGroup;
  public maxCharacters = 1000;
  public minCharacters = 10;

  private quoteRequestId: string;
  rejectOffer$: Subscription;

  constructor(
    public quoteService: QuoteRequestService,
    public dialogRef: MatDialogRef<QuoteRejectReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private router: Router
  ) {
    if (data.maxCharacters) {
      this.maxCharacters = data.maxCharacters;
    }

    this.quoteRequestId = data.quoteRequestId;
  }

  ngOnDestroy(): void {
    this.rejectOffer$?.unsubscribe();
  }

  ngOnInit() {
    this.quoteRejectionForm = this.fb.group({
      quoteRejectionReason: new FormControl(''),
      quoteFeedback: new FormControl('', Validators.maxLength(255)),
      whereDidWeRank: new FormControl(''),
    });
  }

  public async send() {
    this.quoteRejectionForm.updateValueAndValidity();

    if (this.quoteRejectionForm.valid) {
      const quoteRejection = {
        quoteRequestId: this.quoteRequestId,
        reason: this.quoteRejectionForm.value.quoteRejectionReason,
        whereDidWeRank: this.quoteRejectionForm.value.whereDidWeRank,
        quoteFeedback: this.quoteRejectionForm.value.quoteFeedback,
      };

      if (this.data.rejectionAction) {
        const response = await (this.data.rejectionAction)(quoteRejection);
        this.dialogRef.close({ confirmed: true , response: response});

        return;
      }

      this.rejectOffer$ = this.quoteService.rejectOffer(quoteRejection).subscribe(() => {
        this.dialogRef.close({ confirmed: true });
        this.router.navigateByUrl(this.router.url.replace('get-quote','youre-done'))
      });
    }
  }
}
