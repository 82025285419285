export class QuoteTypes {
  matrixJourneyEnabled: boolean;
  customJourneyEnabled: boolean;
  quickQuoteJourneyEnabled: boolean;
  quickQuoteJourneyRequireLoadProfile: boolean;

  constructor({ matrixJourneyEnabled, customJourneyEnabled, quickQuoteJourneyEnabled, quickQuoteJourneyRequireLoadProfile }) {
    this.matrixJourneyEnabled = matrixJourneyEnabled;
    this.customJourneyEnabled = customJourneyEnabled;
    this.quickQuoteJourneyEnabled = quickQuoteJourneyEnabled;
    this.quickQuoteJourneyRequireLoadProfile = quickQuoteJourneyRequireLoadProfile;
  }
}
