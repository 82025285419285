import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortAlphabetically'
})
export class SortAlphabetically implements PipeTransform {
  transform(data: any[], propertyName?: string): any[] {
    if (!data || !Array.isArray(data)) {
      return data;
    }

    if (propertyName) {
      return this.sortArrayOfObjects(data, propertyName);
    } else {
      return this.sortArrayOfStrings(data);
    }
  }

  private sortArrayOfObjects(data: any[], propertyName: string): any[] {
    return data.slice().sort((a, b) => {
      const valueA = String(a[propertyName]).toLowerCase();
      const valueB = String(b[propertyName]).toLowerCase();
      return valueA.localeCompare(valueB);
    });
  }

  private sortArrayOfStrings(data: string[]): string[] {
    return data.slice().sort((a, b) => {
      const valueA = String(a).toLowerCase();
      const valueB = String(b).toLowerCase();
      return valueA.localeCompare(valueB);
    });
  }
}
