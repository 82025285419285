import { QuoteRequestStatus } from "@app/shared/models/quote-request-status";
import { HasRole, UserRole } from "@auth/models/utility-hub-identity";
import { QuoteState, QuotePriceStatus } from "@quoting/models/quote";
import { QuotingModuleState, quotingModuleInitialState } from "../quoting-module.state";

export const selectQuote = (state: QuotingModuleState) => {
    // Reduce state based on statuses
    let quoteState: QuoteState = QuoteState.NOT_QUOTED;
    let isSupplierManagerOrSalesManager = HasRole(state.userViewingQuote, UserRole.SupplierManager) || HasRole(state.userViewingQuote, UserRole.SalesManager);
    
    if (state.quote) {
      switch (state.quote.quoteRequestState?.status) {
        case QuoteRequestStatus.ApiCallFailed: {
          quoteState = QuoteState.NO_RESULT;
          break;
        }
        case QuoteRequestStatus.PendingContract: {
          quoteState = QuoteState.CONTRACT_PENDING;
          break;
        }
        case QuoteRequestStatus.PendingEnrollment: {
          quoteState = QuoteState.PENDING_ENROLLMENT;
          break;
        }
        case QuoteRequestStatus.DocuSignRejected: {
          quoteState = QuoteState.CONTRACT_REJECTED;
          break;
        }
        case QuoteRequestStatus.Enrolled: {
          quoteState = QuoteState.ENROLLED;
          break;
        }
        case QuoteRequestStatus.InProgress:
          if (state.hasIDontKnows === true) {
            quoteState = QuoteState.HAS_I_DONT_KNOWS;
          }
          break;
        case QuoteRequestStatus.QuoteGenerated:
          quoteState = QuoteState.GENERATED;
          if (state.quote.prices.length === 0) {
            quoteState = QuoteState.NO_RESULT;
          }
          break;
        case QuoteRequestStatus.QuoteRequested:
        case QuoteRequestStatus.RefreshRequested:        
          quoteState =
            isSupplierManagerOrSalesManager &&
            state.userViewingQuote.id !== state.quote.quoteRequestState?.ownerUserId
              ? QuoteState.APPROVAL_REQUIRED
              : QuoteState.REQUESTED;
          if (
            state.quote.prices.length === 0 &&
            state.hasIDontKnows !== true &&
            (HasRole(state.userViewingQuote, UserRole.BrokerManager) || HasRole(state.userViewingQuote, UserRole.Broker))
          ) {
            quoteState = QuoteState.BROKER_REQUESTED;
            break;
          }
          if (isSupplierManagerOrSalesManager && state.hasIDontKnows) {
            quoteState = QuoteState.BROKER_REQUESTED_UNQUOTED;
          }
          if (
            isSupplierManagerOrSalesManager &&
            !state.hasIDontKnows &&
            state.quote.prices.length === 0 &&
            state.userViewingQuote.id !== state.quote.quoteRequestState?.ownerUserId
          ) {
            quoteState = QuoteState.INVALIDATED;
          }
          if (
            isSupplierManagerOrSalesManager &&
            !state.hasIDontKnows &&
            state.quote.prices.length === 0 &&
            state.userViewingQuote.id === state.quote.quoteRequestState?.ownerUserId
          ) {
            quoteState = QuoteState.BROKER_REQUESTED;
          }
          break;
        case QuoteRequestStatus.QuoteExpired:
          quoteState = QuoteState.EXPIRED;
          break;
        case QuoteRequestStatus.QuoteExpiredUnderReview:
          quoteState = isSupplierManagerOrSalesManager ? QuoteState.EXPIRED : QuoteState.REQUESTED;
          if (
            state.quote.prices.length === 0 &&
            (HasRole(state.userViewingQuote, UserRole.BrokerManager) || HasRole(state.userViewingQuote, UserRole.Broker)) &&
            state.hasIDontKnows
          ) {
            quoteState = QuoteState.HAS_I_DONT_KNOWS;
          }
          break;
        case QuoteRequestStatus.QuoteRequestRejected:
          quoteState = isSupplierManagerOrSalesManager ? QuoteState.APPROVAL_REJECTED : QuoteState.REQUEST_REJECTED;
          break;
        case QuoteRequestStatus.QuoteRequestApproved:
          quoteState = isSupplierManagerOrSalesManager ? QuoteState.APPROVAL_ACCEPTED : QuoteState.REQUEST_APPROVED;
          break;
        case QuoteRequestStatus.QuoteAccepted:
          quoteState = QuoteState.OFFER_ACCEPTED;
          break;
        case QuoteRequestStatus.QuoteRejected:
          quoteState = QuoteState.OFFER_REJECTED;
          break;
        case QuoteRequestStatus.QuoteRequestComplete:
          quoteState = resolveQuoteAcceptedOrRejected(state);
          break;
        case QuoteRequestStatus.ApprovalRequired:
          quoteState = QuoteState.APPROVAL_REQUIRED;
          break;
          case QuoteRequestStatus.PartiallyEnrolled:
          quoteState = QuoteState.PARTIALLY_ENROLLED;
          break;
        case QuoteRequestStatus.ContractLive:
          quoteState = QuoteState.CONTRACT_LIVE;
          break;
        case QuoteRequestStatus.ContractExpiring:
          quoteState = QuoteState.CONTRACT_EXPIRING;
          break;
        case QuoteRequestStatus.ContractDropPending:
          quoteState = QuoteState.CONTRACT_DROP_PENDING;
          break;
        case QuoteRequestStatus.ContractDropped:
          quoteState = QuoteState.CONTRACT_DROPPED;
          break;
        case QuoteRequestStatus.SubmissionSuccessful:
          quoteState = QuoteState.SUBMISSION_SUCCESSFUL;
          break;
        case QuoteRequestStatus.SubmissionFailed:
          quoteState = QuoteState.SUBMISSION_FAILED;
          break;
        case QuoteRequestStatus.PartiallySubmitted:
          quoteState = QuoteState.PARTIALLY_SUBMITTED;
          break;
        default:
          quoteState = QuoteState.NOT_QUOTED;
          break;
      }
  
      if (state.quote.error) {
        quoteState = QuoteState.ERROR;
      }
  
      return { ...state, quote: { ...state.quote, quoteState } };
    }
  
    return quotingModuleInitialState;
  };
  
  const resolveQuoteAcceptedOrRejected = (state: QuotingModuleState): QuoteState => {
    return state.quote.prices.some((o) => o.status === QuotePriceStatus.OfferAccepted)
      ? QuoteState.OFFER_ACCEPTED
      : QuoteState.OFFER_REJECTED;
  };