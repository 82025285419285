import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { QuoteIndexComponent } from './pages/quote-index.component';
import { InlineSVGModule } from 'ng-inline-svg';

const routes: Routes = [
  { path: '', component: QuoteIndexComponent },
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    InlineSVGModule
  ],
  exports: [RouterModule]
})
export class QuoteIndexRoutingModule { }
