<div>
  <div class="dialog-heading">
    <h5 mat-dialog-title>Switch Organization</h5>
    <span style="flex: 1 1 auto"></span>
    <mat-icon class="close-dialog" aria-hidden="false" mat-dialog-close aria-label="Close icon">close</mat-icon>
  </div>
  <!-- This is the content of the dialog, containing a list of the files to upload -->
  <mat-dialog-content>
    <mat-form-field style="width: 100%">
      <mat-label>Search</mat-label>
      <input matInput type="text" (keyup)="search($event)" />
      <mat-icon matSuffix>search</mat-icon>
    </mat-form-field>

    <mat-selection-list #organisations [multiple]="false" style="height: 400px">
      <mat-list-option *ngFor="let org of organisationList" [value]="org">
        {{ org.name }} - {{ roleAsNiceString(org.role) }}
      </mat-list-option>
    </mat-selection-list>
  </mat-dialog-content>

  <!-- This are the actions of the dialog, containing the primary and the cancel button-->
  <mat-dialog-actions class="actions">
    <button
      mat-flat-button
      color="accent"
      [mat-dialog-close]="organisations.selectedOptions.selected[0]?.value"
      [disabled]="!organisations.selectedOptions.hasValue()"
    >
      Ok
    </button>
    <button mat-stroked-button color="accent" mat-dialog-close>Cancel</button>
  </mat-dialog-actions>
</div>
