import { removeDuplicateObjects } from '@app/shared/helpers/array-helper';
import { ContractLength } from '@app/shared/models/contract-length';
import {
  UtilityInformationActions,
  UtilityInformtationActionTypes as UtilityInformationActionTypes,
} from '../actions/utility-information.actions';
import { initialUtilityInformationState, UtilityInformationState } from '../utility-information.state';

export function utilityInformationReducer(
  state: UtilityInformationState = initialUtilityInformationState,
  action: UtilityInformationActions
): UtilityInformationState {
  switch (action.type) {
    case UtilityInformationActionTypes.SetUtilityUOM:
      return { ...state, utilityUOM: action.utilityUOM };
    case UtilityInformationActionTypes.SetBrokerageName:
      return { ...state, brokerageName: action.brokerageName };
    case UtilityInformationActionTypes.SetCustomerType:
      return { ...state, customerType: action.customerType };
    case UtilityInformationActionTypes.SetCustomerTypeSize:
      return { ...state, customerTypeSize: action.customerTypeSize };
    case UtilityInformationActionTypes.SetAddress:
      return { ...state, zipCode: action.zipCode, state: action.state };
    case UtilityInformationActionTypes.SetZipCode:
      return { ...state, zipCode: action.zipCode };
    case UtilityInformationActionTypes.SetCommodityType:
      return { ...state, commodityType: action.commodityType };
    case UtilityInformationActionTypes.SetUtility:
      return { ...state, utility: action.utility };
    case UtilityInformationActionTypes.LoadedUtilityInformation:
      return { ...state, utilityInformation: action.utilityInformation };
    case UtilityInformationActionTypes.SupplierUtilityInformation:
      return { ...state, supplierUtilityInformation: action.supplierUtilityInformation };
    case UtilityInformationActionTypes.LoadedConsumptionData:
      return { ...state, consumptionData: action.consumptionData };
    case UtilityInformationActionTypes.ClearUtilityInformation:
      return { ...state, utilityInformation: undefined };
    case UtilityInformationActionTypes.ClearConsumptionData:
      return { ...state, consumptionData: undefined };
    case UtilityInformationActionTypes.ClearUtility:
      return { ...state, utility: undefined };
    case UtilityInformationActionTypes.ClearUIState:
      return initialUtilityInformationState;
    case UtilityInformationActionTypes.SetSweetSpotWasRequested:
      return { ...state, sweetSpotWasRequested: action.sweetSpotWasRequested };
    case UtilityInformationActionTypes.SetContractLengths_Selected:
      return { ...state, contractLengths_Selected: action.contractLengths };
    case UtilityInformationActionTypes.SetProductTermsFromProductType:
      return { ...state, contractLengths: action.productTerms.map(Number) };
    case UtilityInformationActionTypes.SetProductType:
      return { ...state, productType: action.productType };
    case UtilityInformationActionTypes.SetSweetSpotFilterValue:
      return { ...state, showSweetSpotFilter: action.showSweetSpotFilter };
    case UtilityInformationActionTypes.SetSweetSpotCheckBoxState:
      return { ...state, sweetSpotCheckBoxState: action.sweetSpotCheckBoxState };
    case UtilityInformationActionTypes.SetQuoteRequestType:
      return { ...state, quoteRequestType: action.quoteRequestType };
    case UtilityInformationActionTypes.SetSalesType:
      return { ...state, salesType: action.salesType };
    case UtilityInformationActionTypes.SetPageName:
      return { ...state, pageName: action.pageName };
    case UtilityInformationActionTypes.SetMarginDollar:
      return { ...state, marginDollar: action.marginDollar };
    case UtilityInformationActionTypes.SetAgentFee:
      return { ...state, agentFee: action.agentFee };
    case UtilityInformationActionTypes.SetMarginTerm:
      return { ...state, marginTerm: action.marginTerm };
    case UtilityInformationActionTypes.SetGetQuoteContractLengthStartingButtons:
      return { ...state, getQuoteContractLengthStartingButtons: action.startingButtons };
    case UtilityInformationActionTypes.SetPricesFilters:
      return {
        ...state,
        productTypeFilters: action.productTypes,
        contractLengths_Selected: removeDuplicateObjects(
          action.contractLengths?.map((x) => ({ months: x, selected: true } as ContractLength)).sort((n1, n2) => n1.months - n2.months),
          'months'
        ),
        showSweetSpotFilter: action.showSweetSpot,
      };
    case UtilityInformationActionTypes.SetUsageSources:
      return { ...state, usageSources: action.usageSourceMaps };
    case UtilityInformationActionTypes.SetBillingType:
      return { ...state, selectedBillingType: action.billingType };
    case UtilityInformationActionTypes.SetIsDisplayReportsEnabled:
      return { ...state, isDisplayReportsEnabled: action.isDisplayReportsEnabled };
    case UtilityInformationActionTypes.SetShowShareQuoteButton:
      return { ...state, showShareQuoteButton: action.showShareQuoteButton };
    default:
      return state;
  }
}
