import { Injectable } from '@angular/core';
import { ReportsService } from '@app/dashboard/services/reports.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { UtilityHubConfigService } from '../../services/utilityhub-config.service';
import { ApplyConfig, ConfigurationTypes, FetchTableauWorkbooks, FetchTableauWorkbooksFailure, FetchTableauWorkbooksSuccess, FetchUtilityHubConfig } from '../actions/configuration.actions';

@Injectable()
export class UtilityHubConfigurationEffects {

  fetch$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<FetchUtilityHubConfig>(ConfigurationTypes.FetchUtilityHubConfig),
        tap(() => {
          return this.utilityHubConfigService.getConfiguration();
        })
      ),
    { dispatch: false }
  );

  applyConfig$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<ApplyConfig>(ConfigurationTypes.ApplyConfig),
        tap(() => {
          // Apply our config here / apply theming
          this.utilityHubConfigService.applyConfiguration();
        })
      ),
    { dispatch: false }
  );

  fetchTableauWorkbooks$ = createEffect(
    ({ debounce = 0 } = {}) =>
      this.actions$.pipe(
        ofType<FetchTableauWorkbooks>(ConfigurationTypes.FetchTableauWorkbooks),
        debounceTime(debounce),
        switchMap(({ organisationId }) =>
          this.reportsService.getTableauWorkbooks(organisationId).pipe(
            map(workbooks => new FetchTableauWorkbooksSuccess(workbooks)),
            catchError(error => of(new FetchTableauWorkbooksFailure(error)))
          )
        )
      )
  );

  constructor(private actions$: Actions, private utilityHubConfigService: UtilityHubConfigService, private reportsService: ReportsService) {}
}
