import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared/shared.module';
import { MaterialModule } from '@app/app.material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { SettingsLayoutComponent } from './components/layout/settings-layout.component';
import { RouterModule } from '@angular/router';
import { SettingsMyaccountLayoutComponent } from './pages/myaccount/settings-myaccount.component';
import { SettingsNotificationsComponent } from './pages/notifications/settings-notifications.component';
import { SettingsRoutingModule } from './settings-routing.module';
import { JsonFormsModule } from '@jsonforms/angular';
import { JsonFormsAngularMaterialModule } from '@jsonforms/angular-material';
import { CheckboxGroupControlRenderer } from './pages/notifications/CustomControls/checkbox-button-controls';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SettingQuoteComponent } from './pages/quote-setting/quote-settings.component';

@NgModule({
  declarations: [
    SettingsLayoutComponent,
    SettingsMyaccountLayoutComponent,
    SettingsNotificationsComponent,
    CheckboxGroupControlRenderer,
    SettingQuoteComponent
],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    SettingsRoutingModule,
    JsonFormsModule,
    JsonFormsAngularMaterialModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [

  ]
})
export class SettingsModule { }
