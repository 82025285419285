import { Injectable } from '@angular/core';
import { Logger } from '@app/logging.service';
import { Filter } from '@app/shared/models/filter';
import { BehaviorSubject, Observable } from 'rxjs';
import { QuoteRequestsIndexService } from '../services/quote-index.service';
import { GridDataResult } from '@progress/kendo-angular-grid';

@Injectable({
  providedIn: 'root',
})
export class QuoteRequestIndexState {
  private activeFilter$ = new BehaviorSubject<Filter>(null);
  private loading$ = new BehaviorSubject<boolean>(false);
  private quoteRequests$ = new BehaviorSubject<GridDataResult>(null);

  constructor(private quoteRequestIndexService: QuoteRequestsIndexService) { }

  public setActiveFilter(filter: Filter) {
    const currentFilter = this.activeFilter$.getValue();
    this.activeFilter$.next({ ...currentFilter, ...filter });
  }

  public loadQuoteRequests(filters: Filter): void {
    this.loading$.next(true);
    this.quoteRequestIndexService.getQuoteRequests(filters).subscribe(
      (ua) => {
        this.quoteRequests$.next(ua);
      },
      (err) => Logger.Log.error(err),
      () => this.loading$.next(false)
    );
  }

  public getQuoteRequests$(): Observable<GridDataResult> {
    return this.quoteRequests$.asObservable();
  }

  public getActiveFilters$(): Observable<Filter> {
    return this.activeFilter$.asObservable();
  }

  public getActiveFilters(): Filter {
    return this.activeFilter$.getValue();
  }

  public isLoading$(): Observable<boolean> {
    return this.loading$.asObservable();
  }
}
