<div id="app-container" [style]="themeApplied == true ? '' : 'display: none'">
  <div id="content-wrap">
    <header>
      <div class="top-bar">
        <app-user-account-actions [loggedIn]="loggedIn"></app-user-account-actions>
      </div>
      <div class="nav-area">
        <h1>
          <a class="logo" [routerLink]="['/']">
            <img id="appLogo" src="/assets/logos/powwr-logo.svg" /> |&nbsp;&nbsp;&nbsp; <span>Sales<b>360°</b> Portal</span>
          </a>
        </h1>
        <span class="flex-fill"></span>
        <nav id="navigationLinks" *ngIf="loggedIn">
          <ul>
            <li id="dashboardBtn" class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
              <a class="nav-link text-dark" [routerLink]="['/']">Dashboard</a>
            </li>
            <li
              *ngIf="userIsSupplierManagerOrSalesManager"
              id="dashboardBtn"
              class="nav-item"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a class="nav-link text-dark" [routerLink]="['/utility-accounts']">Utility Accounts</a>
            </li>
            <li id="quotesBtn" class="nav-item" [routerLinkActive]="['link-active']" [routerLinkActiveOptions]="{ exact: true }">
              <a class="nav-link text-dark" [routerLink]="['./quotes']">Quotes</a>
            </li>
            <li
              *ngIf="displayReports"
              id="dashboardBtn"
              class="nav-item"
              [routerLinkActive]="['link-active']"
              [routerLinkActiveOptions]="{ exact: true }"
            >
              <a class="nav-link text-dark" [routerLink]="['/reports']">Reports</a>
            </li>
            <li>
              <button
                *ngIf="this.quickQuoteJourneyEnabled"
                mat-stroked-button
                color="accent"
                [routerLink]="['quickquote/new/quote-configuration']"
                class="nav-link"
              >
                <i class="buttonIcon" inlineSVG="/assets/icons/icon_quick-quote.svg"></i> Quick Quote
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </header>
    <router-outlet></router-outlet>
  </div>
  <div class="footer">
    <div *ngIf="this.loggedIn" class="footer-links footer-item"><a href="https://www.powwr.com/privacy-policy">Privacy Policy</a></div>
    <div *ngIf="!this.loggedIn" class="footer-links footer-item">
      <a href="https://www.powwr.com/">Advanced Software Solutions by POWWR®</a>
    </div>
    <div class="footer-item"></div>
    <div class="version footer-item">Version: {{ version }}</div>
  </div>
</div>
