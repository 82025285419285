import { AdderType } from '@app/shared/enums/adder-type';
import { CommodityType } from '@app/shared/enums/commodity-type';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';
import { SupplierPricingAdderModel } from '@app/shared/models/utility-information';

let newSupplierPricingAdder: SupplierPricingAdderModel[] = [];

export function processQuoteSettingData(
  values: any,
  userId: string,
  electricityAllowCustom: boolean,
  electricityAllowMatrix: boolean,
  gasAllowCustom: boolean,
  gasAllowMatrix: boolean
): SupplierPricingAdderModel[] {
  if (!values.setAdditionalCostMatrix && !values.setAdditionalCostCustom) {
    electricityAllowCustomFunc(electricityAllowCustom, values, userId);
    electricityAllowMatrixFunc(electricityAllowMatrix, values, userId);
    gasAllowMatrixFunc(gasAllowMatrix, values, userId);
    gasAllowCustomFunc(gasAllowCustom, values, userId);
  } else if (values.setAdditionalCostMatrix) {
    if (electricityAllowMatrix) {
      let supplierAdder: SupplierPricingAdderModel = new SupplierPricingAdderModel();

      supplierAdder.quoteType = QuoteRequestType.Matrix;
      supplierAdder.commodityType = CommodityType.Electricity;
      supplierAdder.adderType = AdderType.overall;
      supplierAdder.adderMillRate = values?.matrixAdditionalCostOverall;
      supplierAdder.userId = userId;
      newSupplierPricingAdder.push(supplierAdder);

      let supplierAdderCustom: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderCustom.quoteType = QuoteRequestType.Custom;
      supplierAdderCustom.commodityType = CommodityType.Electricity;
      supplierAdderCustom.adderType = AdderType.overall;
      supplierAdderCustom.adderMillRate = values?.customAdditionalCostOverall;
      supplierAdderCustom.userId = userId;
      newSupplierPricingAdder.push(supplierAdderCustom);

      let supplierAdderERCOT: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderERCOT.quoteType = QuoteRequestType.Matrix;
      supplierAdderERCOT.commodityType = CommodityType.Electricity;
      supplierAdderERCOT.adderType = AdderType.ercot;
      supplierAdderERCOT.adderMillRate = values?.matrixAdditionalCostErcot;
      supplierAdderERCOT.userId = userId;
      newSupplierPricingAdder.push(supplierAdderERCOT);

      let supplierAdderISONE: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderISONE.quoteType = QuoteRequestType.Matrix;
      supplierAdderISONE.commodityType = CommodityType.Electricity;
      supplierAdderISONE.adderType = AdderType.isone;
      supplierAdderISONE.adderMillRate = values?.matrixAdditionalCostIsone;
      supplierAdderISONE.userId = userId;
      newSupplierPricingAdder.push(supplierAdderISONE);

      let supplierAdderMISO: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderMISO.quoteType = QuoteRequestType.Matrix;
      supplierAdderMISO.commodityType = CommodityType.Electricity;
      supplierAdderMISO.adderType = AdderType.miso;
      supplierAdderMISO.adderMillRate = values?.matrixAdditionalCostMiso;
      supplierAdderMISO.userId = userId;
      newSupplierPricingAdder.push(supplierAdderMISO);

      let supplierAdderNYISO: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderNYISO.quoteType = QuoteRequestType.Matrix;
      supplierAdderNYISO.commodityType = CommodityType.Electricity;
      supplierAdderNYISO.adderType = AdderType.nyiso;
      supplierAdderNYISO.adderMillRate = values?.matrixAdditionalCostNyiso;
      supplierAdderNYISO.userId = userId;
      newSupplierPricingAdder.push(supplierAdderNYISO);

      let supplierAdderPJM: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderPJM.quoteType = QuoteRequestType.Matrix;
      supplierAdderPJM.commodityType = CommodityType.Electricity;
      supplierAdderPJM.adderType = AdderType.pjm;
      supplierAdderPJM.adderMillRate = values?.matrixAdditionalCostPjm;
      supplierAdderPJM.userId = userId;
      newSupplierPricingAdder.push(supplierAdderPJM);

      if (!values.setAdditionalCostCustom) {
        gasAllowMatrixFunc(gasAllowMatrix, values, userId);
        gasAllowCustomFunc(gasAllowCustom, values, userId);
      }
    }
  }
  if (electricityAllowCustom) {
    if (values.setAdditionalCostCustom) {
      let supplierAdder: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdder.quoteType = QuoteRequestType.Custom;
      supplierAdder.commodityType = CommodityType.Electricity;
      supplierAdder.adderType = AdderType.ercot;
      supplierAdder.adderMillRate = values?.customAdditionalCostErcot;
      supplierAdder.userId = userId;
      newSupplierPricingAdder.push(supplierAdder);

      let supplierAdderISONE: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderISONE.quoteType = QuoteRequestType.Custom;
      supplierAdderISONE.commodityType = CommodityType.Electricity;
      supplierAdderISONE.adderType = AdderType.isone;
      supplierAdderISONE.adderMillRate = values?.customAdditionalCostIsone;
      supplierAdderISONE.userId = userId;
      newSupplierPricingAdder.push(supplierAdderISONE);

      let supplierAdderMISO: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderMISO.quoteType = QuoteRequestType.Custom;
      supplierAdderMISO.commodityType = CommodityType.Electricity;
      supplierAdderMISO.adderType = AdderType.miso;
      supplierAdderMISO.adderMillRate = values?.customAdditionalCostMiso;
      supplierAdderMISO.userId = userId;
      newSupplierPricingAdder.push(supplierAdderMISO);

      let supplierAdderNYISO: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderNYISO.quoteType = QuoteRequestType.Custom;
      supplierAdderNYISO.commodityType = CommodityType.Electricity;
      supplierAdderNYISO.adderType = AdderType.nyiso;
      supplierAdderNYISO.adderMillRate = values?.customAdditionalCostNyiso;
      supplierAdderNYISO.userId = userId;
      newSupplierPricingAdder.push(supplierAdderNYISO);

      let supplierAdderPJM: SupplierPricingAdderModel = new SupplierPricingAdderModel();
      supplierAdderPJM.quoteType = QuoteRequestType.Custom;
      supplierAdderPJM.commodityType = CommodityType.Electricity;
      supplierAdderPJM.adderType = AdderType.pjm;
      supplierAdderPJM.adderMillRate = values?.customAdditionalCostPjm;
      supplierAdderPJM.userId = userId;
      newSupplierPricingAdder.push(supplierAdderPJM);

      gasAllowMatrixFunc(gasAllowMatrix, values, userId);
      gasAllowCustomFunc(gasAllowCustom, values, userId);
      electricityAllowCustomFunc(electricityAllowCustom, values, userId);
      electricityAllowMatrixFunc(electricityAllowMatrix, values, userId);
    }
  }
  return newSupplierPricingAdder;
}

function gasAllowMatrixFunc(gasAllowMatrix: boolean, values: any, userId) {
  if (gasAllowMatrix) {
    let supplierAdderGasOverall: SupplierPricingAdderModel = new SupplierPricingAdderModel();
    supplierAdderGasOverall.quoteType = QuoteRequestType.Matrix;
    supplierAdderGasOverall.commodityType = CommodityType.Gas;
    supplierAdderGasOverall.adderType = AdderType.overall;
    supplierAdderGasOverall.adderMillRate = values?.gasmatrixAdditionalCost;
    supplierAdderGasOverall.userId = userId;
    newSupplierPricingAdder.push(supplierAdderGasOverall);
  }
}

function gasAllowCustomFunc(gasAllowCustom: boolean, values: any, userId) {
  if (gasAllowCustom) {
    let supplierAdderGasOverallCustom: SupplierPricingAdderModel = new SupplierPricingAdderModel();
    supplierAdderGasOverallCustom.quoteType = QuoteRequestType.Custom;
    supplierAdderGasOverallCustom.commodityType = CommodityType.Gas;
    supplierAdderGasOverallCustom.adderType = AdderType.overall;
    supplierAdderGasOverallCustom.adderMillRate = values?.gascustomAdditionalCost;
    supplierAdderGasOverallCustom.userId = userId;
    newSupplierPricingAdder.push(supplierAdderGasOverallCustom);
  }
}

function electricityAllowCustomFunc(electricityAllowCustom: boolean, values: any, userId) {
  if (electricityAllowCustom) {
    let supplierAdderCustom: SupplierPricingAdderModel = new SupplierPricingAdderModel();
    supplierAdderCustom.quoteType = QuoteRequestType.Custom;
    supplierAdderCustom.commodityType = CommodityType.Electricity;
    supplierAdderCustom.adderType = AdderType.overall;
    supplierAdderCustom.adderMillRate = values?.customAdditionalCostOverall;
    supplierAdderCustom.userId = userId;
    newSupplierPricingAdder.push(supplierAdderCustom);
  }
}
function electricityAllowMatrixFunc(electricityAllowMatrix: boolean, values: any, userId) {
  if (electricityAllowMatrix) {
    let supplierAdderOverall: SupplierPricingAdderModel = new SupplierPricingAdderModel();
    supplierAdderOverall.quoteType = QuoteRequestType.Matrix;
    supplierAdderOverall.commodityType = CommodityType.Electricity;
    supplierAdderOverall.adderType = AdderType.overall;
    supplierAdderOverall.adderMillRate = values?.matrixAdditionalCostOverall;
    supplierAdderOverall.userId = userId;
    newSupplierPricingAdder.push(supplierAdderOverall);
  }
}
