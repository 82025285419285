/* tslint:disable:no-input-rename no-output-rename */
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UdFile } from '../../UdFile';
import { UploadProgressDialogComponent } from '../upload-progress-dialog/upload-progress-dialog.component';
import { ConfirmComponent } from '../../../shared/components/confirm/confirm.component';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';
import { isReadOnlySelector } from '@app/state/app.selectors';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnDestroy, OnInit {
  waitForConfirmation$: Subscription;
  dialogClosed$: Subscription;
  errorDialogClosed$: Subscription;
  isReadOnly$: Subscription;

  constructor(public dialog: MatDialog, private store: Store<AppState>) {}

  ngOnInit(): void {
    this.isReadOnly$ = this.store.select(isReadOnlySelector).subscribe((isReadOnly) => {
      this.disabled = isReadOnly;
    });
  }

  ngOnDestroy(): void {
    this.waitForConfirmation$?.unsubscribe();
    this.dialogClosed$?.unsubscribe();
    this.errorDialogClosed$?.unsubscribe();
    this.isReadOnly$?.unsubscribe();
  }

  @Input('status-message') public statusMessage: string;
  @Input('upload-description') public uploadDescription = 'Drag & Drop Files Here';
  @Input('disabled') disabled: boolean;
  @Input('multiple') multiple = true;
  @Input() ignoreUnsupportedFileDialog = false;
  @Input() fileTypes: string[] = [
    'bmp',
    'csv',
    'doc',
    'docx',
    'jpg',
    'jpeg',
    'pdf',
    'png',
    'gif',
    'tiff',
    'tif',
    'xls',
    'xlsx',
    'txt',
    'rtf',
  ];
  @Input('upload-url') uploadUrl = 'file/upload';
  @Input('document-type') documentType = 'General';
  @Input() additionalData: any;
  @Input() waitForConfirmationFunc: () => Observable<boolean>;

  @Output('after-closed') private afterClosed = new EventEmitter();
  @Output('on-error') private onError = new EventEmitter();
  @Output('on-upload-start') private onUploadStart = new EventEmitter();
  @ViewChild('file', { static: false }) file;
  private completedFiles: Array<UdFile>;

  public openUploadDialog(droppedFiles: Array<UdFile> | Set<UdFile>) {
    if (this.waitForConfirmationFunc) {
      this.waitForConfirmation$ = this.waitForConfirmationFunc().subscribe((confirmed) => {
        if (confirmed) {
          this.startUpload(droppedFiles);
        }
      });
    } else {
      this.startUpload(droppedFiles);
    }
  }

  private startUpload(droppedFiles: Array<UdFile> | Set<UdFile>) {
    const files: Array<UdFile> = new Array<UdFile>();
    if (droppedFiles) {
      this.onUploadStart.emit(droppedFiles);
      if (this.multiple) {
        droppedFiles.forEach((x) => files.push(x));
      } else {
        files.push(droppedFiles.values().next().value);
      }
    } else {
      const nativeFiles: { [key: string]: UdFile } = this.file.nativeElement.files;
      this.onUploadStart.emit(nativeFiles);
      for (const key in nativeFiles) {
        if (!isNaN(parseInt(key))) {
          files.push(nativeFiles[key]);
        }
      }
    }
    try {
      this.file.nativeElement.value = null;
    } catch {}

    // Document type
    if (this.documentType) {
      files.forEach((x) => (x.documentType = this.documentType));
    }

    let filteredFiles: UdFile[];
    if (this.fileTypes && this.fileTypes.length > 0) {
      filteredFiles = files.filter((f) => {
        const fileParts = f.name.split('.');
        const extension = fileParts[fileParts.length - 1];
        return this.fileTypes.indexOf(extension) >= 0;
      });
    } else {
      filteredFiles = files;
    }
    if (filteredFiles.length < files.length) {
      if (!this.ignoreUnsupportedFileDialog) {
        const dialogRef = this.dialog.open(ConfirmComponent, {
          width: '100%',
          maxWidth: '500px',
          data: {
            title: 'File Type Not Support',
            message: `Only valid files will be uploaded.`,
            primaryButtonText: 'OK',
            hideCancel: true,
          },
        });

        this.dialogClosed$ = dialogRef.afterClosed().subscribe((confirmed) => {
          this.emitWrongFileTypeError(files, filteredFiles);
          this.uploadFiles(filteredFiles, this.uploadUrl, this.additionalData);
        });
      } else {
        this.emitWrongFileTypeError(files, filteredFiles);
        this.uploadFiles(filteredFiles, this.uploadUrl, this.additionalData);
      }
    } else {
      this.uploadFiles(filteredFiles, this.uploadUrl, this.additionalData);
    }

    // Clear error message after upload.
    // This is only used for when a required document has not been uploaded.
    this.statusMessage = '';
  }

  private uploadFiles(filteredFiles, uploadUrl, additionalData) {
    try {
      this.file.value = null;
    } catch {}
    if (filteredFiles.length > 0) {
      this.completedFiles = new Array<UdFile>();
      const dialogRef = this.dialog.open(UploadProgressDialogComponent, {
        width: '100%',
        maxWidth: '900px',
        hasBackdrop: false,
        data: {
          files: filteredFiles,
          completedFiles: this.completedFiles,
          uploadUrl: uploadUrl ?? 'file/upload',
          additionalData,
        },
      });

      if (this.afterClosed !== undefined) {
        this.errorDialogClosed$ = dialogRef.afterClosed().subscribe((filesWithErrors) => {
          if (filesWithErrors?.length > 0) {
            this.onError.emit(new UploadErrorEvent('Error', 'Errors during file upload', filesWithErrors));
          }

          return this.afterClosed.emit(this.completedFiles);
        });
      }
    }
  }

  private emitWrongFileTypeError(files, filteredFiles) {
    this.onError.emit(
      new UploadErrorEvent(
        'WrongFileType',
        'Not all the files you selected are of the correct file type.',
        files.filter((x) => !filteredFiles.includes(x))
      )
    );
  }
}

export class UploadErrorEvent {
  public message: string;
  public errorCode: string;
  public files: UdFile[];
  public error: Error;

  constructor(errorCode?: string, message?: string, files?: UdFile[], error?: Error) {
    this.errorCode = errorCode;
    this.message = message;
    this.files = files;
    this.error = error;
  }
}
