import { Injectable } from "@angular/core";
import { QuoteRequestIndexState } from "../state/quote-index.state";
import { QuoteRequestDisplayStatus } from "@app/shared/models/quote-request-status";

@Injectable({
    providedIn: 'root',
})
export class QuoteGridService {

    constructor(public quoteRequestIndexState: QuoteRequestIndexState) { }

    public generateFilterConditions(selectedIds: string[], quoteStatuses, field: string) {
        const uniqueFilters = new Set();

        selectedIds?.forEach((id) => {
            if (id) {
                const quoteStatus = quoteStatuses.find((status) => status.id === id);
                if (quoteStatus?.filterValues) {
                    quoteStatus.filterValues.forEach((filter) => {
                        uniqueFilters.add(filter);
                    });
                } else {
                    uniqueFilters.add(quoteStatus?.id);
                }
            }
        });
        
        return [...uniqueFilters].map((value) => ({
            field,
            operator: 'eq',
            value,
        }));
    }

    public generateFilterConditionsDate(selectedIds: string[], field: string) {
        const date = [this.getStartOfTodayDateUTC()];
        
        if (selectedIds[0] === QuoteRequestDisplayStatus.QuoteDueToday) {
            return this.generateFilterGTE(date, field);
        } else if (selectedIds[0] === QuoteRequestDisplayStatus.QuoteDateOverdue) {
            return this.generateFilterLT(date, field);
        }
        return [];
    }

    private generateFilterGTE(selectedDates: Date[], field: string) {
        let dateGTE = new Date(selectedDates[0]);

        let dateFilterEqualTo = [
        {
            field: field,
            operator: 'gte',
            value: dateGTE,
        }];

        return dateFilterEqualTo;
    }

    private generateFilterLT(selectedDates: Date[], field: string) {
        let dateLT = new Date(selectedDates[0]);

        let dateFilterEqualTo = [{
            field,
            operator: 'lt',
            value: dateLT
        }
        ];

        return dateFilterEqualTo;
    }

  private getStartOfTodayDateUTC(): Date {
      let dateToUse = new Date();
      const startOfToday = new Date(Date.UTC(dateToUse.getFullYear(), dateToUse.getMonth(), dateToUse.getDate(), 0, 0, 0, 0));
      return startOfToday;
    }
}
