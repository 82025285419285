import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuoteIndexComponent } from './pages/quote-index.component';
import { QuoteIndexRoutingModule } from './quote-index-routing.module';

import { MaterialModule } from '../app.material.module';
import { InlineSVGModule } from 'ng-inline-svg';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { MatrixService } from '../matrix/services/matrix.service';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatPaginatorModule } from '@angular/material/paginator';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { QuoteRequestGridComponent } from './components/quote-grid/quote-grid.component';
import { MultiCheckFilterComponent } from "./components/mutli-check/multicheck-filter.component";
import { SearchBarComponent } from '../shared/components/search-bar/search-bar.component';
import { ExcelExportModule } from "@progress/kendo-angular-excel-export";
import { ChangeQuickQuoteName } from './pipes/change-quick-quote-name.pipe';
import { SortAlphabetically } from './pipes/sort-alphabetically.pipe';

@NgModule({
  declarations: [QuoteIndexComponent,
    QuoteRequestGridComponent,
    MultiCheckFilterComponent,
    ChangeQuickQuoteName,
    SortAlphabetically
  ],
  imports: [
    CommonModule,
    SharedModule,
    MaterialModule,
    QuoteIndexRoutingModule,
    InlineSVGModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatIconModule,
    MatPaginatorModule,
    GridModule,
    ExcelModule,
    PDFModule,
    ExcelExportModule
  ],
  providers: [MatrixService]
})
export class QuoteIndexModule { }
