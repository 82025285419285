import { BrowserModule } from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './app.material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InlineSVGModule } from 'ng-inline-svg';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { AuthModule } from './auth/auth.module';
import { UtilityHubConfigurationModule } from './configuration/utilityhub-config.module';
import { environment } from '../environments/environment';
import { SharedModule } from '../app/shared/shared.module';
import { MatTableModule } from '@angular/material/table';
import { MatChipsModule } from '@angular/material/chips';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { FileManagementModule } from './filemanagement/file-management.module';
import { QuoteIndexModule } from './quote-index/quote-index.module';
import { TrainingDocumentModule } from './training-document/training-document.module';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { SettingsModule } from './settings/settings.module';
import { GridModule, ExcelModule, PDFModule } from '@progress/kendo-angular-grid';
import { ExcelExportModule } from "@progress/kendo-angular-excel-export";
import { ErrorPagesModule } from './4.0.x/error-pages.module';
import { ShareQuoteComponent } from './share-quote/share-quote.component';
import { UtilityInformationEffects } from '@utility-information/state/effects/utility-information.effects';
import { metaReducers, reducers } from './state/app.reducers';

@NgModule({
  declarations: [AppComponent, ShareQuoteComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MaterialModule,
    MatChipsModule,
    BrowserAnimationsModule,
    InlineSVGModule,
    SharedModule,
    AuthModule,
    UtilityHubConfigurationModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    EffectsModule.forRoot([UtilityInformationEffects]),
    MatTableModule,
    FileManagementModule,
    QuoteIndexModule,
    TrainingDocumentModule,
    SettingsModule,
    GridModule,
    ExcelModule,
    PDFModule,
    ExcelExportModule,
    ErrorPagesModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
