import { ConsumptionThreshold } from "./consumption-threshold";
import { UpliftThreshold } from "./uplift-threshold";
import { ConsumptionDataValidationRule } from "./consumption-data-validation-rule";
import { Theme } from "./theme";
import { QuoteTypes } from "./quote-types";

export class ProductConfiguration {
  public consumptionDataValidationRules: ConsumptionDataValidationRule[];
  public plcLoadFactorPercentage: number;
  public nsplLoadFactorPercentage: number;
  public quoteExpiryIntervalTimeSpan: string;
  public quoteExpiryInterval: string;
  public quoteValidityNumberOfDays: number;
  public allowDifferentContractStartDates: boolean;
  public reportsEnabled: boolean;
  public commissionsEnabled: boolean;
  public matricesEnabled: boolean;
  public insightsEnabled: boolean;
  public customerDataEnabled: boolean;
  public matrixConsumptionThreshold: ConsumptionThreshold;
  public customConsumptionThreshold: ConsumptionThreshold;
  public matrixUpliftThreshold: UpliftThreshold;
  public customUpliftThreshold: UpliftThreshold;
  public theme: Theme;
  public quoteTypes: QuoteTypes;
  public displayReports: boolean;
  public requireLetterOfAuthorityForMeterVault: boolean;
  public dayOfMonthQuotingExpires: number;
  public quoteExpirationDisclaimerEnabled: boolean;
  public quoteGenerationDisclaimerProcessUsesCustomMessage: boolean;
  public quoteGenerationDisclaimerProcess: string;
  public quoteAcceptanceDisclaimer: string;

  constructor({
    consumptionDataValidationRules,
    plcLoadFactorPercentage,
    nsplLoadFactorPercentage,
    quoteExpiryInterval,
    quoteExpiryIntervalTimeSpan,
    quoteValidityNumberOfDays,
    allowDifferentContractStartDates,
    theme,
    matrixConsumptionThreshold,
    customConsumptionThreshold,
    matrixUpliftThreshold,
    customUpliftThreshold,
    reportsEnabled,
    commissionsEnabled,
    matricesEnabled,
    insightsEnabled,
    customerDataEnabled,
    quoteTypes,
    displayReports,
    requireLetterOfAuthorityForMeterVault,
    dayOfMonthQuotingExpires,
    quoteExpirationDisclaimerEnabled,
    quoteGenerationDisclaimerProcessUsesCustomMessage,
    quoteGenerationDisclaimerProcess,
    quoteAcceptanceDisclaimer
  }: {
    consumptionDataValidationRules: ConsumptionDataValidationRule[];
    plcLoadFactorPercentage: number;
    nsplLoadFactorPercentage: number;
    quoteExpiryInterval: string;
    quoteExpiryIntervalTimeSpan: string;
    quoteValidityNumberOfDays: number;
    allowDifferentContractStartDates: boolean;
    matrixConsumptionThreshold: ConsumptionThreshold;
    customConsumptionThreshold: ConsumptionThreshold;
    matrixUpliftThreshold: UpliftThreshold;
    customUpliftThreshold: UpliftThreshold;
    theme: any;
    reportsEnabled: boolean;
    commissionsEnabled: boolean;
    matricesEnabled: boolean;
    insightsEnabled: boolean;
    customerDataEnabled: boolean;
    quoteTypes: any;
      displayReports: boolean;
      requireLetterOfAuthorityForMeterVault: boolean;
      dayOfMonthQuotingExpires: number;
    quoteExpirationDisclaimerEnabled: boolean;
    quoteGenerationDisclaimerProcessUsesCustomMessage: boolean;
    quoteGenerationDisclaimerProcess: string;
    quoteAcceptanceDisclaimer: string;
  }) {
    this.consumptionDataValidationRules = consumptionDataValidationRules;
    this.plcLoadFactorPercentage = plcLoadFactorPercentage;
    this.nsplLoadFactorPercentage = nsplLoadFactorPercentage;
    this.quoteExpiryInterval = quoteExpiryInterval;
    this.quoteExpiryIntervalTimeSpan = quoteExpiryIntervalTimeSpan;
    this.quoteValidityNumberOfDays = quoteValidityNumberOfDays;
    this.allowDifferentContractStartDates = allowDifferentContractStartDates ?? false;
    this.matrixConsumptionThreshold = matrixConsumptionThreshold;
    this.customConsumptionThreshold = customConsumptionThreshold;
    this.matrixUpliftThreshold = matrixUpliftThreshold;
    this.customUpliftThreshold = customUpliftThreshold;
    this.reportsEnabled = reportsEnabled;
    this.commissionsEnabled = commissionsEnabled;
    this.matricesEnabled = matricesEnabled;
    this.insightsEnabled = insightsEnabled;
    this.customerDataEnabled = customerDataEnabled;
    this.displayReports = displayReports;
    this.requireLetterOfAuthorityForMeterVault = requireLetterOfAuthorityForMeterVault;
    this.dayOfMonthQuotingExpires = dayOfMonthQuotingExpires;
    this.quoteExpirationDisclaimerEnabled = quoteExpirationDisclaimerEnabled;
    this.quoteGenerationDisclaimerProcessUsesCustomMessage = quoteGenerationDisclaimerProcessUsesCustomMessage;
    this.quoteGenerationDisclaimerProcess = quoteGenerationDisclaimerProcess;
    this.quoteAcceptanceDisclaimer = quoteAcceptanceDisclaimer;
    if (quoteTypes) {
      this.quoteTypes = new QuoteTypes({ ...quoteTypes });
    }
    if (theme) {
      this.theme = new Theme({ ...theme });
    }
  }
}
