import { Component, Input, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { distinct, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { FilterService } from '@progress/kendo-angular-grid';
import { QuoteGridService } from '@app/quote-index/services/quote-grid.service';

@Component({
  selector: 'multicheck-filter',
  template: `
    <ul>
      <li *ngIf="showFilter">
        <input class="k-textbox" (input)="onInput($event)" />
      </li>
      <li #itemElement
        *ngFor="let item of filteredData; let i = index;"
        (click)="onSelectionChange(valueAccessor(item), itemElement)"
        [ngClass]="{'k-state-selected': isItemSelected(item)}">
        <input
          type="checkbox"
          id="chk-{{valueAccessor(item)}}"
          (focus)="onFocus(itemElement)"
          class="k-checkbox"
          [checked]="isItemSelected(item)" />
        <label
          class="k-multiselect-checkbox k-checkbox-label"
          for="chk-{{valueAccessor(item)}}">
            {{ textAccessor(item) }}
        </label>
      </li>
    </ul>
  `,
  styles: [`
    ul {
      list-style-type: none;
      height: 200px;
      overflow-y: scroll;
      padding-left: 0;
      padding-right: 12px;
    }

    ul>li {
      padding: 8px 12px;
      border: 1px solid rgba(0,0,0,.08);
      border-bottom: none;
    }

    ul>li:last-of-type {
      border-bottom: 1px solid rgba(0,0,0,.08);
    }

    .k-multiselect-checkbox {
      pointer-events: none;
    }
  `]
})

export class MultiCheckFilterComponent implements AfterViewInit {
  @Input() public isPrimitive: boolean;
  @Input() public currentFilter: any;
  @Input() public data;
  @Input() public textField;
  @Input() public valueField;
  @Input() public filterService: FilterService;
  @Input() public field: string;
  @Input() public quoteStatusFilter: any;
  @Output() public valueChange = new EventEmitter<number[]>();

  public filteredData: any;
  public showFilter = true;
  private selectedIds: any[] = [];

  public textAccessor = (dataItem: any) => this.isPrimitive ? dataItem : dataItem[this.textField];
  public valueAccessor = (dataItem: any) => this.isPrimitive ? dataItem : dataItem[this.valueField];

  constructor(public quoteGridService: QuoteGridService) { }

  public ngAfterViewInit() {
    this.filteredData = this.data;
    this.selectedIds = this.currentFilter.filters.map((f: FilterDescriptor) => f.value);

    this.showFilter = typeof this.textAccessor(this.filteredData[0]) === 'string';
  }

  public isItemSelected(item) {
    return this.selectedIds.some(x => x === this.valueAccessor(item));
  }

  public onSelectionChange(item, li) {
    if (this.selectedIds.some(x => x === item)) {
      this.selectedIds = this.selectedIds.filter(x => x !== item);
    } else {
      this.selectedIds.push(item);
    }
    
    const uniqueFilters = this.quoteGridService.generateFilterConditions(this.selectedIds, this.filteredData, this.field);

    this.filterService.filter({
      filters: uniqueFilters.map(filter => ({
        field: filter.field,
        operator: filter.operator,
        value: filter.value
    })),
      logic: 'or'
  });
  
    this.onFocus(li);
  }

  public onInput(e: any) {
    this.filteredData = distinct([
      ...this.filteredData.filter(dataItem => this.selectedIds.some(val => val === this.valueAccessor(dataItem))),
      ...filterBy(this.data, {
        operator: 'contains',
        field: this.textField,
        value: e.target.value
      })],
     this.textField
      );
  }

  public onFocus(li: any): void {
    const ul = li.parentNode;
    const below = ul.scrollTop + ul.offsetHeight < li.offsetTop + li.offsetHeight;
    const above = li.offsetTop < ul.scrollTop;

    // Scroll to focused checkbox
    if (below || above) {
      ul.scrollTop = li.offsetTop;
    }
  }
}
