import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { debounce } from 'lodash';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBarComponent implements OnInit {
  @Output() search = new EventEmitter<string>();

  searchText: string;
  searchFunc: any;

  constructor() {
    this.searchFunc = debounce((input: string) => {
      this.search.emit(input);
    }, 500);
  }

  ngOnInit(): void {}

  changed($event): void {
    this.searchFunc($event.target.value);
  }
}
