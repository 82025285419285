import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@app/shared/services/config.service';
import { Observable } from 'rxjs';
import { QuickQuoteConstants } from '../models/quick-quote-constants';

@Injectable({
  providedIn: 'root'
})
export class QuickQuoteService {
  constructor(private httpClient: HttpClient, private configService: ConfigService) { }

  public getServerStepsValidation(quoteRequestId: number): Observable<any[]> {
    const path = this.configService.getApiPath(`quoterequest/validate`);
    const payload = {
      quoteRequestId,
      slugs: QuickQuoteConstants.wizardSteps.map((x) => x.slug),
    };
    return this.httpClient.post<any[]>(path, payload);
  }

  public getLatestValidSteps(matrixQuoteRequestIds: number[]) {
    const path = this.configService.getApiPath(`quickquote/steps-validation-list`);
    return this.httpClient.post<any[]>(path, matrixQuoteRequestIds);
  }

  public getQuoteConfiguration(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`quickquote/${quoteRequestId > 0 ? quoteRequestId : 0}/get-quote-configuration`);
    return this.httpClient.get<any>(path);
  }

  public saveQuoteConfiguration(quoteRequestId: number, formData: any): Observable<any> {
    const path = this.configService.getApiPath(`quickquote/${quoteRequestId}/save-quote-configuration`);
    return this.httpClient.post<any>(path, {...formData});
  }
}
