import { ThemeColours } from "./theme-colours";

export class Theme {
  private readonly logoOnLight: string;
  protected readonly cssVars: ThemeColours;

  public get LogoUrl(): string {
    return this.logoOnLight;
  }
  public get Colours(): ThemeColours {
    return this.cssVars;
  }

  constructor({ logoOnLight, cssVars }: { logoOnLight: string; cssVars: ThemeColours; }) {
    this.logoOnLight = logoOnLight;
    this.cssVars = cssVars;
  }
}
