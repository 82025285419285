import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { UtilityHubConfigurationEffects } from './state/effects/configuration.effects';
import { UtilityHubConfigService } from './services/utilityhub-config.service';
import { SharedModule } from '../shared/shared.module';


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    EffectsModule.forFeature([UtilityHubConfigurationEffects])
  ],
  providers: [UtilityHubConfigService]
})
export class UtilityHubConfigurationModule { }
