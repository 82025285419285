import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TrainingDocumentIndexItem } from './models/training-document-index-item';
import { QuoteQuery } from '../shared/models/quote-query';
import { BehaviorSubject, forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, finalize, map, shareReplay, pluck } from 'rxjs/operators';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { AppState } from '@app/state/app.state';
import { ActivatedRoute } from '@angular/router';
import { UtilityHubService } from '../shared/services/utilityhub.service';
import { DateConstants } from '@app/constants/date-constants';
import { QuoteRequestService } from '@app/shared/services/quote-request.service';
import { UserRole, UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { WizardService } from '@app/wizard-bar/services/wizard.service';
import { FilteredTrainingDocumentResults } from './models/filtered-training-document-results';
import { ConfigService } from '@app/shared/services/config.service';
import { EditableUploadDialogComponent } from '@app/filemanagement/editable-upload-dialog/editable-upload-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { loggedInUserSelector, loggedInUtilityHubIdentitySelector } from '@app/state/app.selectors';

@Component({
  selector: 'app-training-documents',
  templateUrl: './training-document.component.html',
  styleUrls: ['./training-document.component.scss'],
})
export class TrainingDocumentComponent implements OnInit, OnDestroy {
  public dateFormat = DateConstants.LOCALE_FORMAT_SHORT();

  @ViewChild(MatPaginator) paginator: MatPaginator;

  private filterSub = new Subject<QuoteQuery>();

  public userIdentity: UtilityHubIdentity;
  public trainingDocuments: TrainingDocumentIndexItem[];
  public loading$ = new BehaviorSubject(true);
  public users = [];
  public unpaginatedRecordCount: number;
  public pageIndex: number;
  public pageSize: number;
  public filters: QuoteQuery = new QuoteQuery({ pageSize: 20, pageIndex: 0 });
  public filteredTrainingDocumentsIndexData$: Observable<FilteredTrainingDocumentResults>;
  public userIsSupplierManagerOrSalesManager: boolean;
  public isSupplierOrganisation: boolean;
  organisationId: string;
  length: number;
  filteredTrainingDocuments$: Observable<TrainingDocumentIndexItem[]>;
  filteredTrainingDocumentItems$: Observable<FilteredTrainingDocumentResults>;
  selectLoggedInUserSubscription$: Subscription;
  selectLoggedInUtilityHubIdentitySubscription$: Subscription;
  filterSubSubscription$: Subscription;
  loadSubscription$: Subscription;
  uploadDialog: MatDialogRef<EditableUploadDialogComponent>;
  uploadDialog$: Subscription;

  public displayedColumns: string[];

  public supplierDisplayedColumns: string[] = [
    'documentDisplayName',
    'contentType',
    'fileSize',
    'createdBy',
    'uploadedOn',
    'modifiedOn',
    'actions',
  ];

  public brokerDisplayedColumns: string[] = [
    'documentDisplayName',
    'contentType',
    'fileSize',
    'supplierName',
    'createdBy',
    'uploadedOn',
    'modifiedOn',
    'actions',
  ];

  constructor(
    public configService: ConfigService,
    private quoteRequestService: QuoteRequestService,
    private utilityHubService: UtilityHubService,
    private activedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private wizardService: WizardService,
    protected dialog: MatDialog
  ) {}

  ngOnDestroy(): void {
    this.selectLoggedInUserSubscription$?.unsubscribe();
    this.selectLoggedInUtilityHubIdentitySubscription$?.unsubscribe();
    this.filterSubSubscription$?.unsubscribe();
    this.loadSubscription$?.unsubscribe();
  }

  ngOnInit(): void {
    this.selectLoggedInUserSubscription$ = this.store.select(loggedInUserSelector).subscribe((user) => {
      if (user && !user.expired) {
        this.selectLoggedInUtilityHubIdentitySubscription$ = this.store
          .select(loggedInUtilityHubIdentitySelector)
          .subscribe((uhUser) => {
            this.organisationId = uhUser.currentOrganisation.id;
            this.userIdentity = uhUser;
            this.userIsSupplierManagerOrSalesManager = uhUser.isSupplierManagerOrSalesManager;
            this.isSupplierOrganisation = uhUser.currentOrganisation.entityType === UserRole.Supplier;
            console.log(this.isSupplierOrganisation);
            this.displayedColumns = this.isSupplierOrganisation ? this.supplierDisplayedColumns : this.brokerDisplayedColumns;
          });
      }
    });

    this.load();
    this.filterSubSubscription$ = this.filterSub.pipe(debounceTime(250)).subscribe(() => this.filterChanged());
  }

  public filterChanged() {
    this.paginator.pageIndex = 0;
    this.loadDocumentList();
  }

  private load() {
    this.loadSubscription$ = forkJoin({
      users: this.utilityHubService.getCreatedByUsers(this.organisationId, (this.userIdentity.isSupplier || this.userIdentity.isSalesManager)),
      query: QuoteQuery.getQuoteRequestsFromQueryParam(this.activedRoute, this.paginator, null),
    }).subscribe((res) => {
      this.users = res.users;
      this.loadDocumentList(res.query);
    });
  }

  public loadDocumentList(queryFilter?: QuoteQuery) {
    this.loading$.next(true);
    this.filters.pageIndex = this.paginator?.pageIndex ?? 0;
    this.filters.pageSize = this.paginator?.pageSize ?? 20;
    this.filters = queryFilter ? new QuoteQuery({ ...queryFilter }) : this.filters;
    this.filteredTrainingDocumentsIndexData$ = this.quoteRequestService.getTrainingDocumentsIndex(this.filters).pipe(
      finalize(() => this.loading$.next(false)),
      map((results) => this.mapDocumentRequests(results)),
      shareReplay()
    );

    this.quoteRequestService.getTrainingDocumentsIndex(this.filters).subscribe((trainingDocuments) => {
      console.log(trainingDocuments);
      this.mapDocumentRequests(trainingDocuments);
    });
    this.filteredTrainingDocuments$ = this.filteredTrainingDocumentsIndexData$.pipe(pluck('results'));
  }

  private mapDocumentRequests(results: FilteredTrainingDocumentResults) {
    this.trainingDocuments = results.results;
    this.paginator.length = results.unpaginatedRecordCount;
    this.pageSize = results.pageSize;
    this.pageIndex = results.pageIndex;
    this.unpaginatedRecordCount = results.unpaginatedRecordCount;
    this.length = this.unpaginatedRecordCount;
    return results;
  }

  public uploadDocument(file?: TrainingDocumentIndexItem) {
    this.uploadDialog = this.dialog.open(EditableUploadDialogComponent, {
      width: '40%',
      data: {
        title: file ? 'EDIT DOCUMENT' : `UPLOAD A DOCUMENT`,
        description: '',
        uploadBoxDescription: 'Drag and drop your document here',
        noFileUploadedErrorMessage: 'Please upload an Document.',
        file: file,
      },
    });
    this.uploadDialog.disableClose = true;
    this.uploadDialog$ = this.uploadDialog.afterClosed().subscribe((result) => {
      if (result.success) {
        this.loadDocumentList();
      }
    });
  }
}
