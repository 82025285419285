import { Pipe, PipeTransform } from '@angular/core';
import * as lodash from 'lodash/string';
/*
 * Turn SomeValue to Some Value
 */
@Pipe({ name: 'camelToSentenceCase' })
export class CamelToSentenceCasePipe implements PipeTransform {
  transform(value: string): string {
    return lodash.startCase(value);
  }
}
