import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { of, Observable } from 'rxjs';
import { mergeMap, map, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { AuthService } from './auth.service';
import { Login, AccessCheckFailed, UtilityHubIdentitySet } from './state/actions/auth.actions';
import { User } from 'oidc-client';
import { UtilityHubIdentity, UserRole } from './models/utility-hub-identity';
import { CookieService } from 'ngx-cookie-service';
import { AppState } from '@app/state/app.state';
import { loggedInUserSelector } from '@app/state/app.selectors';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private store: Store<AppState>,
    private router: Router,
    private cookieService: CookieService
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkStoreAuthentication().pipe(
      mergeMap((storeAuth) => {
        if (storeAuth && !storeAuth.expired) {
          return of(storeAuth);
        }

        return this.checkApiAuthentication();
      }),
      mergeMap((storeOrApiAuth) => {
        if (!storeOrApiAuth) {
          localStorage.setItem('auth:redirect', state.url);
          this.store.dispatch(new Login());
          return of(false);
        }

        return new Observable<boolean>((o) => {
          this.authService.utilityHubIdentity().subscribe(
            (user) => {
              if (this.checkUtilityHubUser(user)) {
                this.store.dispatch(new AccessCheckFailed());
                o.next(false);
              } else {
                this.store.dispatch(new UtilityHubIdentitySet(storeOrApiAuth, user));
                const orgId = this.router.getCurrentNavigation()?.finalUrl.queryParams.organizationId;
                if (orgId) {
                  this.cookieService.set(`active-organisation-${user.id}`, orgId, { expires: 1000000 });
                }
                o.next(true);
              }
            },
            (err) => o.next(false)
          );
        });
      }),
      map((success) => {
        return success;
      })
    );
  }

  checkStoreAuthentication() {
    return this.store.select(loggedInUserSelector).pipe(take(1));
  }

  checkApiAuthentication() {
    return new Observable<User>((o) => {
      this.authService.getUser().subscribe((user) => {
        if (user) {
          if (!user.expired) {
            o.next(user);
          } else {
            o.next(null);
          }
        } else {
          o.next(null);
        }
      });
    });
  }

  checkUtilityHubUser(user: UtilityHubIdentity) {
    return (
      !user ||
      !user.validIdentity ||
      user.currentOrganisation.entityType === 'Unknown' ||
      (user.hasRole(UserRole.Supplier) && user.isSupplierManagerOrSalesManager)
    );
  }
}
