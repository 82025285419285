import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomConstants } from '@app/custom/models/custom-constants';
import { MatrixConstants } from '@app/matrix/models/matrix-constants';
import { WizardStepCol } from './wizard.service';
import { UserRole, UtilityHubIdentity } from '@auth/models/utility-hub-identity';
import { PricingSource, PricingSourceService } from '@app/shared/services/pricing-source.service';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';
import { Store } from '@ngrx/store';
import { QuickQuoteConstants } from '@app/quick-quote/models/quick-quote-constants';
import { CommodityType } from '../../shared/enums/commodity-type';
import { Observable } from 'rxjs';
import { AppState } from '@app/state/app.state';
import { commodityTypeSelector } from '@utility-information/state/selectors/utility-information.selectors';
import { loggedInUtilityHubIdentitySelector, configurationSelector } from '@app/state/app.selectors';
@Injectable({
  providedIn: 'any',
})
export class WizardStepProviderService {
  private user: UtilityHubIdentity;
  private pricingSource: PricingSource;
  public selectedCommodityType$: Observable<string>;

  constructor(private pricingSourceService: PricingSourceService, private store: Store<AppState>)
  {}

  public getSteps(route?: ActivatedRoute, journeyType?: string): WizardStepCol {
    if (route && route.parent) {
      route.parent.url.subscribe((url) => {
        journeyType = url[0].toString();
      });
    }

    this.store.select(loggedInUtilityHubIdentitySelector).subscribe((user) => {

      this.user = user;
      let selectedCommodity;

      this.selectedCommodityType$ = this.store.select(commodityTypeSelector);
      this.selectedCommodityType$.subscribe( (ct) => { selectedCommodity = ct; }).unsubscribe();

      if (!selectedCommodity)
      {
        route.queryParams.subscribe((x) => {
          if(x.commodityType) {
            selectedCommodity = x.commodityType;
          }
        });
      }

      this.store.select(configurationSelector).subscribe((config) => {
        this.pricingSource = this.pricingSourceService.fetchPricingSource(
          QuoteRequestType.Custom,
          UserRole[user.currentOrganisation.role],
          config.supplierConfiguration.pricing.powwrSupplier,
          CommodityType[selectedCommodity]
          );
        }).unsubscribe();
    }).unsubscribe();

    switch (journeyType.toLowerCase()) {
      case 'quickquote':
        return { steps: QuickQuoteConstants.wizardSteps } as WizardStepCol;
      case 'custom':
        if (this.pricingSource === PricingSource.ManualEntry && this.user?.isSupplierManagerOrSalesManager) {
          return { steps: CustomConstants.wizardStepsQuickSubmit } as WizardStepCol;
        } else {
          return { steps: CustomConstants.wizardSteps } as WizardStepCol;
        }
      case 'matrix':
        return { steps: MatrixConstants.wizardSteps } as WizardStepCol;
    }

    return { steps: [] } as WizardStepCol;
  }
}
