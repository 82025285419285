<!-- Due to how Kendo Grid handles field names, when it's dynamic LINQ executes, the property names are case sensitive.
  So we must make use the field names here reflect the casing of the field names in DotNet! -->
<kendo-grid
  [data]="gridView"
  [loading]="quoteRequestIndexState.isLoading$() | async"
  [skip]="gridState.skip"
  [pageSize]="gridState.take"
  [sort]="gridState.sort"
  [filter]="gridState.filter"
  [sortable]="true"
  [pageable]="{
    buttonCount: 5,
    info: true,
    type: 'input',
    pageSizes: [20, 50, 100, 150],
    previousNext: true,
    position: 'bottom'
  }"
  [resizable]="true"
  filterable="menu"
  scrollable="auto"
  (dataStateChange)="dataStateChange($event)"
>
  <kendo-grid-column [lockable]="true"  field="QuoteRequestId" title="Quote ID" filter="numeric" format="{0:d}" width="110px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.quoteRequestId }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="QuoteType" title="Quote Type" width="120px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.quoteType | changeQuickQuoteName }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        [filterService]="filterService"
        [currentFilter]="filter"
        textField="name"
        valueField="id"
        [data]="this.QuoteTypeValues | sortAlphabetically: 'name'"
      ></multicheck-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="OrganisationName" title="Customer Name" width="130px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.organisationName }}
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell [column]="column" [filter]="filter"></kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="UtilityAccounts" title="Utility Accounts" filter="numeric" width="140px">
    <ng-template kendoGridCellTemplate let-ua>
      <span class="icon-prefix">
        <img
          *ngIf="ua.commodity === Commodity.Electricity || ua.commodity === Commodity.Gas"
          [src]="'/assets/icons/icon-' + ua.commodity?.toLowerCase() + '.svg'"
          alt="commodityIcon"
          class="prefix icon-sm"
        />
        {{ ua.utilityAccounts }}
      </span>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true"  field="Utility" title="Utility" width="100px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.utility }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="TotalKwh" title="TotalKwh" filter="numeric" width="150px">
    <ng-template kendoGridHeaderTemplate> Annual Usage </ng-template>
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.totalKwh | number: '0.0' }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="Unit" title="Units" width="80px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.unit }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="Brokerage" title="Brokerage" [hidden]="!getBrokerageColumnVisibility()" width="100px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.brokerage }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="BrokerName" title="Agent" [hidden]="!getBrokerColumnVisibility()" width="90px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.brokerName }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="CreatedBy" title="Created By" [hidden]="!getCreatedByColumnVisibility()" width="120px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.createdBy }}
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="CreatedDate" title="Created" filter="date" format="{0:d}" width="120px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.createdDate | date: dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" operator="gte">
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="LastUpdated" title="Last Updated" filter="date" format="{0:d}" width="120px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.lastUpdated | date: dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" operator="gte">
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="QuoteDueDate" title="Due Date" filter="date" format="{0:d}" width="120px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.quoteDueDate | date: dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" operator="gte">
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="ContractStartDate" title="Start Date" filter="date" format="{0:d}" width="120px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.contractStartDate | date: dateFormat }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">
      <kendo-grid-date-filter-menu [column]="column" [filter]="filter" [filterService]="filterService" operator="gte">
        <kendo-filter-gte-operator></kendo-filter-gte-operator>
        <kendo-filter-lte-operator></kendo-filter-lte-operator>
      </kendo-grid-date-filter-menu>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [lockable]="true" field="QuoteRequestStatus" title="Quote Status" width="150px">
    <ng-template kendoGridCellTemplate let-ua>
      {{ ua.displayStatus | camelToSentenceCase }}
    </ng-template>
    <ng-template kendoGridFilterMenuTemplate let-column="column" let-filter="filter" let-filterService="filterService">
      <multicheck-filter
        [isPrimitive]="false"
        [field]="column.field"
        [currentFilter]="filter"
        [filterService]="filterService"
        textField="name"
        valueField="id"
        [data]="quoteStatuses | sortAlphabetically: 'name'"
      ></multicheck-filter>
    </ng-template>
  </kendo-grid-column>

  <kendo-grid-column  width="130px">
    <ng-template kendoGridCellTemplate let-ua>
      <div>
        <button id="actionBtn" mat-flat-button color="accent" (click)="navigateToSelectedQuoteRequest(ua)" class="custom-action-buttons">
          <img class="prefix" [src]="resolveSelectQuoteRequestButton(ua).iconSrc" style="padding-right: 10px" />
          {{ resolveSelectQuoteRequestButton(ua).label | camelToSentenceCase }}
        </button>
      </div>
    </ng-template>
  </kendo-grid-column>
  <ng-template kendoPagerTemplate let-totalPages="totalPages" let-currentPage="currentPage">
    <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
    <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
    <kendo-pager-next-buttons></kendo-pager-next-buttons>
    <kendo-pager-page-sizes [pageSizes]="[20, 50, 100, 150]"></kendo-pager-page-sizes>
    <a class="reset" (click)="clearFilters()">Reset Filters</a>&nbsp;
    <button type="button" mat-stroked-button (click)="save(excelexport)" color="accent">
      <i class="buttonIcon" inlineSVG="/assets/icons/btn_icon_export-to-excel.svg"></i> Export to Excel
    </button>
    <button type="button" mat-stroked-button (click)="save(excelexport)" color="accent" kendoGridPDFCommand>
      <i class="buttonIcon" inlineSVG="/assets/icons/btn_icon_export-to-pdf.svg"></i> Export to PDF
    </button>
    <kendo-pager-info></kendo-pager-info>
  </ng-template>

  <kendo-excelexport [data]="gridView?.data" fileName="Quotes.xlsx" #excelexport>
    <kendo-excelexport-column field="quoteRequestId" title="Quote ID"></kendo-excelexport-column>
    <kendo-excelexport-column field="quoteType" title="Quote Type"></kendo-excelexport-column>
    <kendo-excelexport-column field="organisationName" title="Customer Name"></kendo-excelexport-column>
    <kendo-excelexport-column field="utilityAccounts" title="Utility Accounts"></kendo-excelexport-column>
    <kendo-excelexport-column field="utility" title="Utility"></kendo-excelexport-column>
    <kendo-excelexport-column field="totalKwh" title="Annual Usage"></kendo-excelexport-column>
    <!---value may be for gas (ccf/therms) or electric (kWh)-->
    <kendo-excelexport-column field="unit" title="Units"></kendo-excelexport-column>
    <kendo-excelexport-column field="utilityAccounts" title="Utility Accounts"></kendo-excelexport-column>
    <kendo-excelexport-column field="brokerage" title="Brokerage" [hidden]="!getBrokerageColumnVisibility()"> </kendo-excelexport-column>
    <kendo-excelexport-column field="broker" title="Agent" [hidden]="!getBrokerColumnVisibility()"> </kendo-excelexport-column>
    <kendo-excelexport-column field="createdBy" title="Created By" [hidden]="!getCreatedByColumnVisibility()"> </kendo-excelexport-column>
    <kendo-excelexport-column field="createdDate" title="Created" [cellOptions]="cells"></kendo-excelexport-column>
    <kendo-excelexport-column field="lastUpdated" title="Last Updated" [cellOptions]="cells"></kendo-excelexport-column>
    <kendo-excelexport-column field="quoteRequestStatus" title="Quote Status"></kendo-excelexport-column>
  </kendo-excelexport>
  <kendo-grid-pdf fileName="Quotes.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="false" [landscape]="true" [scale]="0.5">
    <kendo-grid-pdf-margin top="2cm" left="1cm" right="1cm" bottom="2cm"></kendo-grid-pdf-margin>
    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
      <div class="page-template">
        <div class="header">
          <div style="float: right">Page {{ pageNum }} of {{ totalPages }}</div>
        </div>
      </div>
    </ng-template>
  </kendo-grid-pdf>
</kendo-grid>
