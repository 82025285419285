import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { UserNotification } from "../models/notification";

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(public configService: ConfigService, private httpClient: HttpClient) { }

  public async getNotification(userId: string): Promise<UserNotification> {
    const path = this.configService.getApiPath(`${userId}/notification`);
    return await this.httpClient.get<UserNotification>(path).toPromise();
  }

  public async saveNotification(userId: string, data: any): Promise<UserNotification> {
    var notificationData = {
      notificationData: JSON.stringify(data)
    }
    const path = this.configService.getApiPath(`${userId}/notification`);
    return await this.httpClient.post<UserNotification>(path, notificationData).toPromise();
  }

  //public async updateContract(userId: string, data: any): Promise<UserNotification> {
  //  var notificationData = {
  //    notificationData: JSON.stringify(data)
  //  }
  //  const path = this.configService.getApiPath(`${userId}/notification`);
  //  return await this.httpClient.patch<UserNotification>(path, notificationData).toPromise();
  //  }
}
