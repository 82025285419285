import { QuoteRequestNoteModel } from '@app/quoting/models/quote-request-notes';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { HttpClient } from '@angular/common/http';
import { QuoteRequestIndexItem } from '@app/quote-index/models/quote-request-index-item.model';
import { QuoteRequestState } from '../models/quote-request-state';
import { YoureDone } from '../models/youre-done';
import { UdFile } from '@app/filemanagement/UdFile';
import * as saveAs from 'file-saver';
import { FilteredTrainingDocumentResults } from '@app/training-document/models/filtered-training-document-results';
import { TrainingDocumentIndexItem } from '@app/training-document/models/training-document-index-item';
import { Constants } from '../models/constants';
import { QuoteRequestStatus } from '../models/quote-request-status';
import { QuoteRequestType } from '../models/QuoteRequestType';
import { UtilityAccountIndexItem } from '@app/utility-accounts-index/models/utility-account-index-item.model';
import { QuoteRequestSummary } from '@app/quoting/models/quote-request-summary';
import { QuotePriceHistoryModel } from '@quoting/models/quote-price-history';
import { Moment } from 'moment';
import { UtilityStartDateModel } from '@quoting/models/utility-start-date';

@Injectable({
  providedIn: 'root',
})
export class QuoteRequestService {
  constructor(public configService: ConfigService, private httpClient: HttpClient) {}

  public deleteQuoteRequest(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}`);
    return this.httpClient.delete<QuoteRequestIndexItem[]>(path);
  }

  // Quote Request State
  public getQuoteRequestState(quoteRequestId: number): Observable<QuoteRequestState> {    
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quote-state`);
    const result = this.httpClient.get<QuoteRequestState>(path);
    return result;
  }

  // Utility Accounts
  public getUtilityAccounts(quoteRequestId: number): Observable<any[]> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/utility-accounts`);
    return this.httpClient.get<any[]>(path);
  }

  public saveUtilityAccounts(quoteRequestId, utilityAccounts: any[]): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/utility-accounts`);
    return this.httpClient.post(path, utilityAccounts);
  }

  public saveUtilityAccountAndUpdateAnnualUsage(quoteRequestId: number, formData: any): Observable<any> {
    const path = this.configService.getApiPath(`quickquote/${quoteRequestId}/save-utility-account-and-update-annual-usage`);
    return this.httpClient.post<any>(path, {...formData});
  }
  
  public saveRateCodes(quoteRequestId: any, rateCodes: { utilityAccountNumber: string; rateCode: string; rateClass: string }[]): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/rate-codes`);
    return this.httpClient.post(path, rateCodes);
  }

  // Get a Quote
  public generateQuoteRequestFromRenewalData(ua: UtilityAccountIndexItem, quoteRequestType: QuoteRequestType) {
    const path = this.configService.getApiPath(`quoterequest/generate-from-renewal-data`);
    return this.httpClient.post<any>(path, { ...ua, quoteRequestType });
  }

  public sendQuoteRequestToSupplier(quoteRequestId: number, quoteDetails: any): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/send-quoterequest-to-supplier`);
    return this.httpClient.post<any>(path, { quoteRequestId, ...quoteDetails });
  }

  public generateQuote(quoteRequestId: number, quoteDetails: any): Observable<any> {   
    const path = this.configService.getApiPath('generatequote');    
    return this.httpClient.post<any>(path, { quoteRequestId, ...quoteDetails });
  }

  public generateLoadProfile(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/generate-load-profile`);
    return this.httpClient.post<any>(path, { quoteRequestId });
  } 

  public getMontlyConsumptionData(quoteRequestId: number, term: number, uplift: number): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/monthly-consumption-data?term=${term}&uplift=${uplift}`);
    return this.httpClient.get<any>(path);
  }

  public checkLoaExists(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`custom-quote-request/${quoteRequestId}/has-loa`);
    return this.httpClient.get<any>(path);
  }

  public getSavedQuote(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quote`);
    return this.httpClient.get<any>(path);
  }

  public getQuoteRequestSummary(quoteRequestId: number): Observable<QuoteRequestSummary> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/get-quote-request-summary`);
    return this.httpClient.get<QuoteRequestSummary>(path);
  }

  public saveQuoteRequestSummary(quoteRequestSummary: QuoteRequestSummary): Observable<QuoteRequestSummary> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestSummary.quoteRequestId}/save-quote-request-summary`);
    return this.httpClient.post<QuoteRequestSummary>(path, {...quoteRequestSummary});
  }

  public acceptOffer(quoteRequestId: number, quotePriceTerm: number, planType?: string): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quote-price-accept/`);
    return this.httpClient.post<any>(path, {
      quotePriceTerm: quotePriceTerm,
      planType: planType,
    });
  }

  public requestExpiredQuoteApproval(quoteRequestId: number, quotePriceTerm: number, planType?: string): Observable<any>  {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quote-price-request/`);
    return this.httpClient.post<any>(path, {
      quotePriceTerm: quotePriceTerm,
      planType: planType,
    });
  }

  public rejectOffer(quoteRejection: any): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/quote-reject`);
    return this.httpClient.post<any>(path, quoteRejection);
  }

  public resolveOffers(quoteRequestId: number, approvedTerms: number[], notes: string): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/resolve-offers`);
    return this.httpClient.patch<any>(path, { ApprovedTerms: approvedTerms, Notes: notes });
  }

  public rejectQuoteRequest(quoteRequestId: number, quoteRejection: any): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/reject-quoterequest`);
    return this.httpClient.patch<any>(path, quoteRejection);
  }

  // Data for you're done page
  public getYoureDone(quoteRequestId: number): Observable<YoureDone> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/youre-done`);
    return this.httpClient.get<YoureDone>(path);
  }

  public getTrainingDocumentsIndex(filters: any): Observable<FilteredTrainingDocumentResults> {
    const path = this.configService.getApiPath('file/list');
    return this.httpClient.get<FilteredTrainingDocumentResults>(path, { params: filters });
  }

  public saveSignedContractDocument(quoteRequestId: number, documentId: string, contractFormat: string) {
    const path = this.configService.getApiPath(`quoterequest/save-signed-contract-document`);
    return this.httpClient.post<any>(path, { quoteRequestId, documentId, contractFormat });
  }

  public getSignedContractDocument(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/get-signed-contract-document`);
    return this.httpClient.get<any>(path);
  }

  public submitQuoteRequest(quoteRequestId: number): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/submit`);
    return this.httpClient.post(path, {});
  }

  public isReadonly(quoteRequestId: number, quoteRequestStatus: string, userId: string, ownerUserId: string): boolean {
    if (!quoteRequestId) {
      return false;
    }
    
    const submittedStatuses = [QuoteRequestStatus.PendingEnrollment];
    const isUserOwner = userId === ownerUserId;
    const isNewQuoteRequest = isNaN(quoteRequestId);

    return submittedStatuses.includes(QuoteRequestStatus[quoteRequestStatus]) && !isNewQuoteRequest;
  }

  public saveQuoteRequestNote(quoteRequestId: number, quoteRequestNote: QuoteRequestNoteModel): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoterequest-note`);
    return this.httpClient.post(path, quoteRequestNote);
  }

  public updateQuoteRequestNote(quoteRequestId: number, quoteRequestNote: QuoteRequestNoteModel): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoterequest-note`);
    return this.httpClient.put(path, quoteRequestNote);
  }

  public getQuoteRequestNotes(quoteRequestId: number): Observable<QuoteRequestNoteModel[]> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/quoterequest-note`);
    return this.httpClient.get<QuoteRequestNoteModel[]>(path);
  }

  public getQuotePriceHistory(quoteRequestId: number): Observable<QuotePriceHistoryModel[]> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/get-quote-price-history`);
    return this.httpClient.get<QuotePriceHistoryModel[]>(path);
  }

  saveNewContractStartMonth(quoteRequestId: string, newContractStartMonthDetails: UtilityStartDateModel[]): Observable<any> {
    const path = this.configService.getApiPath(`quoterequest/${quoteRequestId}/new-contract-start-month`);
    return this.httpClient.post(path, newContractStartMonthDetails);
  }

}
