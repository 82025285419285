import { User } from 'oidc-client';
import { UtilityHubIdentity } from '../models/utility-hub-identity';

export interface AuthModuleState {
  user: User;
  utilityHubIdentity: UtilityHubIdentity;
}

export const initialState: AuthModuleState = {
  user: null,
  utilityHubIdentity: null,
};
