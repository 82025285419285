import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import * as fromAuth from '../actions/auth.actions';
import { AuthService } from '../../auth.service';
import { Store } from '@ngrx/store';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.Login>(fromAuth.AuthActionTypes.Login),
        tap(() => {
          return this.authService.login().subscribe();
        })
      ),
    { dispatch: false }
  );

  loginSilent$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.LoginSilent>(fromAuth.AuthActionTypes.LoginSilent),
        tap(() => {
          return this.authService.renewToken().subscribe();
        })
      ),
    { dispatch: false }
  );

  loginSilentRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.LoginSilentSuccess>(fromAuth.AuthActionTypes.LoginSilentSuccess),
        tap(() => {
          this.redirect();
        })
      ),
    { dispatch: false }
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.LoginSuccess>(fromAuth.AuthActionTypes.LoginSuccess),
        tap(() => {
          this.redirect();
        })
      ),
    { dispatch: false }
  );

  accessCheckFailed$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.AccessCheckFailed>(fromAuth.AuthActionTypes.AccessCheckFailed),
        tap(() => {
          this.redirectToNoAccess();
        })
      ),
    { dispatch: false }
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<fromAuth.Logout>(fromAuth.AuthActionTypes.Logout),
        tap(() => this.authService.logout().subscribe())
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private store$: Store, private authService: AuthService, private router: Router) {}

  private redirect() {
    const redirect = localStorage.getItem('auth:redirect');

    if (redirect) {
      // redirect to route - this is using the angular router
      localStorage.removeItem('auth:redirect');
      this.router.navigateByUrl(redirect);
    } else {
      // redirect to start page
      this.router.navigate(['../']);
    }
  }

  private redirectToNoAccess() {
    this.router.navigateByUrl('/forbidden').catch();
  }
}
