import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { ConfigService } from '../shared/services/config.service';
import { Login } from '../auth/state/actions/auth.actions';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { User } from 'oidc-client';
import { AppState } from '@app/state/app.state';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private configService: ConfigService,
    private store: Store<AppState>,
    private authService: AuthService,
    private cookieService: CookieService
  ) {}

  intercept(request: HttpRequest<any>, nextHttp: HttpHandler): Observable<HttpEvent<any>> {
    return new Observable<HttpEvent<any>>((observer) => {
      const handling = {
        next: (x) => {
          observer.next(x);
        },
        error: (err) => {
          if (err.status === 401) {
            this.store.dispatch(new Login());
          }
          observer.error(err);
        },
        complete: () => observer.complete(),
      };
      if (
        this.configService.config &&
        (request.url.startsWith(this.configService.config.endpoints.apiUrl) ||
          request.url.startsWith(this.configService.config.endpoints.supplierManagerPortalApi) ||
          request.url.startsWith(this.configService.config.endpoints.utilityHubRoot))
      ) {
        this.checkOidcAuthentication().subscribe((user) => {
          nextHttp.handle(this.cloneRequest(user, request)).subscribe(handling);
        });
      } else {
        nextHttp.handle(request).subscribe(handling);
      }
    });
  }

  checkOidcAuthentication() {
    return new Observable<User>((o) => {
      this.authService.getUser().subscribe((user) => {
        if (user) {
          if (!user.expired) {
            o.next(user);
          } else {
            o.next(null);
          }
        } else {
          o.next(null);
        }
      });
    });
  }

  cloneRequest(user, request) {
    if (user && !user.expired) {
      const token = user.access_token;
      return request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
          'x-active-organisation': this.cookieService.get(`active-organisation-${user.profile.sub}`),
        },
      });
    }

    return request;
  }
}
