<section>
  <span style="flex: 1 1 auto"></span>
  <span *ngIf="loggedIn">
    <span style="color: var(--headerFooterColour)">Cookie Org: {{ this.cookieActiveOrganisation }}</span> Welcome
    {{ userFullNameWithRoleAndOrganisation }}
  </span>
  <span *ngIf="!loggedIn"></span>

  <mat-menu #appMenu="matMenu" class="account-menu">
    <button mat-menu-item (click)="openSwitchContextDialog()" *ngIf="hasMultipleOrgs">Switch Organization</button>
    <button mat-menu-item [routerLink]="['/', 'settings', this.userIdentity?.id]">Settings</button>
    <button *ngIf="loggedIn" id="logoutLink" mat-menu-item (click)="logout()">Sign Out</button>
    <button *ngIf="!loggedIn" id="logoutLink" mat-menu-item (click)="login()">Sign In</button>
  </mat-menu>

  <a
    *ngIf="loggedIn"
    id="documentActionsLink"
    [inlineSVG]="'/assets/icons/icon_topnav-documents.svg'"
    style="width: 20px"
    class="profile-icon"
    [routerLink]="['/', 'training-documents']"
  >
  </a>
  <a *ngIf="loggedIn" id="accountActionsLink" [inlineSVG]="'/assets/icons/profile-icon.svg'" class="profile-icon" [matMenuTriggerFor]="appMenu"> </a>
</section>
