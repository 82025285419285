import { ChangeDetectionStrategy, Component, ChangeDetectorRef,  AfterViewInit, OnInit} from '@angular/core';
import { JsonFormsAngularService, JsonFormsControl } from '@jsonforms/angular';
import {
  isPrimitiveArrayControl,
  RankedTester,
  rankWith,
} from '@jsonforms/core';

@Component({
  styles: [`
        .mat-checkbox-button ~ .mat-checkbox-button {
            margin-left: 16px;
        }
    `],
  selector: 'CheckboxGroupControlRenderer',
  template: `
    <div [fxHide]="hidden">
      <section class="example-section">
        <span class="example-list-section">
          <mat-checkbox class="example-margin"
                        [checked]="allComplete"
                        [indeterminate]="someComplete()"
                        (change)="setAll($event.checked)">
            {{task.name}}
          </mat-checkbox>
        </span>
        <span class="example-list-section">
            <div *ngFor="let subtask of task.subtasks">
              <mat-checkbox class="example-subtask"
                            [(ngModel)]="subtask.completed"
                            (ngModelChange)="updateAllComplete()">
                {{subtask.name}}
              </mat-checkbox>
            </div>
        </span>
      </section>
      <div style='padding-bottom:10px;'></div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.Default
})

export class CheckboxGroupControlRenderer extends JsonFormsControl implements AfterViewInit{
  allComplete: boolean = false;
  notificationType = ['Email', 'Text'];
  task: Task  = {
    name: 'all',
    completed: false,
    subtasks: this.notificationType.map((s) => {
      return { name: s, completed: false }
    })
  };

  ngAfterViewInit(): void {
    this.task.name = this.label;
    this.task.subtasks = this.task.subtasks.map((s) => {
      return { name: s.name, completed: this.data ? this.data[s.name] : false }
    })
    this.task.completed = this.task.subtasks.every(s => s.completed);
    this.allComplete = this.task.completed;
    this.changeDetectorRef.detectChanges();
  }

  constructor(jsonformsService: JsonFormsAngularService, private changeDetectorRef: ChangeDetectorRef) {
    super(jsonformsService);
    
  }
 
  getEventValue = (event: any) => {
    return event;
  }

  mapAdditionalProps() {
    this.changeDetectorRef.markForCheck();
  }

  updateAllComplete() {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
    this.updateData();
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => (t.completed = completed));
    this.updateData();
  }

 updateData() {
    var item = {}
    this.task.subtasks.forEach(t => (item[t.name] = t.completed));
    if (this.data) {
      for (let [notifyType, notifyValue] of Object.entries(this.data)) {
        if (!this.notificationType.includes(notifyType)) {
          item[notifyType] = notifyValue;
        }
      }
    }
    this.data = item;
    this.onChange(this.data);
  }
}

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: Task[];
}

export const CheckboxGroupControlRendererTester: RankedTester = rankWith(
  13,
  isPrimitiveArrayControl
);
