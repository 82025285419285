import { Injectable, OnDestroy } from '@angular/core';
import { configurationSelector } from '@app/state/app.selectors';
import { AppState } from '@app/state/app.state';
import { ProductConfiguration } from '@configuration/models/product-configuration';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
  
export class QuotationDeactivationCheckerService implements OnDestroy {
  selectUtilityHubConfiguration$: Subscription;
  public supplierProductConfiguration: ProductConfiguration;
    
  constructor(private store: Store<AppState>) { 
    this.selectUtilityHubConfiguration$ = this.store
      .select(configurationSelector)
      .pipe(map(({ configuration }) => ({ configuration })))
      .subscribe((config) => {
        this.supplierProductConfiguration = config != null ? config.configuration : null;
      });
  }
  ngOnDestroy(): void {
    this.selectUtilityHubConfiguration$?.unsubscribe();
  }

  get IsQuotationDisabledForTheDay() {
    if (this.supplierProductConfiguration.quoteValidityNumberOfDays == 0) {
      let now = new Date();
      return now > new Date(`${now.getFullYear()}-${now.getMonth()+1}-${now.getDate()} ${this.supplierProductConfiguration.quoteExpiryIntervalTimeSpan}`);
    }
    return false;
  }

  get GetQuoteAcceptanceDisclaimer() {
    return this.supplierProductConfiguration.quoteAcceptanceDisclaimer;
  }

  get GetQuoteExpirationDisclaimerBasedOnQuoteStatus() {
    return this.supplierProductConfiguration.quoteGenerationDisclaimerProcess;
  }

  public GetQuoteExpirationDisclaimer(isSupplierManager: boolean, doesQuoteHavePrices: boolean) {
    if (this.IsQuotationDisabledForTheDay && this.supplierProductConfiguration.quoteExpirationDisclaimerEnabled && doesQuoteHavePrices && !isSupplierManager) {
      return this.supplierProductConfiguration.quoteGenerationDisclaimerProcess;
    }
    return "";
  }

}
