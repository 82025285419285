import { TableauWorkbookModel } from "@app/dashboard/models/tableau-workbook.model";
import { UtilityHubConfiguration } from "../models/utilityhub-configuration";


export interface ConfigurationModuleState {
  config: UtilityHubConfiguration;
  tableauWorkbooks: TableauWorkbookModel[];
}

export const initialConfigurationState: ConfigurationModuleState = {
  config: null,
  tableauWorkbooks: null,
};