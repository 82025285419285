import { Pipe, PipeTransform } from '@angular/core';
import { QuoteRequestType } from '@app/shared/models/QuoteRequestType';

@Pipe({
  name: 'changeQuickQuoteName',
})
export class ChangeQuickQuoteName implements PipeTransform {
    transform(quoteRequestType: QuoteRequestType): any {
        // Removes whitespace
        const formatType = quoteRequestType.replace(/\s/g, "");

        if (formatType === QuoteRequestType.QuickQuote) {
            return 'Quick';
        }
    
        return quoteRequestType;
    }
}